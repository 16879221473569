import APIUrl from "../../APIUrl";
import axios from "axios";
import { getOrdersAdmin } from "../orders/orders";
export const GET_CLIENT_CODE = "GET_CLIENT_CODE";

/*
function getClientCodeAction(clientCode) {
  return { type: GET_CLIENT_CODE, getClientCode: clientCode };
}
*/

export const findClient = function (data, successCallback, failureCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.mustFindCli, { data: data })
      .then(function (response) {
        return successCallback(response.data);
      })
      .catch(function (err) {
        err.response.data.code = 512;
        return failureCallback(err.response.data);
      });
  };
};

export const findArticlesMust = function (
  data,
  successCallback,
  failureCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.mustFindArticles, { data: data })
      .then(function (response) {
        return successCallback();
      })
      .catch(function (err) {
        // console.log(err.response.status);
        if (err.response) {
          if (err.response.status === 512) {
            err.response.data.code = 512;
            return failureCallback(err.response.data);
          }
        }
        throw err;
      });
  };
};

export const mustGetEtatDossier = function (
  data,
  successCallback,
  failureCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.mustGetEtatDossier, {})
      .then(function (response) {
        dispatch(getOrdersAdmin());
        successCallback(response.data);
      })
      .catch(function (err) {
        failureCallback(err.response.data);
        throw err;
      });
  };
};
