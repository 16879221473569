import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import UserModal from "./UserModal";
import Paginator from "../sub/Paginator";
import { deleteClient } from "../../actions/clients/clients";
import ModalManager from "../sub/modals/ModalManager";
import Icon from "../sub/Icon";
import ActionMenu from "../sub/ActionMenu";

class EstablishmentUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };

    this.random = Math.floor(Math.random() * 999999999999) + 1;

    this.paginator = new Paginator(this);
  }

  openModal(user) {
    this.setState({
      modal: (
        <UserModal
          user={user}
          establishment={this.props.establishment}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  openConfModal(user) {
    let content = (
      <div>
        <p>
          <FormattedMessage id="User.Remove.Confirmation1" />
        </p>
        <p>
          <FormattedMessage
            id="User.Remove.Confirmation2"
            values={{ name: `${user.first_name} ${user.name}` }}
          />
        </p>
      </div>
    );

    this.setState({
      modal: (
        <ModalManager
          showModal={true}
          context={"danger"}
          title={<FormattedMessage id="Warning" />}
          mandatoryConfirmation={true}
          content={content}
          successCallback={() =>
            this.props.onDeleteClient({ clientId: user._id })
          }
          closeModal={() => this.closeModal()}
          modalType="confirmation"
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  render() {
    this.paginator.init(this.props.clients.length);
    let clientsNode = this.props.clients.map((client) => {
      if (!this.paginator.keep()) return null;

      let menuItems = [];

      menuItems.push(
        {
          icon: "pen-to-square",
          action: () => this.openModal(client),
          text: <FormattedMessage id="Modify" />,
        },
        {
          icon: "trash",
          action: () => this.openConfModal(client),
          text: <FormattedMessage id="Delete" />,
        },
      );

      let menuAction = <ActionMenu items={menuItems} />;

      return (
        <tr className="d-flex" key={client._id}>
          <td className="col">{client.username}</td>
          <td className="col">{client.name}</td>
          <td className="col">{client.first_name}</td>
          <td className="col">{client.email}</td>
          <td className="col">{client.function}</td>
          <td className="col text-center">
            {client.validator ? (
              <Icon icon="circle-check" size="xl" className="text-success" />
            ) : (
              <Icon
                variant="far"
                size="xl"
                icon="circle-xmark"
                className="text-danger"
              />
            )}
          </td>
          <td className="col col-1 text-center">{menuAction}</td>
        </tr>
      );
    });

    let tableStyle = "table tablee4coll mt-3";
    if (this.props.cssStyle) tableStyle = this.props.cssStyle;

    return (
      <React.Fragment>
        <table className={tableStyle}>
          <thead>
            <tr className="d-flex">
              <th scope="col" className="col">
                <FormattedMessage id="Username" />
              </th>
              <th scope="col" className="col">
                <FormattedMessage id="Name" />
              </th>
              <th scope="col" className="col">
                <FormattedMessage id="First.Name" />
              </th>
              <th scope="col" className="col">
                <FormattedMessage id="Email" />
              </th>
              <th scope="col" className="col">
                <FormattedMessage id="Function" />
              </th>
              <th scope="col" className="col text-center">
                <FormattedMessage id="Validator" />
              </th>
              <th scope="col" className="col col-1 text-center">
                <FormattedMessage id="Actions" />
              </th>
            </tr>
          </thead>
          <tbody>
            {clientsNode}
            <tr>
              <td className="pt-2 pb-0 mb-0" colSpan="7">
                {this.paginator.render()}
              </td>
            </tr>
          </tbody>
        </table>

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteClient: (data) => dispatch(deleteClient(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EstablishmentUsers);
