import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_EQUIPMENTS_REQUESTS = "GET_EQUIPMENTS_REQUESTS";

function getEquipmentsRequestAction(equipmentsRequest) {
  return {
    type: GET_EQUIPMENTS_REQUESTS,
    equipments_requests: equipmentsRequest,
  };
}

export const getEquipmentsRequest = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getEquipmentsRequest)
      .then(function (response) {
        dispatch(getEquipmentsRequestAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addEquipmentsRequest = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.createEquipmentsRequest, data)
      .then(function (response) {
        dispatch(getEquipmentsRequest());
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateEquipmentsRequest = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateEquipmentsRequest, data)
      .then(function (response) {
        dispatch(getEquipmentsRequest());
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteEquipmentsRequest = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteEquipmentsRequest, data)
      .then(function (response) {
        dispatch(getEquipmentsRequest());
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
