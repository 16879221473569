import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import DateUtil from "../../util/DateUtil";
import Roles from "../../enums/Roles";
import EstimateStatus from "../../enums/EstimateStatus";
import { deleteRequest, updateRequest } from "../../actions/requests/requests";
import MenuButton from "../sub/bootstrap/MenuButton";
import ActionMenu from "../sub/ActionMenu";
import ModalManager from "../sub/modals/ModalManager";

class RequestRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  updateRequest(target, request) {
    let data = {
      updatedValue: target.value,
      updatedField: "status",
      requestId: request._id,
    };
    this.props.onUpdateRequest(data);
  }

  updateParentState(data) {
    this.props.updateParentState(data);
  }

  deleteRequestAdmin(request) {
    const deleteCbk = () =>
      this.props.onDeleteRequest({ requestId: request._id });

    this.updateParentState({
      modal: (
        <ModalManager
          showModal={true}
          title={<FormattedMessage id={"Confirm"} />}
          content={<FormattedMessage id={"Request.Remove.Confirmation"} />}
          successCallback={deleteCbk}
          closeModal={() => this.closeModal()}
          modalType="confirmation"
        />
      ),
    });
  }

  closeModal() {
    this.updateParentState({ modal: null });
  }

  render() {
    let statusNode = Object.values(EstimateStatus).map((status) => {
      return (
        <option key={status} value={status}>
          {this.props.intl.formatMessage({ id: "Estimate.Status." + status })}
        </option>
      );
    });

    let statusClassName = "light";

    switch (true) {
      case parseInt(this.props.request.status) === EstimateStatus.DECLINED:
        statusClassName = "danger";
        break;
      case parseInt(this.props.request.status) ===
        EstimateStatus.PARTIALLY_ACCEPTED:
        statusClassName = "warning";
        break;
      case parseInt(this.props.request.status) === EstimateStatus.ACCEPTED:
        statusClassName = "success";
        break;
      case parseInt(this.props.request.status) === EstimateStatus.PENDING:
        statusClassName = "info";
        break;
      default:
        break;
    }

    let status = (
      <div className={"alert alert-" + statusClassName + " p-2"}>
        &nbsp;
        {this.props.intl.formatMessage({
          id: "Estimate.Status." + this.props.request.status,
        })}
        &nbsp;
      </div>
    );

    let menuItems = [];

    menuItems.push(
      // Edit
      {
        icon: "pen-to-square",
        action: () => {
          this.props.openEstimateModal(this.props.request);
        },
        text: <FormattedMessage id="Modify" />,
      },
      // Delete
      {
        icon: "trash",
        action: (e) => this.deleteRequestAdmin(this.props.request),
        text: <FormattedMessage id="Delete" />,
      },
    );

    let menuAction = <ActionMenu items={menuItems} />;

    return (
      <tr key={this.props.key} className="d-flex">
        {this.props.user.role === Roles.CLIENT ? (
          <td className="col col-2 align-middle">
            {" "}
            {this.props.request.client_name}
          </td>
        ) : (
          <td className="col col-2 align-middle">
            {this.props.clientName
              ? this.props.clientName
              : this.props.establishment}
          </td>
        )}

        <td className="col align-middle">
          <div className="truncate">{this.props.request.precisions}</div>
        </td>

        <td className={!this.props.limit ? "col col-2" : "col col-3"}>
          {DateUtil.toDate(this.props.request.creation_date)}
        </td>

        {this.props.user.role === Roles.CLIENT ? (
          <td className={!this.props.limit ? "col col-2" : "col col-2"}>
            {status}
          </td>
        ) : (
          <td className="col col-2">
            <select
              className={"form-control alert-" + statusClassName}
              onChange={(e) => this.updateRequest(e.target, this.props.request)}
              defaultValue={this.props.request.status}
            >
              {statusNode}
            </select>
          </td>
        )}
        {!this.props.limit && (
          <td className="col col-1 align-middle text-center">
            {this.props.user.role === Roles.CLIENT ? (
              <MenuButton
                icon={"eye"}
                onClick={(e) => {
                  this.props.openEstimateModal(this.props.request);
                }}
                hover={<FormattedMessage id="Consult" />}
              />
            ) : (
              menuAction
            )}
          </td>
        )}
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateRequest: (data) => dispatch(updateRequest(data)),
    onDeleteRequest: (requestId) => dispatch(deleteRequest(requestId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(RequestRow));
