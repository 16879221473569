import React from "react";
import { connect } from "react-redux";
import CartRow from "./CartRow";
import ModalManager from "../sub/modals/ModalManager";
import { deleteAll } from "../../actions/cart/cart";
import { FormattedMessage, injectIntl } from "react-intl";
import { passOrder, passOrderAdmin } from "../../actions/orders/orders";
import ProductsTotalUtil from "../../util/ProductsTotalUtil";
import Roles from "../../enums/Roles";
import Paginator from "../sub/Paginator";
import { Link } from "react-router-dom";
import PriceUtil from "../../util/PriceUtil";
import DateUtil from "../../util/DateUtil";
import APIUrl from "../../APIUrl";
import { getListProducts } from "../../actions/products/products";
import Icon from "../sub/Icon";
import TableToolbar from "../sub/bootstrap/TableToolbar";
import { Card, Badge, Col, Container, Row, Alert } from "react-bootstrap";
import MenuButton from "../sub/bootstrap/MenuButton";
import TextareaCounter from "react-textarea-counter";
import ProductsParserUtil from "../../util/ProductsParserUtil";

class Cart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      disableButton: true,
    };

    this.paginator = new Paginator(this);
  }

  checkProductsExistById(productsId) {
    for (let i = 0; i < this.props.products.length; i++) {
      if (this.props.products[i]._id === productsId) {
        return false;
      }
    }
    return true;
  }

  getProduct(productId) {
    for (let product of this.props.products) {
      if (product._id === productId) return product;
    }

    return null;
  }

  componentDidMount() {
    let checkProducts = [];

    for (let cart of this.props.cart) {
      if (this.checkProductsExistById(cart.id_product)) {
        checkProducts.push(cart.id_product);
      }
    }

    if (checkProducts.length) {
      this.props.onGetListProducts(checkProducts);
    }
  }

  passOrder(instantValidation = true) {
    var comment = document.getElementById("comment").value;
    var data = { comment: comment };

    if (instantValidation) data.instantValidation = true;

    // Admin
    if (
      this.props.user.role === Roles.SALES_REP ||
      this.props.user.role === Roles.ADMIN
    ) {
      // Add the clientId to the call
      var clientId = document.getElementById("order-clientId").value;
      data.clientId = clientId;

      this.props.onPassOrderAdmin(data);
    }
    // User
    else {
      this.props.onPassOrder(data);
    }
  }

  deleteAll(e) {
    this.props.onDeleteAll();
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ModalManager
          showModal={true}
          title={title}
          content={content}
          successCallback={successCallback}
          closeModal={() => this.closeModal()}
          modalType="confirmation"
        />
      ),
    });
  }

  openOrderPromptModal(instantValidation = false) {
    const promptModalTitle = instantValidation ? (
      <FormattedMessage id="Order.Confirm" />
    ) : this.props.user.validator ? (
      <FormattedMessage id="Put.On.Hold" />
    ) : (
      <FormattedMessage id="Submit.For.Approval" />
    );
    let promptModalContent = (
      <Alert variant={!instantValidation ? "warning" : "success"}>
        {instantValidation ? (
          <FormattedMessage id="Order.Confirm.Message" />
        ) : this.props.user.validator ? (
          <FormattedMessage id="Put.On.Hold.Message" />
        ) : (
          <FormattedMessage id="Submit.For.Approval.Message" />
        )}
      </Alert>
    );
    promptModalContent = (
      <>
        {promptModalContent}
        <FormattedMessage id="Comment" /> (<FormattedMessage id="Optional" />)
        <TextareaCounter
          countLimit={500}
          className="textareaCounter"
          id="comment"
        />
      </>
    );

    this.setState({
      modal: (
        <ModalManager
          showModal={true}
          title={promptModalTitle}
          content={promptModalContent}
          successCallback={(e) => this.passOrder(instantValidation)}
          closeModal={() => this.closeModal()}
          modalType="confirmation"
          size="lg"
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  render() {
    var ordersSettings;
    if (
      this.props.ordersSettings.length === 0 &&
      this.props.ordersSettingsAdmin.length === 0
    )
      return null;
    if (this.props.ordersSettings.length !== 0)
      ordersSettings = this.props.ordersSettings;
    if (this.props.ordersSettingsAdmin.length !== 0)
      ordersSettings = this.props.ordersSettingsAdmin;

    var offreOfMoment;
    if (this.props.promoOperations.length > 0) {
      var promoOperation =
        this.props.promoOperations[
          Math.floor(
            Math.random() * Math.floor(this.props.promoOperations.length),
          )
        ];
      var product = this.getProduct(promoOperation.product_id);
      if (!product) {
        offreOfMoment = null;
      } else {
        offreOfMoment = (
          <>
            <Card className="w-50 m-auto" border="success">
              <Card.Body>
                <Card.Title className="text-success">
                  <Icon icon="medal" className="mr-2" />
                  {this.props.intl.formatMessage({
                    id: "Offer.Of.Moment.Until",
                  }) +
                    DateUtil.toDate(promoOperation.endDate) +
                    ")"}
                </Card.Title>
                <Card.Text>
                  <Container>
                    <Row className="mb-3">
                      <Col xs={10} className="p-0">
                        {ProductsParserUtil.swapDesignationDelimiter(
                          product.designation,
                        ) +
                          " " +
                          product.unite_de_vente +
                          " "}
                      </Col>

                      <Col className="text-right p-0">
                        <h4>
                          <Badge variant="danger">
                            {PriceUtil.priceTtc(product, 1, 2) + "€"}
                          </Badge>
                        </h4>
                      </Col>
                    </Row>
                  </Container>
                  <Card.Footer>
                    <div className="text-right">
                      {promoOperation.fileName && (
                        <a
                          className="text-warning"
                          role="button"
                          href={
                            APIUrl.getPromoOperationFile +
                            promoOperation._id +
                            "/" +
                            promoOperation.fileName +
                            "?token=" +
                            APIUrl.jwtToken
                          }
                          rel="noopener noreferrer"
                          download={promoOperation.fileName}
                          target="_blank"
                        >
                          <MenuButton
                            icon="file"
                            className="mr-2"
                            variant="outline-success"
                          >
                            <FormattedMessage id="Know.More" />
                          </MenuButton>
                        </a>
                      )}
                      <Link
                        to={
                          "/home/products/mercurial/" +
                          product.mercurial_id +
                          "/" +
                          encodeURI(product.famille) +
                          "/" +
                          product._id
                        }
                        className="text-decoration-none"
                      >
                        <MenuButton variant="success" icon="shopping-cart">
                          <FormattedMessage id="Consult" />
                        </MenuButton>
                      </Link>
                    </div>
                  </Card.Footer>
                </Card.Text>
              </Card.Body>
            </Card>
          </>
        );
      }
    }

    if (this.props.cart.length === 0) {
      return (
        <React.Fragment>
          {offreOfMoment}
          <TableToolbar>
            <div className="alert alert-info w-100">
              <FormattedMessage id="Empty.Cart" />
            </div>
            <Link to="/home/families">
              <MenuButton variant="info">
                <FormattedMessage id="Go.To.Mercurial" />
              </MenuButton>
            </Link>
          </TableToolbar>
        </React.Fragment>
      );
    }

    // Helper that will do all calculations for us
    var productsTotalUtil = new ProductsTotalUtil(
      this.props.cart,
      this.props.products,
      null,
      null,
      ordersSettings,
    );
    // 'Delete cart' modal setup
    var modalTitle = <FormattedMessage id="Confirm" />;
    var modalContent = <FormattedMessage id="Empty.Cart.Confirm" />;
    var successCallbackConfModal = (e) => this.deleteAll(e);

    this.paginator.init(this.props.cart.length);
    let cartNode = this.props.cart.map((cart) => {
      if (!this.paginator.keep()) return null;

      var product = this.getProduct(cart.id_product);

      return (
        <CartRow
          key={cart._id}
          cart={cart}
          product={product}
          openConfModal={(title, content, successCallback) =>
            this.openConfModal(title, content, successCallback)
          }
        />
      );
    });

    var shoppingAlert = null;
    var shippingAlert = null;

    if (!productsTotalUtil.aboveMinToOrder()) {
      shoppingAlert = (
        <div className="alert alert-danger" role="alert">
          <FormattedMessage
            id="Min.Order.Cost"
            values={{ cost: productsTotalUtil.min_cost_to_order }}
          />
        </div>
      );
    }

    if (productsTotalUtil.freeShipping()) {
      var max =
        ordersSettings.shipping_costs[ordersSettings.shipping_costs.length - 1]
          .max;
      var min =
        ordersSettings.shipping_costs[ordersSettings.shipping_costs.length - 1]
          .min;
      shippingAlert = (
        <div className="alert alert-info" role="alert">
          <FormattedMessage
            id="Min.Shipping.Cost"
            values={{ cost: max || min }}
          />
        </div>
      );
    }

    return (
      <React.Fragment>
        {offreOfMoment}
        <a href="#/home/families">
          <MenuButton variant="info">
            <FormattedMessage id="Go.To.Mercurial" />
          </MenuButton>
        </a>

        <div className="content mt-3">
          {this.state.modal}
          {shoppingAlert}
          {shippingAlert}

          <table className="table table-striped tablee4coll">
            <thead>
              <tr>
                <th scope="col" className="col col-md-auto">
                  <FormattedMessage id="Category" />
                </th>
                <th scope="col" className="col col-md-auto">
                  <FormattedMessage id="Designation" />
                </th>
                <th scope="col" className="col col-md-auto">
                  <FormattedMessage id="Vending.unit" />
                </th>
                <th scope="col" className="col col-md-auto">
                  <FormattedMessage id="Reference" />
                </th>
                <th scope="col" className="col col-md-auto">
                  <FormattedMessage id="Qty" />
                </th>
                <th scope="col" className="col col-md-auto">
                  <FormattedMessage id="Unit.Price.Excl.Tax" />
                </th>
                <th scope="col" className="col col-md-auto">
                  <FormattedMessage id="Unit.Tax" />
                </th>
                <th scope="col" className="col col-md-auto">
                  <FormattedMessage id="Unit.Price.Incl.Tax" />
                </th>
                <th scope="col" className="col col-md-auto">
                  <FormattedMessage id="Total" />{" "}
                  <FormattedMessage id="Excl.Tax" />
                </th>
                <th scope="col" className="col col-md-auto">
                  <FormattedMessage id="Total" />{" "}
                  <FormattedMessage id="Incl.Tax" />
                </th>
                <th scope="col" className="col col-1 text-center">
                  <FormattedMessage id="Actions" />
                </th>
              </tr>
            </thead>
            <tbody>{cartNode}</tbody>
          </table>

          {this.paginator.render()}

          <div className="card col-12 col-lg-5 offset-lg-7">
            <div className="card-body">
              <div className="container">
                <table className="table">
                  <tbody>
                    <tr className="d-flex">
                      <td className="col col-md-auto">
                        <FormattedMessage id="Total.Cart.Excl.Tax" />
                      </td>
                      {productsTotalUtil.aboveMinToOrder() ? (
                        <td className="col text-right">
                          <b>{productsTotalUtil.totalHtCart}€</b>
                        </td>
                      ) : (
                        <td className="col text-right">
                          <b className="text-danger">
                            {productsTotalUtil.totalHtCart}€
                          </b>

                          <Icon
                            id="aboveMinToOrder"
                            icon="triangle-exclamation"
                            className={"text-danger ml-4"}
                            hover={
                              <FormattedMessage
                                id="Cart.Min.Order"
                                values={{
                                  amount: productsTotalUtil.min_cost_to_order,
                                  missing: Number(
                                    productsTotalUtil.min_cost_to_order -
                                      productsTotalUtil.totalHtCart,
                                  ).toFixed(1),
                                }}
                              />
                            }
                          />
                        </td>
                      )}
                    </tr>
                    {productsTotalUtil.urgent && (
                      <tr className="d-flex text-danger">
                        <td className="col col-md-auto">
                          <FormattedMessage id="Emergency.Cost" />
                        </td>
                        <td className="col text-right">
                          <b>{productsTotalUtil.urgent_shipping_cost}€</b>
                        </td>
                      </tr>
                    )}
                    {productsTotalUtil.aboveMinToOrder() && (
                      <tr className="d-flex">
                        <td className="col col-md-auto">
                          <FormattedMessage id="Shipping.Cost" />
                        </td>
                        <td className="col text-right">
                          <b>{productsTotalUtil.shippingCost}€</b>
                        </td>
                      </tr>
                    )}
                    <tr className="d-flex">
                      <td className="col col-md-auto">
                        <FormattedMessage id="Total.Excl.Tax" />
                      </td>
                      <td className="col text-right">
                        <b>{productsTotalUtil.totalHt}€</b>
                      </td>
                    </tr>
                    <tr className="d-flex">
                      <td className="col col-md-auto">
                        <FormattedMessage id="VAT" />
                      </td>
                      <td className="col text-right">
                        <b>{productsTotalUtil.totalTva}€</b>
                      </td>
                    </tr>
                    <tr className="d-flex">
                      <td className="col col-md-auto">
                        <FormattedMessage id="Total.Incl.Tax" />
                      </td>
                      <td className="col text-right">
                        <b>{productsTotalUtil.totalTtc}€</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="container">
                <div className="row text-center">
                  <div
                    className={
                      this.props.user.validator
                        ? "col-12 col-sm-4 mb-1"
                        : "col-12 col-sm-6 mb-1"
                    }
                  >
                    <button
                      className="btn btn-sm btn-secondary btn-block"
                      onClick={(e) =>
                        this.openConfModal(
                          modalTitle,
                          modalContent,
                          successCallbackConfModal,
                        )
                      }
                    >
                      <FormattedMessage id="Cart.Empty" />
                    </button>
                  </div>
                  {this.props.user.validator && (
                    <div className="col-12 col-sm-4">
                      <button
                        className="btn btn-sm btn-warning btn-block"
                        onClick={(e) => this.openOrderPromptModal(false)}
                        disabled={!productsTotalUtil.aboveMinToOrder()}
                      >
                        <FormattedMessage id="Put.On.Hold" />
                      </button>
                    </div>
                  )}
                  <div
                    className={
                      this.props.user.validator
                        ? "col-12 col-sm-4"
                        : "col-12 col-sm-6"
                    }
                  >
                    <button
                      className="btn btn-sm btn-info btn-block"
                      onClick={(e) =>
                        this.openOrderPromptModal(
                          this.props.user.validator ? true : false,
                        )
                      }
                      disabled={!productsTotalUtil.aboveMinToOrder()}
                    >
                      <FormattedMessage
                        id={
                          this.props.user.validator
                            ? "Finalize.Order"
                            : "Submit.For.Approval"
                        }
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.i18n.lang,
    cart: state.cart,
    products: state.products,
    user: state.user,
    ordersSettings: state.ordersSettings,
    ordersSettingsAdmin: state.ordersSettingsAdmin,
    promoOperations: state.promoOperations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteAll: () => dispatch(deleteAll()),
    onPassOrder: (data) => dispatch(passOrder(data)),
    onPassOrderAdmin: (data) => dispatch(passOrderAdmin(data)),
    onGetListProducts: (data) => dispatch(getListProducts(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Cart));
