import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Col, Row } from "react-bootstrap";
import MenuButton from "../../../../sub/bootstrap/MenuButton";
import Checkpoints from "./Checkpoints";
import {
  addCheckpointToMT,
  deleteCheckpointFromMT,
  sortCheckpoint,
  updateCheckpoint,
} from "../../../../../actions/settings/materialsTypes/materialsTypes";
import CreateCheckpointModal from "./CreateCheckpointModal";
import Util from "../../../../../util/Util";

function MaterialsTypesModalCheckpoints({
  materialType,
  onSortCheckpoint,
  onAddCheckpointToMT,
  onUpdateCheckpoint,
  onDeleteCheckpointFromMT,
}) {
  const [checkpoints, setCheckpoints] = useState(
    (materialType && materialType.checkpoints) || [],
  );
  const [modal, setModal] = useState(null);

  useEffect(() => {
    if (materialType) setCheckpoints(materialType.checkpoints);
  }, [materialType]);

  const closeModal = () => setModal(null);

  const sortPoint = (checkpoint, direction) => {
    if (!materialType._id || !checkpoint) return;

    direction = direction === "up" || direction === "down" ? direction : "down";

    const data = {
      materialTypeId: materialType._id,
      checkpointId: checkpoint._id,
      direction: direction,
      updatedValue:
        direction === "down"
          ? checkpoint.sortIndex + 1
          : checkpoint.sortIndex - 1,
    };

    onSortCheckpoint(data, () => closeModal());
  };

  const addCheckpoint = (checkpointName) => {
    if (!materialType._id || Util.emptyString(checkpointName)) return;

    const data = {
      materialTypeId: materialType._id,
      checkpoint: {
        name: checkpointName,
        sortIndex: checkpoints.length + 1,
      },
    };

    onAddCheckpointToMT(data, () => closeModal());
  };

  const deleteCheckpoint = (checkpoint) => {
    if (!materialType._id || !checkpoint) return;

    onDeleteCheckpointFromMT(
      {
        materialTypeId: materialType._id,
        checkpointId: checkpoint._id,
      },
      () => closeModal(),
    );
  };

  const openCreateCheckpointModal = () => {
    setModal(
      <CreateCheckpointModal
        addCheckpoint={(checkpoint) => addCheckpoint(checkpoint)}
        close={() => closeModal()}
      />,
    );
  };

  return (
    <>
      <Row>
        <Col className="text-right">
          <MenuButton
            variant="info"
            icon="circle-plus"
            className="mr-3"
            onClick={() => openCreateCheckpointModal()}
          >
            <FormattedMessage id="Add.Checkpoint" />
          </MenuButton>
        </Col>
      </Row>

      <Row className="m-3">
        <Checkpoints
          materialTypeId={materialType._id}
          checkpoints={checkpoints}
          onSort={(checkpoint, direction) => sortPoint(checkpoint, direction)}
          onUpdateCheckpoint={(data) =>
            onUpdateCheckpoint(data, () => closeModal())
          }
          onDeleteCheckpoint={(checkpoint) => deleteCheckpoint(checkpoint)}
        />
      </Row>

      {modal}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSortCheckpoint: (data, successCallback) =>
      dispatch(sortCheckpoint(data, successCallback)),
    onAddCheckpointToMT: (data, successCallback) =>
      dispatch(addCheckpointToMT(data, successCallback)),
    onUpdateCheckpoint: (data, successCallback) =>
      dispatch(updateCheckpoint(data, successCallback)),
    onDeleteCheckpointFromMT: (data, successCallback) =>
      dispatch(deleteCheckpointFromMT(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(MaterialsTypesModalCheckpoints));
