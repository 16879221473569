import React, { useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import MaterialsTypesModalAdmin from "./MaterialsTypesModalAdmin";
import MaterialsTypesTableAdmin from "./MaterialsTypesAdminTable";
import TableToolbar from "../../../../sub/bootstrap/TableToolbar";
import { Alert, Button } from "react-bootstrap";

function MaterialsTypesAdmin({ materialsTypes }) {
  const [modal, setModal] = useState(null);

  const closeModal = () => setModal(null);

  const openMaterialsTypesModal = () => {
    setModal(
      <MaterialsTypesModalAdmin
        materialTypeId={null}
        closeModal={() => closeModal()}
      />,
    );
  };

  return (
    <>
      <TableToolbar>
        <Button
          variant="info"
          className="ml-auto"
          onClick={(e) => openMaterialsTypesModal()}
        >
          <FormattedMessage id="Add.Material" />
        </Button>
      </TableToolbar>

      {materialsTypes.length > 0 ? (
        <MaterialsTypesTableAdmin materialsTypes={materialsTypes} />
      ) : (
        <Alert variant="info" className="col-12">
          <FormattedMessage id="Empty.Material.Type" />
        </Alert>
      )}

      {modal}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    materialsTypes: state.materialsTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MaterialsTypesAdmin);
