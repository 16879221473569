import CustomPagination from "./CustomPagination";
import { Col, Container, Form, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import CustomLabel from "./CustomLabel.js";

export default class Pagination {
  constructor(parent) {
    this.parent = parent;
    this.nbrElems = 0;
    this.paginationIndex = 1;
    this.paginationElems = window.localStorage.getItem("paginationElems") || 5;
    this.pageRangeDisplayed = 5;
    this.fetchData = null;
    this.disabled = false;
  }

  keep() {
    if (
      this.nbrElems < (this.paginationIndex - 1) * this.paginationElems ||
      this.nbrElems >= this.paginationIndex * this.paginationElems
    ) {
      // Increment
      this.nbrElems++;
      return false;
    }
    // Increment
    this.nbrElems++;
    return true;
  }

  init(nbResults = null, nbrElems = 0, fetchData) {
    this.nbrElems = nbrElems;
    this.nbResults = nbResults;
    this.fetchData = fetchData;
  }

  updatePaginationElementNumber(value) {
    this.paginationElems = value;
    window.localStorage.setItem("paginationElems", value);
    this.updateIndex(1);
    if (this.fetchData) {
      this.fetchData(this.paginationIndex, this.paginationElems);
    }
  }

  updateIndex(i) {
    this.paginationIndex = i;
    this.nbrElems = 0;
    this.parent.setState({});
  }

  handlePageChange(pageNumber) {
    this.updateIndex(pageNumber);
    if (this.fetchData) {
      this.fetchData(pageNumber, this.paginationElems);
    }
  }

  render() {
    if (this.nbrElems < this.pageRangeDisplayed) return null;

    const isXsScreen = window.innerWidth < 576;

    return (
      <Container fluid>
        <Row className="align-items-center">
          <Col
            xs={12}
            md={3}
            className={
              isXsScreen
                ? "mb-3 text-secondary text-center"
                : "p-0 ps-3 text-secondary"
            }
          >
            <small>
              <FormattedMessage id="Total" /> : {this.nbResults}
            </small>
          </Col>
          <Col xs={12} md={6} className={isXsScreen ? "mb-3" : "p-0"}>
            <div className="pagination justify-content-center">
              {!(this.nbrElems <= this.paginationElems) && (
                <CustomPagination
                  activePage={this.paginationIndex}
                  itemsCountPerPage={parseInt(this.paginationElems)}
                  totalItemsCount={this.nbrElems}
                  pageRangeDisplayed={this.pageRangeDisplayed}
                  onChange={this.handlePageChange.bind(this)}
                  disabled={this.disabled}
                />
              )}
            </div>
          </Col>
          <Col xs={12} md={3} className="p-0 pe-3 text-end text-secondary">
            <small>
              <Row
                className={
                  isXsScreen
                    ? "justify-content-center align-items-center"
                    : "justify-content-end align-items-center"
                }
              >
                <Col xs={"auto"} md={"auto"} className="m-0 p-0">
                  <FormattedMessage id="Elements.Per.Page" />
                  <CustomLabel
                    label={<FormattedMessage id="Elements.Per.Page" />}
                    htmlFor="search_name"
                    labelClassName="me-0 mb-0"
                  />
                </Col>
                <Col
                  xs={"auto"}
                  md={"auto"}
                  className="d-flex align-items-center"
                >
                  <Form.Control
                    as="select"
                    size="sm"
                    defaultValue={this.paginationElems}
                    disabled={this.disabled}
                    onChange={(e) => {
                      this.updatePaginationElementNumber(e.target.value);
                    }}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                  </Form.Control>
                </Col>
              </Row>
            </small>
          </Col>
        </Row>
      </Container>
    );
  }
}
