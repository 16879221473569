import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import StringUtil from "../../util/StringUtil";
import {
  addResident,
  addResidentAdmin,
  checkIfResidentExists,
  checkIfResidentExistsAdmin,
  updateResident,
  updateResidentAdmin,
} from "../../actions/residents/residents";
import UserGender from "../../enums/UserGender";
import CustomLabel from "../sub/CustomLabel";
import Roles from "../../enums/Roles";
import { Button, Modal } from "react-bootstrap";

class ResidentModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resident_id: "",
      name: "",
      first_name: "",
      gender: "",
      // Errors
      uniqueError: null,

      disabled: false,
    };
    if (this.props.resident !== undefined) {
      this.state = {
        resident_id: this.props.resident._id,
        name: this.props.resident.name,
        first_name: this.props.resident.first_name,
        gender: this.props.resident.gender,
      };
    }
  }

  checkUniqueness(name, first_name, noErrorCallback) {
    // Make sure the name / first name combination is unique
    if (
      this.props.user.role === Roles.ADMIN ||
      this.props.user.role === Roles.SALES_REP
    ) {
      this.props.onCheckIfResidentExistsAdmin(
        {
          name: name,
          first_name: first_name,
          establishment_id: this.props.establishmentId,
        },
        // Resident exists. Return error
        () => {
          this.setState({
            uniqueError: <FormattedMessage id="Resident.Already.Exists" />,
          });
        },
        // Resident does not exist!
        () => noErrorCallback(),
      );
    } else {
      this.props.onCheckIfResidentExists(
        { name: name, first_name: first_name },
        // Resident exists. Return error
        () => {
          this.setState({
            uniqueError: <FormattedMessage id="Resident.Already.Exists" />,
          });
        },
        // Resident does not exist!
        () => noErrorCallback(),
      );
    }
  }

  onComplete(mode) {
    var crudResident = () => {
      this.setState({ disabled: true });
      let resident = {
        name: this.state.name,
        first_name: this.state.first_name,
        gender: this.state.gender,
        establishment_id: this.props.establishmentId,
      };

      if (mode === "Modify") {
        resident = {
          ...resident,
          resident_id: this.state.resident_id,
        };

        if (
          this.props.user.role === Roles.ADMIN ||
          this.props.user.role === Roles.SALES_REP
        ) {
          this.props.onUpdateResidentAdmin(resident, () => this.props.close());
        } else {
          this.props.onUpdateResident(resident, () => this.props.close());
        }
      } else {
        if (
          this.props.user.role === Roles.ADMIN ||
          this.props.user.role === Roles.SALES_REP
        ) {
          this.props.onAddResidentAdmin(resident, () => this.props.close());
        } else {
          this.props.onAddResident(resident, () => this.props.close());
        }
      }
    };

    // Validate fields & send to BE
    this.checkUniqueness(this.state.name, this.state.first_name, crudResident);
  }

  onFieldChange(field, value) {
    this.setState({ [field]: value });
  }

  disabled() {
    return (
      Util.emptyString(this.state.name) ||
      Util.emptyString(this.state.first_name) ||
      Util.emptyString(this.state.gender) ||
      Util.typeOf(this.state.gender) === "Undefined" ||
      this.state.disabled
    );
  }

  render() {
    //*************** List of gender ********/

    var eqGenderNode = Object.values(UserGender).map((option) => {
      return (
        <option key={option} value={option}>
          {this.props.intl.formatMessage({
            id: "UserGender." + option,
          })}
        </option>
      );
    });
    return (
      <Modal show={true} onHide={() => this.props.close()} backdrop={"static"}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage
              id={this.state.resident_id ? "Resident.Edit" : "Resident.Add"}
            />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group row">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Sexe" })}
              htmlFor="checkpoint"
              required
            />
            <div className="col-12 col-md-7">
              <select
                className="form-control"
                value={this.state.gender}
                onChange={(e) => {
                  this.setState({ gender: e.target.value });
                }}
              >
                <option value="">
                  {this.props.intl.formatMessage({ id: "Select" })}
                </option>
                {eqGenderNode}
              </select>
            </div>
          </div>

          <div className="form-group row">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Name" })}
              htmlFor="resident-name"
              required
            />
            <div className="col-12 col-md-7">
              <input
                type="text"
                className="form-control"
                id="resident-name"
                autoComplete="off"
                value={this.state.name}
                onChange={(e) =>
                  this.onFieldChange("name", e.target.value.toUpperCase())
                }
              />
            </div>
          </div>

          <div className="form-group row">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "First.Name" })}
              htmlFor="resident-first_name"
              required
            />
            <div className="col-12 col-md-7">
              <input
                type="text"
                className="form-control"
                id="resident-first_name"
                autoComplete="off"
                value={this.state.first_name}
                onChange={(e) =>
                  this.onFieldChange(
                    "first_name",
                    StringUtil.ucFirst(e.target.value),
                  )
                }
              />
              <div className="text-danger">
                <small>{this.state.uniqueError}</small>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.props.close()}>
            <FormattedMessage id="Cancel" />
          </Button>
          <Button
            variant="info"
            onClick={() =>
              this.onComplete(this.state.resident_id ? "Modify" : "Add")
            }
            disabled={this.disabled()}
          >
            <FormattedMessage id={this.state.resident_id ? "Modify" : "Add"} />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddResident: (resident, successCallback) =>
      dispatch(addResident(resident, successCallback)),
    onAddResidentAdmin: (resident, successCallback) =>
      dispatch(addResidentAdmin(resident, successCallback)),
    onCheckIfResidentExists: (resident, existsCallback, noExistsCallback) =>
      dispatch(
        checkIfResidentExists(resident, existsCallback, noExistsCallback),
      ),
    onCheckIfResidentExistsAdmin: (
      resident,
      existsCallback,
      noExistsCallback,
    ) =>
      dispatch(
        checkIfResidentExistsAdmin(resident, existsCallback, noExistsCallback),
      ),
    onUpdateResident: (resident, successCallback) =>
      dispatch(updateResident(resident, successCallback)),
    onUpdateResidentAdmin: (resident, successCallback) =>
      dispatch(updateResidentAdmin(resident, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ResidentModal));
