import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import CustomLabel from "../../../sub/CustomLabel";
import { Button, Modal } from "react-bootstrap";

class ShippingModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.index === -1) {
      let max =
        this.props.shippingCosts.length > 0
          ? this.props.shippingCosts[this.props.shippingCosts.length - 1].max
          : 0;

      this.state = {
        min: max,
        max: max + 1,
        cost: 0,
        disabledInputMax: true,
        defaultChecked: true,
        alert: null,
      };
    } else {
      var hasMax = this.props.shippingCosts[this.props.index].max;

      this.state = {
        min: this.props.shippingCosts[this.props.index].min,
        max: this.props.shippingCosts[this.props.index].max,
        cost: this.props.shippingCosts[this.props.index].cost,
        disabledInputMax: !hasMax,
        defaultChecked: !hasMax,
        alert: null,
      };
    }
  }

  close() {
    this.props.closeModal();
  }

  onComplete() {
    if (this.state.min >= this.state.max && !this.state.disabledInputMax) {
      this.setState({ alert: <FormattedMessage id="Min.Greater.Max" /> });
      return false;
    }

    let shippingCosts = this.props.shippingCosts || [];

    let shippingCost = {
      min: this.state.min,
      max: this.state.max,
      cost: this.state.cost || 0,
    };

    if (this.state.disabledInputMax) shippingCost.max = "";

    // Costs exist, modify appropriate line
    if (this.props.index !== -1) shippingCosts[this.props.index] = shippingCost;
    // Costs do not exist, add a new line
    else shippingCosts.push(shippingCost);

    this.props.successCallback(shippingCosts);

    // This is an update, so don't close the window
    if (this.props.index === -1) this.close();
  }

  onChangeMin(min) {
    this.setState({ min: min });
  }

  onChangeMax(max) {
    this.setState({ max: max });
  }

  onChangeCost(cost) {
    this.setState({ cost: cost });
  }

  onClickRadioMax(e) {
    if (this.state.disabledInputMax)
      this.setState({
        max: document.getElementById("maxShippingCost").value,
        disabledInputMax: false,
      });
    else this.setState({ disabledInputMax: true });
  }

  render() {
    var buttonText = <FormattedMessage id="Create" />;
    if (this.props.shippingCost) buttonText = <FormattedMessage id="Modify" />;

    return (
      <Modal show={true} onHide={() => this.close()} backdrop={"static"}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Shipping.Cost" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group row">
            <div className="col-10 m-auto">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Minimum" })}
                htmlFor="Min"
                labelClassName={this.props.labelClassName}
                required={this.props.required}
              />
              <input
                type="number"
                disabled={true}
                onChange={(e) => this.onChangeMin(e.target.value)}
                className="form-control"
                min="0"
                value={this.state.min}
                id="minShippingCost"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="col-10 m-auto">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Maximum" })}
                htmlFor="Max"
                labelClassName={this.props.labelClassName}
                required={this.props.required}
              />
              <div className="m-auto align-items-center row">
                <div className="row m-0 align-items-center col-8 p-0">
                  <input
                    className="btnRadioMax"
                    type="radio"
                    name="max"
                    onBlur={() => this.props.index !== -1 && this.onComplete()}
                    onChange={(e) => this.onClickRadioMax(e)}
                    value="number"
                    defaultChecked={!this.state.defaultChecked}
                  />
                  <input
                    type="number"
                    onBlur={() => this.props.index !== -1 && this.onComplete()}
                    onChange={(e) => this.onChangeMax(e.target.value)}
                    disabled={this.state.disabledInputMax}
                    className="form-control ml-2 col-10"
                    min="0"
                    value={this.state.max || this.state.min + 1}
                    id="maxShippingCost"
                  />
                </div>
                <div className="row m-0 align-items-center col-3 p-0">
                  <input
                    className="btnRadioMax"
                    type="radio"
                    name="max"
                    onBlur={() => this.props.index !== -1 && this.onComplete()}
                    onChange={(e) => this.onClickRadioMax(e)}
                    defaultChecked={this.state.defaultChecked}
                    value="infinity"
                  />
                  <h4 className="m-0 ml-2">&infin;</h4>
                </div>
              </div>
              <small className="ml-2 col-8 row text-danger">
                {this.state.alert}
              </small>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-10 m-auto">
              <CustomLabel
                label={this.props.intl.formatMessage({
                  id: "Shipping.Cost.Excl.Tax",
                })}
                htmlFor="Cost"
                labelClassName={this.props.labelClassName}
                required={this.props.required}
              />
              <input
                type="number"
                onBlur={() => this.props.index !== -1 && this.onComplete()}
                onChange={(e) => this.onChangeCost(e.target.value)}
                className="form-control"
                min="0"
                value={this.state.cost}
                id="cost"
              />
            </div>
          </div>
        </Modal.Body>

        {this.props.index === -1 && (
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.close()}>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button variant="info" onClick={() => this.onComplete()}>
              {buttonText}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

export default injectIntl(ShippingModalAdmin);
