import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// https://fontawesome.com/v5/docs/web/use-with/react
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import { Tooltip, OverlayTrigger } from "react-bootstrap";

library.add(fas, far);

export default class Icon extends React.Component {
  render() {
    /**
     * https://fontawesome.com/docs/web/use-with/react/
     * icon : The name like provided by fontawesome
     * className : Additionnal bootstrap className
     * disabled : icon looks and acts as disabled
     * id : icon (element) id
     * onClick : icon onclik action
     * clickable : Set style on the icon to act like a link (on hover)
     * variant : which fontawesome collection to use ("fas" = solid, "far" = regular, "fab" = brand)
     * size : Icon size. If other than normal (none specified), must be one of these values (see availableSizes const)
     */
    const {
      icon,
      className,
      disabled,
      id,
      onClick,
      clickable,
      variant,
      size,
      fw,
      hover,
    } = this.props;

    const availableSizes = [
      "10x",
      "1x",
      "2x",
      "3x",
      "4x",
      "5x",
      "6x",
      "7x",
      "8x",
      "9x",
      "2xs",
      "lg",
      "sm",
      "xl",
      "xs",
      "2xl",
    ];

    const collection =
      variant && (variant === "fas" || variant === "far" || variant === "fab")
        ? variant
        : "fas";

    let finalClassName = "";

    // Add disabled class for styling (if required)
    finalClassName += disabled ? className + " disabled" : className;

    const iconSize = availableSizes.includes(size) ? size : "1x";

    // Disable pointer if clickable attribute is true BUT disabled is true too
    const checkClickable = disabled ? false : clickable ? true : false;

    const iconElement = (
      <FontAwesomeIcon
        id={id}
        size={iconSize}
        onClick={onClick}
        icon={[collection, icon]}
        className={finalClassName}
        role={checkClickable ? "button" : ""}
        disabled={disabled}
        fixedWidth={fw ? false : true}
      />
    );

    return (
      <React.Fragment>
        {hover && (
          <OverlayTrigger
            delay={{ hide: 0, show: 0 }}
            overlay={(props) => <Tooltip {...props}>{hover}</Tooltip>}
            placement="top"
          >
            {iconElement}
          </OverlayTrigger>
        )}

        {!hover && iconElement}
      </React.Fragment>
    );
  }
}
