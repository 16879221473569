import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import RequestTypes from "../../enums/RequestTypes";
import Roles from "../../enums/Roles";
import RequestsAdmin from "../requests/admin/RequestsAdmin";
import Requests from "../requests/Requests";
import { Modal } from "react-bootstrap";

class InterventionRequestsModal extends React.Component {
  render() {
    const { user, equipment, close, establishmentSettings } = this.props;
    if (!user) return null;

    return (
      <Modal show={true} onHide={() => close()} backdrop={"static"} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Interventions" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {user.role === Roles.ADMIN || user.role === Roles.SALES_REP ? (
            <RequestsAdmin
              equipment={equipment}
              establishmentSettings={establishmentSettings}
              equipmentId={equipment._id}
              establishmentId={equipment.establishment_id}
              interventionRequest={RequestTypes.INTERVENTION}
            />
          ) : (
            <Requests
              equipment={equipment}
              establishmentSettings={establishmentSettings}
              equipmentId={equipment._id}
              required={false}
              interventionRequest={RequestTypes.INTERVENTION}
            />
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InterventionRequestsModal);
