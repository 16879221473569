import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import UpdateAccountForm from "./UpdateAccountForm";
import UpdatePwdForm from "./UpdatePwdForm";
import Contacts from "./Contacts";

import Role from "../../enums/Roles";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import Util from "../../util/Util";

class Account extends React.Component {
  render() {
    if (Util.emptyObject(this.props.user)) return null;
    if (
      this.props.user.role === Role.CLIENT &&
      Util.emptyObject(this.props.establishment)
    )
      return null;

    var establishment = null;
    if (this.props.user.role === Role.CLIENT) {
      establishment = this.props.establishment;
    }

    return (
      <Tabs defaultActiveKey="general" id="accountTabs">
        <Tab
          eventKey="general"
          title={this.props.intl.formatMessage({ id: "My.Informations" })}
        >
          <UpdateAccountForm
            user={this.props.user}
            establishment={establishment}
          />
        </Tab>
        <Tab
          eventKey="profile"
          title={this.props.intl.formatMessage({ id: "Change.Password" })}
        >
          <UpdatePwdForm />
        </Tab>
        {this.props.user.role === Role.CLIENT && (
          <Tab
            eventKey="contacts"
            title={this.props.intl.formatMessage({ id: "Contacts" })}
          >
            <Contacts
              admin={this.props.admin}
              generalSettings={this.props.generalSettings}
            />
          </Tab>
        )}
      </Tabs>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    establishment: state.establishment,
    admin: state.admin,
    generalSettings: state.generalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Account));
