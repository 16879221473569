import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import AddMaintenanceModal from "./AddMaintenanceModal";
import MaintenanceRow from "./MaintenanceRow";
import Paginator from "../../sub/Paginator";
import { Button, Modal } from "react-bootstrap";
import Icon from "../../sub/Icon";
import Role from "../../../enums/Roles";
import TableToolbar from "../../sub/bootstrap/TableToolbar";
import { hasAlreadyMaintenance } from "../../../actions/maintenances/maintenances";
import MenuButton from "../../sub/bootstrap/MenuButton";
import ActionMenu from "../../sub/ActionMenu";
import CustomLabel from "../../sub/CustomLabel";

class MaintenancesHistoryModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      hasMaintenanceToSign: false,
      establishmentFilter: this.props.establishment?._id || "",
      displayHistory: false,
    };

    this.paginator = new Paginator(this);
  }

  componentDidMount() {
    this.props
      .onGetHasMaintenanceActive(this.props.equipment._id)
      .then((response) => {
        this.setState({ hasMaintenanceToSign: response });
      });
  }

  closeModal() {
    this.props
      .onGetHasMaintenanceActive(this.props.equipment._id)
      .then((response) => {
        this.setState({ hasMaintenanceToSign: response });
      });
    this.setState({ modal: null });
  }

  getNameEstablishment(establishmentid) {
    for (let establishment of this.props.establishments) {
      if (establishment._id === establishmentid) {
        return establishment.name;
      }
    }
    return "";
  }

  openMaintenanceModal(equipment) {
    this.setState({
      modal: (
        <AddMaintenanceModal
          equipment={equipment}
          establishment={this.props.establishment}
          admin={this.props.admin}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  onChange(field, value) {
    this.setState({
      [field]: value,
    });
  }

  sortByDateDesc(maintenance1, maintenance2) {
    if (maintenance1.creation_date > maintenance2.creation_date) return -1;
    if (maintenance1.creation_date < maintenance2.creation_date) return 1;
    return 0;
  }

  render() {
    let maintenance = [];
    let filterEstablishmentList = [];
    for (var m of this.props.maintenances) {
      if (m.equipment_id === this.props.equipment._id) {
        let isInFilter = false;
        for (let filterEstablishment of filterEstablishmentList) {
          if (
            filterEstablishment._id.toString() === m.establishment_id.toString()
          ) {
            isInFilter = true;
            break;
          }
        }
        if (filterEstablishmentList.length === 0 || isInFilter === false) {
          filterEstablishmentList.push({
            _id: m.establishment_id,
            name: this.getNameEstablishment(m.establishment_id),
          });
        }
        if (
          m.establishment_id === this.state.establishmentFilter ||
          this.state.establishmentFilter === ""
        ) {
          maintenance.push(m);
        }
      }
    }
    // If there are some maintenances
    if (maintenance.length > 0) {
      maintenance = maintenance.sort(this.sortByDateDesc);

      this.paginator.init(maintenance.length);
      var maintenancesNode = maintenance.map((maintenance) => {
        if (!this.paginator.keep()) return null;

        return (
          <MaintenanceRow
            key={maintenance._id}
            maintenance={maintenance}
            establishment={this.props.establishment}
            updateParentState={(data) => this.setState(data)}
            admin={this.props.admin}
            equipment={this.props.equipment}
            closeModal={() => this.closeModal()}
          />
        );
      });
    }

    let menuItems = [];

    menuItems.push({
      icon: "circle-plus",
      disabled: !this.props.establishment || this.state.hasMaintenanceToSign,
      action: () => {
        this.openMaintenanceModal(this.props.equipment);
      },
      text: <FormattedMessage id="New.Maintenance" />,
    });

    menuItems.push({
      icon: "history",
      disabled:
        !filterEstablishmentList.length ||
        (filterEstablishmentList.length === 1 &&
          filterEstablishmentList[0]["_id"] ===
            this.props.establishment?._id) ||
        this.state.displayHistory
          ? true
          : false,
      action: () => {
        this.onChange("displayHistory", !this.state.displayHistory);
      },
      text: <FormattedMessage id="Previous.History" />,
    });

    const menuAction = (
      <ActionMenu
        className="ml-auto"
        items={menuItems}
        label={<FormattedMessage id="Actions" />}
        text="Actiu"
        size="2xl"
      />
    );

    return (
      <Modal
        show={true}
        onHide={() => this.props.closeModal()}
        backdrop={"static"}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Maintenances.History" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.props.user.role !== Role.CLIENT && this.props.establishment && (
            <TableToolbar>
              {this.props.user.role === Role.ADMIN &&
                this.state.displayHistory === true && (
                  <React.Fragment>
                    <CustomLabel
                      label={this.props.intl.formatMessage({
                        id: "Client",
                      })}
                      htmlFor="search_client"
                      labelClassName="my-1 mr-2"
                    />
                    <select
                      className="form-control ml-2"
                      onChange={(e) =>
                        this.onChange("establishmentFilter", e.target.value)
                      }
                      defaultValue={this.state.establishmentFilter}
                    >
                      <option key="empty" value="">
                        {this.props.intl.formatMessage({ id: "All" })}
                      </option>
                      {filterEstablishmentList.map((e) => {
                        return (
                          <option key={e._id} value={e._id}>
                            {e.name}
                          </option>
                        );
                      })}
                    </select>
                    <MenuButton
                      size="sm"
                      className="ml-1"
                      onClick={(e) => {
                        this.onChange(
                          "displayHistory",
                          !this.state.displayHistory,
                        );
                        this.onChange(
                          "establishmentFilter",
                          this.props.establishment._id,
                        );
                      }}
                    >
                      <Icon icon="times-circle" className="" />
                    </MenuButton>
                  </React.Fragment>
                )}
              {menuAction}
            </TableToolbar>
          )}

          {maintenance.length < 1 && (
            <div className="alert alert-secondary" role="alert">
              <FormattedMessage id="Empty.Maintenances" />
            </div>
          )}

          {maintenance.length > 0 && (
            <table className="table tablee4coll">
              <thead>
                <tr>
                  <th scope="col" className="col col-4">
                    <FormattedMessage id="Date" />
                  </th>
                  <th scope="col" className="col col-7">
                    {this.props.user.role === Role.ADMIN && (
                      <FormattedMessage id="Establishment" />
                    )}
                  </th>
                  <th scope="col" className="col col-1 text-center">
                    <FormattedMessage id="Actions" />
                  </th>
                </tr>
              </thead>

              <tbody>{maintenancesNode}</tbody>
            </table>
          )}

          {this.paginator.render()}

          {this.state.modal}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="info" onClick={() => this.props.closeModal()}>
            <FormattedMessage id="Close" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    maintenances: state.maintenances,
    establishments: state.establishments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetHasMaintenanceActive: (equipmentId) =>
      dispatch(hasAlreadyMaintenance(equipmentId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(MaintenancesHistoryModal));
