import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import MenuProfile from "./MenuProfile";
import RequestStatus from "../../../enums/RequestStatus";
import RequestTypes from "../../../enums/RequestTypes";
import ProductsTotalUtil from "../../../util/ProductsTotalUtil";
import { passOrder } from "../../../actions/orders/orders";
import OrderStatus from "../../../enums/OrderStatus";
import { NavLink } from "react-router-dom";
import MenuLogo from "./MenuLogo";
import { getManyProducts } from "../../../actions/products/products";
import Icon from "../../sub/Icon";

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ordersSettings: this.props.ordersSettings,
    };

    this.getProductsFromCart();
  }

  getProductsFromCart() {
    if (this.props.cart.length > 0) {
      let productsIds = [];
      for (let cartProduct of this.props.cart) {
        productsIds.push(cartProduct.id_product);
      }
      if (productsIds.length > 0) {
        this.props.onGetManyProducts(productsIds);
      }
    }
  }

  countNewRequests(requests) {
    var nbr = 0;
    for (var request of requests) {
      if (request.request_type === RequestTypes.NEW_MATERIAL) continue;

      if (request.status === RequestStatus.NEW) nbr++;
    }

    return nbr;
  }

  countNewOrders() {
    var nbr = 0;

    for (let order of this.props.orders) {
      if (order.status === OrderStatus.NEW) nbr++;
    }

    return nbr;
  }

  countCartProduct() {
    var nbr = 0;

    for (let c of this.props.cart) {
      nbr += c.quantity;
    }

    return nbr;
  }

  countTotalCart() {
    // Helper that will do all calculations for us
    var productsTotalUtil = 0;
    if (
      this.props.cart.length !== 0 &&
      this.props.products.length !== 0 &&
      this.state.ordersSettings
    ) {
      productsTotalUtil = new ProductsTotalUtil(
        this.props.cart,
        this.props.products,
        null,
        null,
        this.state.ordersSettings,
      );
      return productsTotalUtil.totalTtc;
    }
    return productsTotalUtil;
  }
  render() {
    var totalCart = this.countTotalCart();
    var newOrdersNbr = this.countNewOrders();

    var CartProductsNbr = this.countCartProduct();
    var disabled = true;
    if (this.props.cart.length !== 0 && this.props.products.length !== 0) {
      var productsTotalUtil = new ProductsTotalUtil(
        this.props.cart,
        this.props.products,
        null,
        null,
        this.state.ordersSettings,
      );
      disabled = !productsTotalUtil.aboveMinToOrder();
    }

    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-lg menu">
          <div
            id="menu"
            className="collapse navbar-collapse mb-3 mb-md-0 mt-2 pb-2"
          >
            <ul className="navbar-nav list-inline text-center align-items-center">
              <li className="nav-item list-inline-item align-middle">
                <img
                  className="logo-header"
                  alt="logo PEPSiCOLL"
                  src="./images/svg/logo_pepsicoll.png"
                />
              </li>

              <li className="nav-item list-inline-item align-middle">
                <NavLink
                  className="w-100 p-3"
                  to="/home"
                  exact
                  activeClassName="selected"
                >
                  <FormattedMessage id="Home" />
                </NavLink>
              </li>

              <li className="nav-item list-inline-item align-middle">
                <NavLink
                  className="w-100 p-3"
                  to="/home/families"
                  exact
                  activeClassName="selected"
                >
                  <FormattedMessage id="Mercurial" />
                </NavLink>
              </li>

              <li className="nav-item list-inline-item align-middle text-nowrap">
                <NavLink
                  className="w-100 p-3"
                  to="/home/orders"
                  exact
                  activeClassName="selected"
                >
                  <FormattedMessage id="Orders" />
                  {newOrdersNbr > 0 && (
                    <span className="badge badge-danger">{newOrdersNbr}</span>
                  )}
                </NavLink>
              </li>

              <li className="nav-item list-inline-item align-middle">
                <NavLink
                  className="w-100 p-3"
                  to="/home/fleet"
                  exact
                  activeClassName="selected"
                >
                  <FormattedMessage id="Fleet" />
                </NavLink>
              </li>

              <li className="nav-item list-inline-item align-middle text-nowrap">
                <NavLink
                  className="w-100 p-3"
                  to="/home/requests"
                  exact
                  activeClassName="selected"
                >
                  <FormattedMessage id="Interventions" />
                  {this.countNewRequests(this.props.requests) > 0 && (
                    <span className="badge badge-danger">
                      {this.countNewRequests(this.props.requests)}
                    </span>
                  )}
                </NavLink>
              </li>

              <li className="nav-item list-inline-item align-middle text-nowrap">
                <NavLink
                  className="w-100 p-3"
                  to="/home/estimates"
                  exact
                  activeClassName="selected"
                >
                  <FormattedMessage id="Estimates" />
                </NavLink>
              </li>

              <li className="nav-item list-inline-item align-middle text-nowrap">
                <NavLink
                  className="w-100 p-3"
                  to="/home/budget"
                  exact
                  activeClassName="selected"
                >
                  <span className="relative">
                    <FormattedMessage id="Budget" />
                  </span>
                </NavLink>
              </li>

              <li className="nav-item list-inline-item align-middle">
                <div className="cart">
                  <div className="row d-flex justify-content-center mb-2">
                    <div className="col-3 text-left nav-item text-nowrap">
                      <span className="relative">
                        <Icon icon="cart-shopping" className="ml-1" />
                        {CartProductsNbr > 0 && (
                          <span className="badge badge-danger">
                            {CartProductsNbr}
                          </span>
                        )}
                      </span>
                    </div>
                    <div className="col-9 text-right text-nowrap">
                      <FormattedMessage id="Total" />
                      <b> {totalCart}€</b>
                    </div>
                  </div>
                  <div>
                    <NavLink
                      to="/home/cart"
                      disabled={disabled}
                      className="cart-item"
                    >
                      <button className="btn btn-outline-info">
                        <FormattedMessage id="Go.To.Cart" />
                      </button>
                    </NavLink>
                  </div>
                </div>
              </li>

              <MenuLogo generalSettings={this.props.generalSettings} />

              {/*
                            
                        <div className="ml-md-auto">
                            <li className="nav-item list-inline-item align-middle mx-auto p-2 col-12">
                              {(this.props.generalSettings.logo) && <img src={APIUrl.getLogo + this.props.generalSettings._id + "/" + Math.random() + "?token=" + APIUrl.jwtToken} className="logo-img" width="70" height="70" alt="logo"/>}
                            </li>
                        </div>
                            */}

              <li className="menuProfile nav-item list-inline-item align-middle">
                <MenuProfile />
              </li>
            </ul>
          </div>
        </nav>
        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.i18n.lang,
    cart: state.cart,
    orders: state.orders,
    requests: state.requests,
    products: state.products,
    ordersSettingsAdmin: state.ordersSettingsAdmin,
    ordersSettings: state.ordersSettings,
    generalSettings: state.generalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPassOrder: (data, successCallback) =>
      dispatch(passOrder(data, successCallback)),
    onGetManyProducts: (ids) => dispatch(getManyProducts(ids)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
