import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import CustomLabel from "../sub/CustomLabel";
import { Button, Modal } from "react-bootstrap";

class EstablishmentBudgetModal extends React.Component {
  constructor(props) {
    super(props);

    this.START_YEAR = new Date().getFullYear() - 1;
    this.END_YEAR = new Date().getFullYear() + 5;
    this.YEARS = this.getYears(this.START_YEAR, this.END_YEAR);

    this.state = {
      year: this.START_YEAR,
      budget: 0,

      budgetError: null,
    };
  }

  onChange(field, value) {
    if (
      field === "budget" &&
      !Util.emptyString(value) &&
      parseInt(value) >= 0
    ) {
      this.setState({ budgetError: null });
    }

    this.setState({ [field]: value });
  }

  getYears(startYear, endYear) {
    var years = [];

    for (; startYear <= endYear; startYear++) {
      years.push(startYear);
    }

    return years;
  }

  onComplete() {
    // Value is empty. Don't go further
    if (Util.emptyString(this.state.budget)) {
      this.setState({
        budgetError: <FormattedMessage id="Must.Provide.Value" />,
      });
      return;
    }
    if (parseInt(this.state.budget) < 0) {
      this.setState({
        budgetError: <FormattedMessage id="Must.Provide.Positive.Value" />,
      });
      return;
    }

    // If there is already a budget object
    if (this.props.budgets && this.props.budgets.length !== 0) {
      var alreadyIn = false;
      for (let budget of this.props.budgets) {
        if (parseInt(budget.year) === parseInt(this.state.year)) {
          budget.budget = this.state.budget;
          alreadyIn = true;
          break;
        }
      }

      if (!alreadyIn)
        this.props.budgets.push({
          year: this.state.year,
          budget: this.state.budget,
        });

      this.props.onComplete(this.props.budgets);
    }
    // Else, add new budget
    else {
      this.props.onComplete([
        { year: this.state.year, budget: this.state.budget },
      ]);
    }
  }

  hasYear(year, budgets) {
    if (Util.emptyArray(budgets)) return false;

    for (let budget of budgets) {
      if (parseInt(year) === parseInt(budget.year)) return true;
    }

    return false;
  }

  getBudget(year) {
    for (let budget of this.props.budgets) {
      if (parseInt(year) === parseInt(budget.year)) return budget.budget;
    }
  }

  getDefaultYear() {
    for (let year of this.YEARS) {
      if (!this.hasYear(year, this.props.budgets)) return year;
    }
  }

  componentWillMount() {
    let budget = 0;
    let year = this.getDefaultYear();

    // Modal has been opened to modify a budget: display values
    if (this.props.year) {
      budget = this.getBudget(this.props.year);
      year = this.props.year;
    }

    this.setState({ year: year, budget: budget });
  }

  render() {
    const { budgets, year, close, intl } = this.props;

    let yearsOptionsNode = this.YEARS.map((year) => {
      let disabled = this.hasYear(year, budgets);
      return (
        <option key={year} value={year} disabled={disabled}>
          {year}
        </option>
      );
    });

    let title = (
      <FormattedMessage
        id={year ? "Modify.Annual.Budget" : "Add.Annual.Budget"}
      />
    );

    return (
      <Modal show={true} onHide={() => close()} backdrop={"static"}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group row">
            <CustomLabel
              label={intl.formatMessage({ id: "Year" })}
              htmlFor="year"
              labelClassName="col-5"
            />
            <div className="col-7">
              <select
                id="year"
                className="form-control"
                defaultValue={this.state.year}
                disabled={year}
                onChange={(e) => this.onChange("year", e.target.value)}
              >
                {yearsOptionsNode}
              </select>
            </div>
          </div>

          <div className="form-group row">
            <CustomLabel
              label={intl.formatMessage({ id: "Budget" })}
              htmlFor="budget"
              labelClassName="col-5"
            />
            <div className="col-7">
              <input
                id="budget"
                type="number"
                className="form-control"
                value={this.state.budget}
                onChange={(e) => this.onChange("budget", e.target.value)}
                onBlur={() => this.onComplete()}
              />
              <div className="text-danger">
                <small>{this.state.budgetError}</small>
              </div>
            </div>
          </div>
        </Modal.Body>

        {!year && (
          <Modal.Footer>
            <Button variant="secondary" onClick={() => close()}>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button variant="info" onClick={() => this.onComplete()}>
              <FormattedMessage id="Create" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

export default injectIntl(EstablishmentBudgetModal);
