import React from "react";
import DateUtil from "../../../../util/DateUtil";
import MercurialStatus from "../../../../enums/MercurialStatus";
import APIUrl from "../../../../APIUrl";
import ActionMenu from "../../../sub/ActionMenu";
import { FormattedMessage } from "react-intl";

class PromotionalOperationsRow extends React.Component {
  render() {
    const { promoOperation, mercurial } = this.props;

    let isOutDated = true;
    let isInactive = true;

    if (mercurial) {
      isOutDated =
        new Date(mercurial.end_date).setHours(0, 0, 0, 0) <
        new Date(new Date().setHours(0, 0, 0, 0));
      isInactive = mercurial.status === MercurialStatus.INACTIVE;
    }

    let menuItems = [];

    menuItems.push(
      {
        icon: "pen-to-square",
        action: () => {
          if (!isInactive && !isOutDated)
            this.props.editPromotionalOperationModal(promoOperation);
        },
        text: <FormattedMessage id="Modify" />,
        disabled: isInactive || isOutDated,
      },
      {
        icon: "trash",
        action: () =>
          this.props.deletePromotionalOperationModal(promoOperation._id),
        text: <FormattedMessage id="Delete" />,
      },
    );

    const menuAction = <ActionMenu items={menuItems} />;

    return (
      <React.Fragment>
        <tr
          key={this.props.keyId}
          className={
            isOutDated || isInactive
              ? "font-italic text-muted d-flex"
              : "d-flex"
          }
        >
          <td className="col">{promoOperation.operationName}</td>
          <td className="col col-2">
            {DateUtil.toDate(promoOperation.startDate)}
          </td>
          <td className="col col-2">
            {DateUtil.toDate(promoOperation.endDate)}
          </td>
          <td className="col col-3">
            <a
              role="button"
              href={
                APIUrl.getPromoOperationFile +
                promoOperation._id +
                "/" +
                promoOperation.fileName +
                "?token=" +
                APIUrl.jwtToken
              }
              rel="noopener noreferrer"
              download={promoOperation.fileName}
              target="_blank"
            >
              {promoOperation.fileName}
            </a>
          </td>
          <td className="col col-1 text-center tdaction">{menuAction}</td>
        </tr>
      </React.Fragment>
    );
  }
}

export default PromotionalOperationsRow;
