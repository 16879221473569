import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import CustomLabel from "../../../../sub/CustomLabel";
import AutoSuggestInput from "../../../../sub/AutoSuggestInput";
import Util from "../../../../../util/Util";
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";

function CreateCheckpointModal({
  materialTypeId,
  checkpoint,
  checkpoints,
  addCheckpoint,
  onUpdateCheckpoint,
  close,
  intl,
}) {
  const [checkpointName, setCheckpointName] = useState(
    checkpoint ? checkpoint.name : "",
  );
  const [checkpointNameError, setCheckpointNameError] = useState("");

  const [isGlobalEdit, setIsGlobalEdit] = useState(false);

  const add = () => {
    if (Util.emptyString(checkpointName)) return;

    addCheckpoint(checkpointName.trim());
  };

  const update = () => {
    if (!materialTypeId || !checkpoint || Util.emptyString(checkpointName))
      return;

    const data = {
      materialTypeId: materialTypeId,
      checkpointId: checkpoint._id,
      updatedField: "name",
      updatedValue: checkpointName,
      isGlobal: isGlobalEdit,
    };
    onUpdateCheckpoint(data);
    close();
  };

  const onChange = (value) => {
    setCheckpointName(value);
    if (checkpoint) {
      setCheckpointNameError(
        getCheckpointsNames(checkpoints).includes(value) ? (
          <FormattedMessage id="Checkpoint.Already.Exists" />
        ) : (
          ""
        ),
      );
    }
  };

  const getCheckpointsNames = (checkpoints) => {
    if (!checkpoints) return [];

    const names = [];
    for (const c of checkpoints) names.push(c.name);
    return names;
  };

  return (
    <Modal show={true} onHide={() => close()} backdrop={"static"}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage
            id={checkpoint ? "Modify.Checkpoint" : "Add.Checkpoint"}
          />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="form-group row">
          <div id="checkpoint" className="col-sm-4">
            <CustomLabel
              label={intl.formatMessage({ id: "Description" })}
              labelClassName="col-12"
              htmlFor="checkpoint"
              required={true}
            />
          </div>
          <div id="checkpoint" className="col-sm-8">
            <AutoSuggestInput
              onChange={(value) => onChange(value)}
              value={checkpointName}
              onSuggestionSelected={() => {}}
              suggestions={getCheckpointsNames(checkpoints)}
            />
            <small className="text-danger">{checkpointNameError}</small>
          </div>
        </div>

        {checkpoint && (
          <div className="form-group row">
            <div id="is-global-edit" className="col-sm-10">
              <CustomLabel
                label={intl.formatMessage({ id: "Checkpoint.Edit.Global" })}
                labelClassName="col-12"
                htmlFor="is-global-edit"
              />
            </div>
            <div id="is-global-edit" className="col-sm-2">
              <input
                type="checkbox"
                defaultChecked={isGlobalEdit}
                onClick={() => setIsGlobalEdit(!isGlobalEdit)}
              />
            </div>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => close()}>
          <FormattedMessage id="Cancel" />
        </Button>
        <Button
          variant="info"
          onClick={() => {
            checkpoint ? update() : add();
          }}
          disabled={
            !Util.emptyString(checkpointNameError) ||
            Util.emptyString(checkpointName) ||
            (checkpoint && checkpointName === checkpoint.name)
          }
        >
          <FormattedMessage id={checkpoint ? "Modify" : "Add"} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    checkpoints: state.checkpoints,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CreateCheckpointModal));
