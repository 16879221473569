import React from "react";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import FileUtil from "../../util/FileUtil";
import { FormattedMessage } from "react-intl";
import Icon from "./Icon.js";

class FileDropZone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      extensionError: null,
    };
  }

  onDrop(acceptedFiles, rejectedFiles) {
    this.setState({ extensionError: null });

    for (let file of acceptedFiles) {
      if (!FileUtil.extensionIsOk(file.name, this.props.acceptedExtensions)) {
        this.setState({
          extensionError: (
            <FormattedMessage
              id="Unsupported.File.Type.Ext"
              values={{ extension: FileUtil.extension(file.name) }}
            />
          ),
        });
        return;
      }
    }

    this.setState({ files: acceptedFiles });

    var clearFiles = () => {
      this.setState({ files: [] });
    };

    if (this.props.multiple) this.props.onDropFiles(acceptedFiles, clearFiles);
    else this.props.onDropFile(acceptedFiles[0], clearFiles);
  }

  render() {
    let baseStyle = {
      width: "100%",
      borderWidth: 2,
      borderColor: "#666",
      borderStyle: "dashed",
      borderRadius: 5,
      padding: 10,
    };
    let disabledStyle = { borderColor: "grey", color: "grey" };
    let activeStyle = {
      borderStyle: "solid",
      borderColor: "#6c6",
      backgroundColor: "#eee",
    };
    let rejectStyle = {
      borderColor: "#c66",
      color: "#c66",
      backgroundColor: "#eee",
    };
    let icon = "";

    if (this.props.appearance) {
      switch (this.props.appearance) {
        case "thumbnail":
          baseStyle = {
            height: "210px",
            borderWidth: 1,
            borderColor: "#dee2e6",
            borderStyle: "solid",
            borderRadius: 5,
            padding: "0.25rem",
            paddingTop: "4em",
          };
          //disabledStyle = "";
          //activeStyle = "";
          //rejectStyle = "";
          icon = "fa-4x disabled";
          break;
        default:
          break;
      }
    }

    return (
      <Dropzone
        disabled={this.props.disabled}
        onDrop={(acceptedFiles, rejectedFiles) =>
          this.onDrop(acceptedFiles, rejectedFiles)
        }
        multiple={this.props.multiple}
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject,
          acceptedFiles,
          rejectedFiles,
        }) => {
          let styles = { ...baseStyle };
          if (this.props.disabled) styles = { ...styles, ...disabledStyle };
          else {
            styles =
              isDragActive && isDragAccept
                ? { ...styles, ...activeStyle }
                : styles;
            styles =
              (isDragActive && isDragReject) || this.state.extensionError
                ? { ...styles, ...rejectStyle }
                : styles;
          }

          let filesNames = "";
          if (this.state.files.length <= 10) {
            for (let file of this.state.files)
              filesNames +=
                " " + (file.name > 30)
                  ? FileUtil.withoutExtension(file.name).slice(0, 20) +
                    "." +
                    FileUtil.extension(file.name)
                  : file.name;
            filesNames = <span>{filesNames}</span>;
          } else {
            var i = 0;
            for (; i < 10; i++) filesNames += " " + this.state.files[i].name;
            filesNames = (
              <span>
                {filesNames}...{" "}
                <small>
                  <em>
                    (
                    <FormattedMessage
                      id="More.items"
                      values={{ nbr: this.state.files.length - i }}
                    />
                    )
                  </em>
                </small>
              </span>
            );
          }

          let msg = null;
          if (this.state.extensionError) msg = this.state.extensionError;
          else if (isDragReject)
            msg = <FormattedMessage id="Unsupported.File.Type" />;
          else if (!this.props.multiple && this.state.files.length === 0)
            msg = <FormattedMessage id="Drag.Drop" />;
          else if (this.props.multiple && this.state.files.length === 0)
            msg = <FormattedMessage id="Drag.Drop.Multiple" />;
          else if (this.state.files.length > 0) msg = <div>{filesNames}</div>;

          return (
            <div
              {...getRootProps()}
              style={styles}
              className="text-center mx-auto cursor-pointer"
            >
              <input {...getInputProps()} />
              <div>
                <Icon
                  icon="cloud-arrow-up"
                  size="6x"
                  className={icon + " text-center"}
                  disabled={!this.props.multiple && this.state.files.length > 0}
                />
                <div className="small">{msg}</div>
              </div>
            </div>
          );
        }}
      </Dropzone>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileDropZone);
