import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import DateUtil from "../../util/DateUtil";
import APIUrl from "../../APIUrl";
import SignatureInput from "../sub/SignatureInput";
import { Button, Container, Row, Col, Card } from "react-bootstrap";
import Icon from "../sub/Icon";
import Select from "react-select";
import CustomLabel from "./CustomLabel";
import PopoverHelper from "./PopoverHelper";

class SignaturePage extends React.Component {
  constructor(props) {
    super(props);
    this.techSignatureRef = React.createRef();
    this.repSignatureRef = React.createRef();
    this.state = {
      checked: this.props.selfSigned,
      representative_name: "",
    };
  }

  onChange(value) {
    if (value === true) {
      // Clear previous signature if any
      this.props.rep_signature && this.repSignatureRef.current.clear();
      this.props.setState({
        rep_signature_checkbox: { isEstablishmentRep: true },
      });
    } else {
      this.props.setState({ rep_signature_checkbox: null });
    }
    this.setState({
      checked: value,
    });
  }

  getSelect(selectedOptionField, field, options, disabled = false) {
    return (
      <Select
        isDisabled={disabled}
        placeholder={<FormattedMessage id="Select" />}
        noOptionsMessage={() => <FormattedMessage id={"No.Result"} />}
        isSearchable={false}
        onChange={(option) => {
          this.props.setState({
            [selectedOptionField]: option,
            [field]: option.value,
          });
          this.setState({ representative_name: option.label });
        }}
        value={this.props[selectedOptionField]}
        options={options}
      />
    );
  }

  render() {
    const { establishment } = this.props;
    let imgError = false;

    return (
      <Container>
        {this.props.title && (
          <Row>
            <h4 className="pb-3 pt-2 pr-5 ml-auto mr-0 text-info">
              {this.props.title}
            </h4>
          </Row>
        )}

        {this.props.generalSettings && (
          <Row>
            <Col md={7} className="text-center">
              {this.props.generalSettings.logo && (
                <img
                  src={
                    APIUrl.getGeneralSettingsLogo +
                    this.props.generalSettings._id +
                    "/" +
                    Math.random() +
                    "?token=" +
                    APIUrl.jwtToken
                  }
                  style={{ maxWidth: "180px", maxHeight: "180px" }}
                  alt="logo"
                  onError={(e) => {
                    if (imgError) return;
                    e.target.onerror = null;
                    imgError = true;
                    e.target.src = "/images/placeholder.png";
                  }}
                />
              )}
            </Col>

            <Col md={5}>
              <div className="col-12">
                <FormattedMessage id="Establishment" />:{" "}
                <b>{establishment.name}</b>
              </div>
              {establishment.address && (
                <div className="col-12">{establishment.address}</div>
              )}
              {(establishment.postal_code || establishment.city) && (
                <div className="col-12">
                  {establishment.postal_code} {establishment.city}
                </div>
              )}

              <div className="col-12 mt-3">
                <FormattedMessage id="Date" />: {DateUtil.toDate(Date.now())}
              </div>
            </Col>
          </Row>
        )}

        <Row className="pt-4">
          <Col sm={6}>
            <Row className="mb-3 d-flex align-items-center">
              <Col>
                <CustomLabel
                  label={this.props.intl.formatMessage({
                    id: "Technician",
                  })}
                  htmlFor="tech"
                  labelClassName="col-sm-12 p-0 m-0"
                />
              </Col>
              <Col>
                {this.getSelect("selectedTechOption", null, null, true)}
              </Col>
            </Row>
            <Card border="dark">
              <Card.Header>
                <FormattedMessage id="Technician.Signature" />
              </Card.Header>
              <Card.Body className="p-1" style={{ minHeight: "16em" }}>
                <div
                  id="tech_signature-container"
                  className={"max-height-200 min-height-200 text-center mb-2"}
                >
                  <SignatureInput
                    containerId="tech_signature-container"
                    onEnd={(value) =>
                      this.props.setState({ tech_signature: value })
                    }
                    ref={this.techSignatureRef}
                  />
                </div>
                <Button
                  variant="secondary"
                  className="ml-3"
                  size="sm"
                  onClick={() => this.techSignatureRef.current.clear()}
                  disabled={!this.props.tech_signature}
                >
                  <Icon icon="rotate-left" className="mr-2" />
                  <FormattedMessage id="Delete.Signature" />
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6}>
            <Row className="mb-3 d-flex align-items-center">
              <Col>
                <CustomLabel
                  label={this.props.intl.formatMessage({
                    id: "Estab.Rep",
                  })}
                  htmlFor="rep"
                  labelClassName="col-sm-12 p-0 m-0"
                  required
                />
              </Col>
              <Col>
                {this.getSelect(
                  "selectedRepOption",
                  "rep_id",
                  this.props.repSelectOptions,
                )}
              </Col>
            </Row>
            <Card border="dark">
              <Card.Header>
                <Row>
                  <Col md={7}></Col>
                  <Col className="text-right pr-0">
                    <FormattedMessage id="Signature.Replace" />
                    {this.state.checked ? (
                      <Icon
                        icon="square-check"
                        size="lg"
                        className="text-dark"
                        onClick={(e) =>
                          !this.props.selfSigned && this.onChange(false)
                        }
                        disabled={this.props.selfSigned}
                      />
                    ) : (
                      <Icon
                        icon="square"
                        variant="far"
                        size="lg"
                        className="text-dark"
                        onClick={(e) =>
                          !this.props.selfSigned && this.onChange(true)
                        }
                        disabled={this.props.selfSigned}
                      />
                    )}
                    <PopoverHelper
                      placement="left"
                      content={this.props.intl.formatMessage({
                        id: "Popover.Estab.Rep.Signature",
                      })}
                    />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="p-1" style={{ minHeight: "16em" }}>
                {!this.state.checked && (
                  <div
                    id="rep_signature-container"
                    className={"max-height-200 min-height-200 text-center mb-2"}
                  >
                    <SignatureInput
                      containerId="rep_signature-container"
                      onEnd={(value) => {
                        this.props.setState({ rep_signature: value });
                      }}
                      ref={this.repSignatureRef}
                    />
                  </div>
                )}
                {(this.state.checked || this.props.selfSigned) && (
                  <div className={"text-center text-dark pt-4"}>
                    <div className={"h5 mt-5"}>
                      <b>
                        {this.props.rep_signature_checkbox.isEstablishmentRep &&
                          establishment.name}
                      </b>
                    </div>
                    <div>
                      {this.props.intl.formatMessage({
                        id: "Signature.Replace",
                      })}{" "}
                      {this.props.intl.formatMessage({ id: "Establishment" })}
                    </div>
                    <div className={"h5"}>
                      {this.props.selectedRepOption?.label}
                    </div>
                  </div>
                )}
                {!this.state.checked && (
                  <div className="text-right">
                    <Button
                      variant="secondary"
                      className="mr-3"
                      size="sm"
                      onClick={() => this.repSignatureRef.current.clear()}
                      disabled={!this.props.rep_signature}
                    >
                      <Icon icon="rotate-left" className="mr-2" />
                      <FormattedMessage id="Delete.Signature" />
                    </Button>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col className="text-center pt-3">
            <Button
              variant="warning"
              disabled={
                !this.props.tech_signature ||
                (!this.props.rep_signature &&
                  !this.props.rep_signature_checkbox) ||
                !this.props.selectedRepOption ||
                !this.props.selectedTechOption
              }
              onClick={() => this.props.callBack()}
            >
              <Icon icon="check-circle" className="mr-2" />
              <FormattedMessage id="Finish" />
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default injectIntl(SignaturePage);
