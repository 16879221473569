import React from "react";
import { connect } from "react-redux";
import StringUtil from "../../util/StringUtil";
import MercurialsUtil from "../../util/mercurialsUtil.js";
import ArrayUtil from "../../util/ArrayUtil";
import ObjectUtil from "../../util/ObjectUtil";
import Maths from "../../util/Maths";
import Util from "../../util/Util";
import ExcelUtil from "../../util/ExcelUtil";
import FileUtil from "../../util/FileUtil";
import CustomLabel from "../sub/CustomLabel";
import { FormattedMessage, injectIntl } from "react-intl";
import Icon from "../sub/Icon";
import { Button, Modal } from "react-bootstrap";
import ModalManager from "../sub/modals/ModalManager";

class MercurialColumnsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      disabled: false,
      autoMatching: false,
      simFamilies: [], // Array of families found by string similarity
      missingRequiredColumns: [],
      errorsFound: [],
      formVisibility: true,
      progressBarVisibility: false,
      maxErrorToDisplay: 50, // Define the number of errors (if any) to be displayed in a parsed file
      allowSameSelectValue: true, // If true, value of the same column can be associated to multiple selects
    };
  }

  // Set mercurial mapping fields for current PSDM
  newMercurialsUtil = new MercurialsUtil(this.props.company);
  MercurialsMapping = this.newMercurialsUtil.getMercurialMapping();

  close() {
    this.props.closeModal();
  }

  formatValue(value) {
    let formattedValue = "" + value;
    if (formattedValue.length > 100)
      formattedValue = formattedValue.substring(0, 100) + "...";

    return formattedValue;
  }

  /**
   * Not used anymore.
   * Replaced by buildSelects() below.
   * Code keeped because of the usage of "closest" example (similarity checks between strings)
   *
   * @param {*} key
   */
  /*
    buildSelect(key) {

        let firstRowKeys = Object.keys(this.props.fileData[0]);

        // Help user and pre-select the closest column (select default value)
        let closest = StringUtil.closest(key, firstRowKeys);

        var optionsNode = firstRowKeys.map(key => {
            let value = this.props.fileData[0][key];

            let optionElement;

            if (!this.state.autoMatching) {
                optionElement = <optgroup label={key} key={key + "-" + value}><option key={key + "-" + value} value={key}>{this.formatValue(value)}</option></optgroup>
            }
            else {
                optionElement = <option key={key + "-" + value} value={key}>{this.formatValue(value)}</option>;
            }

            return optionElement;
        });

        return (
            <select key={Math.random()} className="form-control column-select" defaultValue={(this.state.autoMatching && closest) && closest} onChange={(e) => this.manageSelectChange(e)}>
                <option value="">{this.props.intl.formatMessage({ id: "Select" })}...</option>
                {optionsNode}
            </select>
        );
    }
    */

  // Detect similarities to avoid duplication
  checkFamilySimilarity() {
    let families = [];

    // Add only distinct family names to families array to improve performances when detecting similarities below
    for (let key of Object.keys(this.props.fileData)) {
      let currentFamilyKey = this.props.fileData[key]["FAMILLE"];

      if (
        Util.typeOf(currentFamilyKey) !== "Undefined" &&
        families.indexOf(currentFamilyKey) === -1 &&
        currentFamilyKey.toString().trim() !== ""
      ) {
        families.push(currentFamilyKey.toString());
      }
    }

    var simFamilies = [];
    for (let f0 of families) {
      inner: for (let f1 of families) {
        if (f0 === f1) continue;

        for (let s of simFamilies) {
          if ((s[0] === f1 && s[1] === f0) || (s[0] === f0 && s[1] === f1)) {
            continue inner;
          }
        }

        // console.log(f0 + " / " + f1 + " : " + StringUtil.similarity(f0, f1));
        if (StringUtil.similarity(f0, f1) > 0.8) {
          simFamilies.push([
            f0.replace(/\s/g, "[*]"),
            f1.replace(/\s/g, "[*]"),
          ]);
        }
      }
    }

    this.setState({ simFamilies: simFamilies });
  }

  // Build selects to match columns in the file
  buildSelects() {
    let selects = [];
    let firstRowKeys = Object.keys(this.props.fileData[0]);
    let hintIcon;
    let selectClass;

    for (let mappingKey of Object.keys(this.MercurialsMapping)) {
      // Help user and pre-select the closest column (select default value)
      let matchColumn =
        this.state.missingRequiredColumns.indexOf(
          this.MercurialsMapping[mappingKey],
        ) !== -1
          ? false
          : this.MercurialsMapping[mappingKey];

      if (!matchColumn) {
        hintIcon = (
          <Icon
            icon="circle-exclamation"
            hover={<FormattedMessage id="Mercurials.Auto.Detect.No.Match.2" />}
          />
        );

        selectClass = "text-danger";
      } else {
        hintIcon = (
          <Icon
            icon="circle-check"
            hover={
              <>
                <FormattedMessage id="Mercurial.Column.Reference.Name" /> :{" "}
                {this.MercurialsMapping[mappingKey]}
              </>
            }
          />
        );

        selectClass = "text-success";
      }

      var optionsNode = firstRowKeys.map((key) => {
        let value = this.props.fileData[0][key];

        let optionElement;

        if (
          !this.state.autoMatching ||
          (this.state.autoMatching && !matchColumn)
        ) {
          // When autoMatching did not found matching column or autoMatching is disabled, we build a select with the whole list of columns in options
          optionElement = (
            <option key={key + "-" + value} value={key}>
              [{key}] : {this.formatValue(value)}
            </option>
          );
        } else {
          // When autoMatching is enabled and worked well (column found) we don't need to display the whole list of columns.
          // Only displaying the matched one in select options
          if (key === this.MercurialsMapping[mappingKey]) {
            optionElement = (
              <option key={key + "-" + value} value={key}>
                {this.formatValue(value)}
              </option>
            );
          }
        }

        return optionElement;
      });

      selects.push(
        <div className="form-row mb-2" key={mappingKey}>
          <CustomLabel
            label={this.MercurialsMapping[mappingKey]}
            htmlFor={mappingKey}
            labelClassName="col-sm-4"
          />
          <div id={mappingKey} className="col-sm-7">
            <select
              key={Math.random()}
              className="form-control column-select"
              defaultValue={
                this.state.autoMatching && matchColumn && matchColumn
              }
              onChange={(e) => this.manageSelectChange(e, mappingKey + "_hint")}
            >
              <option value="">
                {this.props.intl.formatMessage({ id: "Select" })}...
              </option>
              {optionsNode}
            </select>
          </div>
          <div
            id={mappingKey + "_hint"}
            className={"col-sm-1 d-flex align-items-center " + selectClass}
          >
            {this.state.autoMatching && hintIcon}
          </div>
        </div>,
      );
    }

    return selects;
  }

  getSelColumn(key) {
    let famDiv = document.getElementById(key);
    let select = famDiv.firstChild;
    return select.options[select.selectedIndex].value;
  }

  onComplete() {
    // Prevent post to backend if button to next step is disabled
    // (means that some column matching already needs to be done)
    if (this.state.disabled || !this.selectsAllHaveValues()) return false;

    this.setState({
      disabled: true,
    });

    var columns = {};

    // Store association between required column and matching column in the file (labels can be different if we didnt used automatching or partial automatching)
    for (let key of Object.keys(this.MercurialsMapping)) {
      columns[key] = this.getSelColumn(key);
    }

    /*
        PERFORM INTEGRITY CHECKS UPON DATA BEFORE SENDING MERCURIAL TO THE BACKEND
        */
    if (this.checkDataIntegrity(columns)) {
      this.setState({
        formVisibility: false,
        progressBarVisibility: true,
      });

      this.props.onComplete(columns);
    }
  }

  /**
   * Performs checks upon data.
   * We try to avoid sending data that will be rejected by the backend (because of Mongo/Mongoose field types for example)
   *
   * @param {*} columnsReferenceList
   */
  checkDataIntegrity(columnsReferenceList) {
    let currentError;
    let minTva = 0;
    let maxTva = 100;

    // Add an error to the stack
    const addError = (error) => {
      if (Util.typeOf(error) === "Object") {
        let arr = this.state.errorsFound;
        arr.push(error);
        this.setState({ errorsFound: arr });
      }
    };

    // Loop through file rows
    for (let row of this.props.fileData) {
      if (this.state.errorsFound.length === this.state.maxErrorToDisplay) {
        break;
      }

      // Get current row keys
      let currentRowKeys = Object.keys(row);

      /**
       * Compare with required keys to find if any field is missing
       */
      let missingFieldsForCurrentRow = ArrayUtil.difference(
        Object.values(columnsReferenceList),
        currentRowKeys,
      );

      // If some empty or missing fields are found in the file, we add them to the stack trace
      if (missingFieldsForCurrentRow.length > 0) {
        for (let missingField of missingFieldsForCurrentRow) {
          currentError = {
            numRow: row.__rowNum__ + 1,
            field: missingField,
            targetField:
              this.MercurialsMapping[
                ObjectUtil.getKeyByValue(columnsReferenceList, missingField)
              ],
            value: "",
            hint: <FormattedMessage id="Field.Cant.Be.Empty" />,
          };

          addError(currentError);
        }
      }

      /**
       * CHECK SPECIAL FIELDS (where we know that value must be an integer or a float for example)
       */
      for (let key of currentRowKeys) {
        if (this.state.errorsFound.length === this.state.maxErrorToDisplay) {
          break;
        }

        let MercurialsMappingReferenceKey = ObjectUtil.getKeyByValue(
          columnsReferenceList,
          key,
        );
        // Convert field to string to evaluate it
        // let fieldValue = row[key].toString();

        switch (true) {
          // Perfom tests on numeric fields
          case MercurialsMappingReferenceKey === "tva":
            if (!Maths.isInt(row[key]) && !Maths.isFloat(row[key])) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField:
                  this.MercurialsMapping[MercurialsMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Int.Or.Float"
                    values={{ value: row[key] }}
                  />
                ),
              };
              addError(currentError);
            } else {
              switch (true) {
                case row[key] < minTva || row[key] > maxTva:
                  currentError = {
                    numRow: row.__rowNum__ + 1,
                    field: key,
                    targetField:
                      this.MercurialsMapping[MercurialsMappingReferenceKey],
                    value: row[key],
                    hint: (
                      <FormattedMessage
                        id="Value.Must.Be.Between"
                        values={{ value: row[key], min: minTva, max: maxTva }}
                      />
                    ),
                  };
                  addError(currentError);
                  break;
                case row[key] >= 1:
                  this.props.fileData[row.__rowNum__ - 1][key] = row[key] / 100;
                  break;
                default:
                  break;
              }
            }

            break;

          case MercurialsMappingReferenceKey === "prix_u_ht_emera":
            if (!Maths.isInt(row[key]) && !Maths.isFloat(row[key])) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField:
                  this.MercurialsMapping[MercurialsMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Int.Or.Float"
                    values={{ value: row[key] }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          case MercurialsMappingReferenceKey === "min_cde":
            if (!Maths.isInt(row[key])) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField:
                  this.MercurialsMapping[MercurialsMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Int"
                    values={{ value: row[key] }}
                  />
                ),
              };
              addError(currentError);
            } else if (row[key] < 1) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField:
                  this.MercurialsMapping[MercurialsMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Less.Than.1"
                    values={{ value: row[key] }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          default:
            // Convert field to string to evaluate it
            let fieldValue = row[key].toString();
            // Double check string fields that may contain only spaces (so they are not considered as empty)
            // We trim the value in order to catch'em as well eventually
            if (fieldValue.trim() === "") {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField:
                  this.MercurialsMapping[MercurialsMappingReferenceKey],
                value: "",
                hint: <FormattedMessage id="Field.Cant.Be.Empty" />,
              };

              addError(currentError);
            }
            break;
        }
      }
    }

    // If some errors have been detected on fields values while parsing file (and after column matching), we abort the import process
    // And we display a list of found errors
    if (this.state.errorsFound.length > 0) {
      return this.openDataIntegrityModal(
        this.state.errorsFound,
        columnsReferenceList,
      );
    }

    return true;
  }

  // When an error occurs, allow download of generated import file
  downloadGeneratedImportFile(columns) {
    let products = this.props.fileData;
    let newProducts = [];

    for (let p of products) {
      var newProduct = {};
      let colValue;

      for (let col of Object.keys(columns)) {
        colValue =
          Util.typeOf(p[columns[col]]) !== "Undefined" ? p[columns[col]] : "";
        newProduct[col] = colValue.toString().trim() !== "" ? colValue : "";
      }

      newProducts.push(newProduct);
    }

    // Convert data to Excel format
    let excelData = ExcelUtil.toExcel(newProducts, this.MercurialsMapping, [
      "_id",
      "__v",
      "mercurial_id",
    ]);

    // Sanitize the file name
    let fileName = FileUtil.toFileName("export");

    // Save the file
    ExcelUtil.save(excelData, fileName);
  }

  openDataIntegrityModal(dataProblems, columns) {
    var errorModalTitle = <FormattedMessage id="Error" />;
    var errorModalContent = (
      <div>
        <div className="alert alert-danger">
          <div>
            <FormattedMessage id="Mercurial.File.Missing.Data" />
          </div>
          {Object.values(dataProblems).length ===
            this.state.maxErrorToDisplay && (
            <div>
              <FormattedMessage
                id="Mercurial.File.Error.Count"
                values={{ count: Object.values(dataProblems).length }}
              />
            </div>
          )}
          <div className="text-center">
            <button
              className="btn btn-danger"
              onClick={(e) => {
                this.downloadGeneratedImportFile(columns);
              }}
            >
              <Icon icon="download" />{" "}
              <FormattedMessage id="Export.Mercurial" />
            </button>
          </div>
        </div>
        <table className="table table-striped tablee4coll">
          <thead>
            <tr className="d-flex">
              <th scope="col" className="col col-1">
                <FormattedMessage id="Line" />
              </th>
              <th scope="col" className="col col-3">
                <FormattedMessage id="Column.In.File" />
              </th>
              <th scope="col" className="col col-3">
                <FormattedMessage id="Target.Field" />
              </th>
              <th scope="col" className="col col-5">
                <FormattedMessage id="Hint" />
              </th>
            </tr>
          </thead>
          <tbody>
            {dataProblems.map((row, index) => {
              return (
                <tr key={index} className="d-flex">
                  <td className="col col-1">{row.numRow}</td>
                  <td className="col col-3">{row.field}</td>
                  <td className="col col-3">{row.targetField}</td>
                  <td className="col col-5">{row.hint}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );

    this.setState({
      modal: (
        <ModalManager
          showModal={true}
          title={errorModalTitle}
          content={errorModalContent}
          closeModal={(e) => this.close()}
          buttonLabel={<FormattedMessage id="Cancel" />}
          modalType="error"
        />
      ),
    });
  }

  manageSelectChange(e, selectHint) {
    let hintElement = document.getElementById(selectHint);

    if (e.target.value !== "") {
      hintElement.classList.remove("text-danger");
      hintElement.classList.add("text-success");
    } else {
      hintElement.classList.add("text-danger");
      hintElement.classList.remove("text-success");
    }

    this.updateSelectOptions();
  }

  componentDidMount() {
    this.updateSelectOptions();
  }

  updateSelectOptions() {
    // if allowSameSelectValue is true in state (default) we allow the user to choose the same select multiple times for different values (ex: client ref and manufacturer ref linked to the same col in file)
    if (!this.state.allowSameSelectValue) {
      let selects = document.getElementsByClassName("column-select");

      for (let s of selects) {
        for (let i = 0; i < s.length; i++) {
          s.options[i].disabled = false;
        }
      }

      for (let s1 of selects) {
        var value1 = s1.value;

        for (let s2 of selects) {
          if (s1 === s2) continue;

          for (let i = 0; i < s2.length; i++) {
            if (s2.options[i].value !== "" && s2.options[i].value === value1)
              s2.options[i].disabled = true;
          }
        }
      }
    }
    this.checkDisableButton();
  }

  updateAutoMatching() {
    if (!this.state.autoMatching) {
      // When automatching is enabled, we check if all the required columns are found in the provided file
      let firstRowKeys = Object.keys(this.props.fileData[0]);
      this.setState({
        missingRequiredColumns: ArrayUtil.difference(
          Object.values(this.MercurialsMapping),
          firstRowKeys,
        ),
      });

      // Also, enable family similarities detection only when automatching is set to true (still set to false when updateAutoMatching() is called)
      this.checkFamilySimilarity();
    } else {
      // We reset the columns to check if automatching is disabled
      this.setState({ missingRequiredColumns: [] });
      // We reset the families similarity detection too
      this.setState({ simFamilies: [] });
    }

    this.setState(
      { autoMatching: !this.state.autoMatching },
      this.updateSelectOptions,
    );
  }

  checkDisableButton() {
    let button = document.getElementById("submit-button");
    button.disabled = this.state.disabled || !this.selectsAllHaveValues();
  }

  selectsAllHaveValues() {
    let selects = document.getElementsByClassName("column-select");

    for (let s of selects) {
      if (!s.value || s.value === "") return false;
    }

    return true;
  }

  render() {
    // Prepare as many selects as required for the mapping
    let selects = this.buildSelects();

    // Split the selects on 2 displayed columns
    let selects1stHalf = [];
    let selects2ndHalf = [];
    for (let i = 0; i < selects.length; i++) {
      if (i <= selects.length / 2) selects1stHalf.push(selects[i]);
      else selects2ndHalf.push(selects[i]);
    }

    let goToNextStepButton = (
      <button
        id="submit-button"
        type="button"
        className="btn btn-info"
        onClick={() => this.onComplete()}
        disabled={this.state.disabled}
      >
        {this.props.mode === "update" ? (
          <FormattedMessage id="Update" />
        ) : (
          <FormattedMessage id="Step.3" />
        )}
      </button>
    );

    if (this.state.simFamilies.length > 0) {
      if (
        (this.state.autoMatching && !this.state.disabled) ||
        (!this.state.autoMatching && this.selectsAllHaveValues())
      ) {
        //if((!this.state.autoMatching && !this.selectsAllHaveValues()) || (this.state.autoMatching && !this.selectsAllHaveValues()))
        goToNextStepButton = (
          <button
            id="submit-button"
            type="button"
            className="btn btn-warning"
            onClick={() => this.onComplete()}
            disabled={this.state.disabled}
          >
            <FormattedMessage id="Step.3.warning" />
          </button>
        );
      }
    }

    return (
      <Modal
        show={true}
        onHide={() => this.close()}
        backdrop={"static"}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage
              id={
                this.props.mode === "update"
                  ? "Mercurials.Update"
                  : "Mercurials.Add.Some"
              }
            />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h4 className="w-100 text-center">
            <FormattedMessage id="Mercurials.Step2.Desc" />
          </h4>

          {this.state.progressBarVisibility && (
            <div className="text-center mb-5 ">
              <Icon
                icon="gear"
                className="fa-spin text-success mb-3"
                size="3x"
              />
              <div className="progress" style={{ height: "30px" }}>
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                  role="progressbar"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: "100%" }}
                >
                  <strong>
                    <FormattedMessage id="Import.Mercurial.Save.Data" />
                  </strong>
                </div>
              </div>
            </div>
          )}

          {this.state.formVisibility && (
            <div>
              <h5 className="mb-4 w-100 text-center font-weight-light">
                <FormattedMessage id="Mercurials.Step2.Read.1st.Line" />
              </h5>
              <div className="custom-switch custom-control switch-success mb-3 text-center">
                <input
                  onChange={(e) => this.updateAutoMatching()}
                  type="checkbox"
                  className="custom-control-input switch-bg-blue"
                  id="auto-matching"
                  checked={this.state.autoMatching}
                />
                <CustomLabel
                  label={this.props.intl.formatMessage({
                    id: "Mercurials.Auto.Detect",
                  })}
                  htmlFor="auto-matching"
                  labelClassName="custom-control-label"
                />
              </div>
              <div className="row">
                <div className="col-12 col-lg-6">{selects1stHalf}</div>

                <div className="col-12 col-lg-6">{selects2ndHalf}</div>

                <div className="col-12">
                  {this.state.simFamilies.length > 0 && (
                    <div className="alert alert-danger mt-4" role="alert">
                      <div className="row">
                        <div className="col-1 d-flex align-items-center">
                          <Icon icon="triangle-exclamation" size="3x" />
                        </div>
                        <div className="col-11">
                          <div className="mb-2">
                            <FormattedMessage id="Mercurials.Families.Similarities" />{" "}
                            :
                          </div>
                          <ul className="pb-0 mb-2">
                            {this.state.simFamilies.map((family, index) => (
                              <li key={index}>
                                <span className="badge badge-success">
                                  {family[0]}
                                </span>{" "}
                                /{" "}
                                <span className="badge badge-danger">
                                  {family[1]}
                                </span>
                              </li>
                            ))}
                          </ul>
                          <div className="text-justify">
                            <FormattedMessage id="Mercurials.Families.Excel.Fix" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.missingRequiredColumns.length > 0 && (
                    <div className="alert alert-danger mt-4" role="alert">
                      <div className="row">
                        <div className="col-1 d-flex align-items-center">
                          <Icon icon="triangle-exclamation" size="3x" />
                        </div>
                        <div className="col-11">
                          <div className="mb-2">
                            <FormattedMessage id="Mercurials.Auto.Detect.No.Match.1" />
                          </div>
                          <ul className="pb-0 mb-2">
                            {this.state.missingRequiredColumns.map(
                              (requiredColumn, index) => (
                                <li key={index}>
                                  <span className="badge badge-success">
                                    {requiredColumn}
                                  </span>
                                </li>
                              ),
                            )}
                          </ul>
                          <div className="text-justify">
                            <FormattedMessage id="Mercurials.Auto.Detect.No.Match.2" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.close()}>
            <FormattedMessage id="Cancel" />
          </Button>
          {goToNextStepButton}
        </Modal.Footer>
        {this.state.modal}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
  };
};

export default connect(mapStateToProps)(injectIntl(MercurialColumnsModal));
