import APIUrl from "../../APIUrl";
import axios from "axios";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const SET_NEWPRODUCTS = "SET_NEWPRODUCTS";

function getProductsAction(products) {
  return { type: GET_PRODUCTS, products: products };
}

function getProductsAddAction(product) {
  return { type: SET_NEWPRODUCTS, products: product };
}

export const getProducts = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getProducts)
      .then(function (response) {
        dispatch(getProductsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getManyProducts = function (idsToSend) {
  return function (dispatch) {
    return axios
      .post(APIUrl.getManyProducts, { ids: idsToSend })
      .then(function (response) {
        dispatch(getProductsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getListProducts = function (data) {
  return function (dispatch) {
    return axios
      .post(APIUrl.getListProducts, { data: data })
      .then(function (response) {
        dispatch(getProductsAddAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

// ==================================================================================
// ================================== ADMIN =========================================
// ==================================================================================

export const getProductsAdmin = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getProductsAdmin)
      .then(function (response) {
        dispatch(getProductsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

/**
 *
 * @param {*} mercurialId
 * @param {*} getAllProducts Boolean : If set to true, all products are downloaded. ilf false (default) only a subset.
 * @returns
 */
export const getProductsByMercurialIdAdmin = function (
  mercurialId,
  getAllProducts,
) {
  // Define if all products must be loaded (true) or not (false)
  const loadAllProducts =
    getAllProducts === undefined
      ? false
      : typeof (getAllProducts === "boolean")
        ? getAllProducts
        : false;

  return function (dispatch) {
    return axios
      .get(APIUrl.getProductsByMercurialIdAdmin + mercurialId)
      .then(function (response) {
        if (loadAllProducts) {
          dispatch(getProductsAction(response.data));
        } else {
          dispatch(getProductsAddAction(response.data));
        }
        return response.data;
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getProductsByMercurialIdInactive = function (mercurialId) {
  return function (dispatch) {
    return axios
      .get(APIUrl.getProductsByMercurialIdInactive + mercurialId)
      .then(function (response) {
        return response.data;
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getProductsByEstablishmentIdAdmin = function (establishmentId) {
  return function (dispatch) {
    return axios
      .get(APIUrl.getProductsByEstablishmentId + establishmentId)
      .then(function (response) {
        dispatch(getProductsAddAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getListProductsAdmin = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.getListProductsAdmin, { data: data })
      .then(function (response) {
        if (response.data.length !== 0) {
          dispatch(getProductsAddAction(response.data));
        }
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateProductAdmin = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateProductAdmin, data)
      .then(function (response) {
        dispatch(
          getProductsByMercurialIdAdmin(response.data.mercurial_id, true),
        );
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
