import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import MaterialTypeAdminRow from "./MaterialTypeAdminRow.js";
import MaterialsTypesModalAdmin from "./MaterialsTypesModalAdmin";
import ModalManager from "../../../../sub/modals/ModalManager";
function MaterialsTypesTable({ materialsTypes }) {
  const [modal, setModal] = useState(null);

  const closeModal = () => setModal(null);

  const openConfModal = (title, content, successCallback) => {
    setModal(
      <ModalManager
        showModal={true}
        title={title}
        content={content}
        successCallback={successCallback}
        closeModal={() => closeModal()}
        modalType="confirmation"
      />,
    );
  };

  const openEditModal = (materialType) => {
    if (!materialType) return;
    setModal(
      <MaterialsTypesModalAdmin
        materialTypeId={materialType._id}
        closeModal={() => closeModal()}
      />,
    );
  };

  const materialsTypesRowNode =
    materialsTypes &&
    materialsTypes.map((materialType) => {
      return (
        <MaterialTypeAdminRow
          key={materialType._id}
          materialType={materialType}
          openConfModal={(title, content, successCallback) =>
            openConfModal(title, content, successCallback)
          }
          openEditModal={(materialType) => openEditModal(materialType)}
        />
      );
    });

  return (
    <>
      <table className="table tablee4coll">
        <thead>
          <tr className="d-flex">
            <th scope="col" className="col">
              <FormattedMessage id="Type" />
            </th>
            <th scope="col" className="col col-1 text-center">
              <FormattedMessage id="Actions" />
            </th>
          </tr>
        </thead>
        <tbody>{materialsTypesRowNode}</tbody>
      </table>

      {modal}
    </>
  );
}

export default MaterialsTypesTable;
