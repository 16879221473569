import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import RequestRow from "./RequestRow";
import RequestTypes from "../../enums/RequestTypes";
import RequestStatus from "../../enums/RequestStatus";
import DateUtil from "../../util/DateUtil";
import RequestModal from "./RequestModal";
import Paginator from "../sub/Paginator";
import CustomLabel from "../sub/CustomLabel";
import Util from "../../util/Util";
import Dropdown from "react-bootstrap/Dropdown";
import { hasAlreadyRequest } from "../../actions/equipments/equipments";
import Icon from "../sub/Icon";
import TableToolbar from "../sub/bootstrap/TableToolbar";
import MenuButton from "../sub/bootstrap/MenuButton";

class Request extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      requestTypeFilter: "",
      dateFilter: "",
      statusFilter: "",
      hasAlreadyRequest: this.props.equipmentId
        ? this.hasAlreadyAnyPendingRequest()
        : false,
    };

    this.paginator = new Paginator(this);
  }

  hasAlreadyRequest(requestType) {
    this.props.onHasAlreadyRequest(
      this.props.equipmentId,
      requestType,
      (result) => this.setState({ hasAlreadyRequest: result }),
    );
  }

  hasAlreadyAnyPendingRequest() {
    return this.hasAlreadyRequest(null);
  }

  openRequestModal(equipment, RequestType) {
    if (RequestType === RequestTypes.INTERVENTION) {
      this.setState({
        modal: (
          <RequestModal
            isOpen={true}
            equipment={equipment}
            requestType={RequestTypes.INTERVENTION}
            required={true}
            close={() => this.closeModal()}
          />
        ),
      });
    } else {
      this.setState({
        modal: (
          <RequestModal
            isOpen={true}
            equipment={equipment}
            required={true}
            requestType={RequestTypes.RENEWAL}
            close={() => this.closeModal()}
          />
        ),
      });
    }
  }

  openRequestDetailModal(request) {
    var equipment = this.getEquipment(request.equipment_id);

    this.setState({
      modal: (
        <RequestModal
          isOpen={true}
          equipment={equipment}
          close={() => this.closeModal()}
          requestType={request.request_type}
          requestId={request._id}
        />
      ),
    });
  }

  getEquipment(equipmentId) {
    for (var equipment of this.props.equipments) {
      if (equipment._id === equipmentId) return equipment;
    }
  }

  closeModal() {
    this.hasAlreadyAnyPendingRequest();
    this.setState({ modal: null });
  }

  areResultsFiltered = () => {
    return (
      !Util.emptyString(this.state.requestTypeFilter) ||
      !Util.emptyString(this.state.dateFilter) ||
      !Util.emptyString(this.state.statusFilter)
    );
  };

  resetSearchFields() {
    this.setState({
      requestTypeFilter: "",
      dateFilter: "",
      statusFilter: "",
    });
  }

  render() {
    let requests = this.props.requests;

    if (this.props.equipmentId) {
      requests = requests.filter((request) => {
        if (
          request.establishment_id ===
          this.props.establishmentSettings.establishment_id
        ) {
          return request.equipment_id === this.props.equipmentId;
        } else {
          return false;
        }
      });
    }

    const actionMenu = (status) => {
      return (
        this.props.equipment && (
          <Dropdown className="ml-auto">
            <Dropdown.Toggle
              disabled={status}
              variant="info"
              id="dropdownMenuLink"
            >
              <Icon icon="circle-info" className="mr-2" />
              <FormattedMessage id="Actions" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() =>
                  this.openRequestModal(
                    this.props.equipment,
                    RequestTypes.INTERVENTION,
                  )
                }
              >
                <Icon icon="wrench" className="mr-2" />
                <FormattedMessage id="Request.Intervention" />
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                onClick={() =>
                  this.openRequestModal(
                    this.props.equipment,
                    RequestTypes.RENEWAL,
                  )
                }
              >
                <Icon icon="recycle" className="mr-2" />
                <FormattedMessage id="Replacement.Request" />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )
      );
    };

    if (requests.length === 0)
      return (
        <div>
          <TableToolbar>{actionMenu(false)}</TableToolbar>
          <div className="alert alert-secondary" role="alert">
            <FormattedMessage id="Empty.Requests" />
          </div>
          {this.state.modal}
        </div>
      );

    this.paginator.init(requests.length);

    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    let requestRowNode = requests.map((request) => {
      if (request.request_type === RequestTypes.NEW_MATERIAL) return null;

      if (this.state.requestTypeFilter && this.state.requestTypeFilter !== "") {
        if (
          request.request_type === 1 &&
          request.type_of_renewal === 0 &&
          parseInt(this.state.requestTypeFilter) !== 1
        ) {
          return null;
        } else if (
          request.request_type === 1 &&
          request.type_of_renewal === 1 &&
          parseInt(this.state.requestTypeFilter) !== 3
        ) {
          return null;
        } else if (
          request.type_of_renewal === undefined &&
          parseInt(request.request_type) !==
            parseInt(this.state.requestTypeFilter)
        ) {
          return null;
        }
      }

      if (this.state.dateFilter && this.state.dateFilter !== "") {
        if (
          DateUtil.toDate(request.creation_date) !==
          DateUtil.toDate(this.state.dateFilter)
        )
          return null;
      }

      if (this.state.statusFilter && this.state.statusFilter !== "") {
        if (parseInt(request.status) !== parseInt(this.state.statusFilter))
          return null;
      }

      if (!this.paginator.keep()) return null;

      return (
        <RequestRow
          includeType={true}
          key={request._id}
          request={request}
          required={false}
          openRequestDetailModal={(request) =>
            this.openRequestDetailModal(request)
          }
          limit={this.props.limit && true}
        />
      );
    });

    let requestTypeFilterNode = Object.values(RequestTypes).map(
      (RequestType) => {
        if (RequestType === RequestTypes.NEW_MATERIAL) return null;
        if (RequestType === RequestTypes.RENEWAL) return null;

        return (
          <option key={RequestType} value={RequestType}>
            {this.props.intl.formatMessage({
              id: "Request.Type." + RequestType,
            })}
          </option>
        );
      },
    );

    let statusFilterNode = Object.values(RequestStatus).map((requestS) => {
      return (
        <option key={requestS} value={requestS}>
          {this.props.intl.formatMessage({ id: "Request.Status." + requestS })}
        </option>
      );
    });

    return (
      <React.Fragment>
        {requests.length > 0 && (
          <React.Fragment>
            {!this.props.limit && (
              <React.Fragment>
                {this.state.hasAlreadyRequest && (
                  <div className="alert alert-warning">
                    <FormattedMessage id="Dm.Ongoing.Request" />
                  </div>
                )}
                <TableToolbar>
                  <CustomLabel
                    label={this.props.intl.formatMessage({ id: "Type" })}
                    htmlFor="search_type"
                    labelClassName="my-1 mr-2"
                  />
                  <select
                    id="search_type"
                    className="form-control mr-sm-4"
                    onChange={(e) => {
                      this.setState({
                        requestTypeFilter: e.target.value,
                      });
                    }}
                    value={this.state.requestTypeFilter}
                    disabled={disableFormInput}
                  >
                    <option value="">
                      {this.props.intl.formatMessage({ id: "All.Fem" })}
                    </option>
                    <option value={1}>
                      {this.props.intl.formatMessage({
                        id: "Request.Type." + 1,
                      })}
                    </option>
                    <option value={3}>
                      {this.props.intl.formatMessage({
                        id: "Request.Type." + 3,
                      })}
                    </option>
                    {requestTypeFilterNode}
                  </select>
                  <CustomLabel
                    label={this.props.intl.formatMessage({ id: "Date" })}
                    htmlFor="search_date"
                    labelClassName="my-1 mr-2"
                  />
                  <input
                    className="form-control mr-sm-4"
                    type="date"
                    onChange={(e) => {
                      this.setState({ dateFilter: e.target.value });
                    }}
                    value={this.state.dateFilter}
                    disabled={disableFormInput}
                  />
                  <CustomLabel
                    label={this.props.intl.formatMessage({
                      id: "Status",
                    })}
                    htmlFor="search_date"
                    id="search_status"
                    labelClassName="my-1 mr-2"
                  />
                  <select
                    id="search_status"
                    className="form-control mr-sm-4"
                    onChange={(e) => {
                      this.setState({ statusFilter: e.target.value });
                    }}
                    value={this.state.statusFilter}
                    disabled={disableFormInput}
                  >
                    <option value="">
                      {this.props.intl.formatMessage({ id: "All" })}
                    </option>
                    {statusFilterNode}
                  </select>
                  <MenuButton
                    onClick={() => this.resetSearchFields()}
                    hover={
                      this.areResultsFiltered() &&
                      !disableFormInput && (
                        <FormattedMessage id="Remove.Filter" />
                      )
                    }
                    variant={
                      this.areResultsFiltered()
                        ? "warning"
                        : "outline-secondary"
                    }
                    icon="filter"
                    disabled={!this.areResultsFiltered() || disableFormInput}
                  />
                  {this.props.equipment &&
                    actionMenu(this.state.hasAlreadyRequest)}
                </TableToolbar>
              </React.Fragment>
            )}

            <table className="table tablee4coll">
              <thead>
                <tr className="d-flex">
                  <th scope="col" className="col">
                    <FormattedMessage id="Date" />
                  </th>
                  <th scope="col" className="col">
                    <FormattedMessage id="Buyer" />
                  </th>
                  <th scope="col" className="col">
                    <FormattedMessage id="Type" />
                  </th>
                  <th scope="col" className="col">
                    <FormattedMessage id="Medical.Device" />
                  </th>
                  <th scope="col" className="col">
                    <FormattedMessage id="Request" />
                  </th>
                  <th
                    scope="col"
                    className={!this.props.limit ? "col col-2" : "col col-3"}
                  >
                    <FormattedMessage id="Status" />
                  </th>
                  {!this.props.limit && (
                    <th scope="col" className="col col-1 text-center">
                      <FormattedMessage id="Actions" />
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>{requestRowNode}</tbody>
            </table>

            {!this.props.limit && this.paginator.render()}
          </React.Fragment>
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requests: state.requests,
    materialsTypes: state.materialsTypes,
    equipments: state.equipments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onHasAlreadyRequest: (equipmentId, requestType, successCallback) =>
      dispatch(hasAlreadyRequest(equipmentId, requestType, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Request));
