import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import ModalManager from "../sub/modals/ModalManager";
import ActionMenu from "../sub/ActionMenu";

class EstablishmentBudgets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  sortByDateAsc(budget1, budget2) {
    if (budget1.year < budget2.year) return -1;
    if (budget1.year > budget2.year) return 1;
    return 0;
  }

  deleteBudget(year) {
    var sendUpdatedBudget = () => {
      for (var i = 0; i < this.props.budgets.length; i++) {
        if (parseInt(this.props.budgets[i].year) === parseInt(year)) {
          this.props.budgets.splice(i, 1);
          break;
        }
      }

      this.props.updateBudgets(this.props.budgets);
    };

    this.setState({
      modal: (
        <ModalManager
          showModal={true}
          title={<FormattedMessage id={"Confirm"} />}
          content={<FormattedHTMLMessage id="Remove.Row.Confirmation" />}
          successCallback={() => sendUpdatedBudget()}
          closeModal={() => this.closeModal()}
          modalType="confirmation"
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  editBudget(year) {
    this.props.editBudget(year);
  }

  render() {
    let budgets = this.props.budgets.sort(this.sortByDateAsc);

    let budgetsNode = budgets.map((budget) => {
      let menuItems = [];

      menuItems.push(
        // edit budget
        {
          icon: "pen-to-square",
          action: () => this.editBudget(budget.year),
          text: <FormattedMessage id="Modify" />,
        },
        // delete budget
        {
          icon: "trash",
          action: () => this.deleteBudget(budget.year),
          text: <FormattedMessage id="Delete" />,
        },
      );

      let menuAction = <ActionMenu items={menuItems} />;

      return (
        <tr key={budget.year} className="d-flex">
          <td className="col">{budget.year}</td>
          <td className="col">{budget.budget}€</td>
          {this.props.user.role !== 2 && (
            <td className="col col-2 text-center">{menuAction}</td>
          )}
        </tr>
      );
    });

    return (
      <React.Fragment>
        <table className="table col-12 tablee4coll">
          <thead>
            <tr className="d-flex">
              <th scope="col" className="col">
                <FormattedHTMLMessage id="Year" />
              </th>
              <th scope="col" className="col">
                <FormattedHTMLMessage id="Budget" />
              </th>
              {this.props.user.role !== 2 && (
                <th scope="col" className="col col-2 text-center">
                  <FormattedMessage id="Actions" />
                </th>
              )}
            </tr>
          </thead>
          <tbody>{budgetsNode}</tbody>
        </table>

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.user.lang,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EstablishmentBudgets);
