import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import FileDropZone from "../../sub/FileDropZone";
import {
  updateGeneralSettings,
  addLogo,
} from "../../../actions/settings/generalSettings/generalSettings";
import APIUrl from "../../../APIUrl";
import CustomLabel from "../../sub/CustomLabel";
import TextareaCounter from "react-textarea-counter";

class GeneralSettingsAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commercial_sign: this.props.generalSettings.commercial_sign || "",
      contact_information: this.props.generalSettings.contact_information || "",
      phone: this.props.generalSettings.phone || "",
      email: this.props.generalSettings.email || "",
      website: this.props.generalSettings.website || "",
      vatNumber: this.props.generalSettings.vatNumber || "",
      Siret: this.props.generalSettings.Siret || "",
      disabledDropZone: false,
    };
  }

  onChange(key, value) {
    this.setState({ [key]: value });
  }

  update(field, value) {
    var data = {
      generalSettingsId: this.props.generalSettings._id,
      updatedField: field,
      updatedValue: value,
    };

    this.props.onUpdateGeneralSettings(data);
  }

  onDropFile(file, clearCallback) {
    let formData = new FormData();
    formData.append(file.name, file);

    this.setState({ disabledDropZone: true });

    var successCallback = () => {
      clearCallback();
      this.setState({ disabledDropZone: false });
    };

    this.props.onAddLogo(
      this.props.generalSettings._id,
      formData,
      successCallback,
    );
  }

  render() {
    if (this.props.generalSettings.length === 0) return null;

    var img = null;
    if (this.props.generalSettings.logo) {
      img = (
        <div className="alert alert-dark col-12 text-center">
          <img
            className="logoSettings"
            src={
              APIUrl.getLogo +
              this.props.generalSettings._id +
              "/" +
              Math.random() +
              "?token=" +
              APIUrl.jwtToken
            }
            alt="logo"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/images/no_image_512.png";
            }}
          />
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 col-lg-6">
            {img}
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Logo" })}
                htmlFor="logo"
              />
              <div className="col-12 col-md-7">
                <FileDropZone
                  disabled={this.state.disabledDropZone}
                  onDropFile={(file, clearCallback) =>
                    this.onDropFile(file, clearCallback)
                  }
                  acceptedExtensions={["jpg", "jpeg", "png"]}
                  multiple={false}
                />
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Commercial.Sign" })}
                htmlFor="commercialSign"
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control"
                  id="commercialSign"
                  value={this.state.commercial_sign}
                  onChange={(e) =>
                    this.onChange("commercial_sign", e.target.value)
                  }
                  onBlur={(e) =>
                    this.update("commercial_sign", this.state.commercial_sign)
                  }
                />
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({
                  id: "Contact.Information",
                })}
                htmlFor="contactInformation"
              />
              <div className="col-12 col-md-7">
                <TextareaCounter
                  rows={3}
                  countLimit={250}
                  className="textareaCounter"
                  id="contactInformation"
                  value={this.state.contact_information}
                  onChange={(e) =>
                    this.onChange("contact_information", e.target.value)
                  }
                  onBlur={(e) =>
                    this.update(
                      "contact_information",
                      this.state.contact_information,
                    )
                  }
                />
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Phone" })}
                htmlFor="phone"
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  value={this.state.phone}
                  onChange={(e) => this.onChange("phone", e.target.value)}
                  onBlur={(e) => this.update("phone", this.state.phone)}
                />
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Email" })}
                htmlFor="email"
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  value={this.state.email}
                  onChange={(e) => this.onChange("email", e.target.value)}
                  onBlur={(e) => this.update("email", this.state.email)}
                />
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Website" })}
                htmlFor="website"
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control"
                  id="website"
                  value={this.state.website}
                  onChange={(e) => this.onChange("website", e.target.value)}
                  onBlur={(e) => this.update("website", this.state.website)}
                />
              </div>
            </div>
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "VAT.Number" })}
                htmlFor="vatNumber"
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control"
                  id="vatNumber"
                  value={this.state.vatNumber}
                  onChange={(e) => this.onChange("vatNumber", e.target.value)}
                  onBlur={(e) => this.update("vatNumber", this.state.vatNumber)}
                />
              </div>
            </div>
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "SIRET" })}
                htmlFor="Siret"
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control"
                  id="Siret"
                  value={this.state.Siret}
                  onChange={(e) => this.onChange("Siret", e.target.value)}
                  onBlur={(e) => this.update("Siret", this.state.Siret)}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.user.lang,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateGeneralSettings: (data) => dispatch(updateGeneralSettings(data)),
    onAddLogo: (generalSettingsId, data, successCallback) =>
      dispatch(addLogo(generalSettingsId, data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(GeneralSettingsAdmin));
