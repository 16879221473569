import APIUrl from "../../APIUrl";
import axios from "axios";

import { getAllRequests } from "../requests/requests";
import { getMaintenances } from "../maintenances/maintenances";
import { getBrands } from "../configurables/brands";
import { getModels } from "../configurables/models";

export const GET_EQUIPMENTS = "GET_EQUIPMENTS";

function getEquipmentsAction(equipments) {
  return { type: GET_EQUIPMENTS, equipments: equipments };
}

export const getEquipments = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getEquipments)
      .then(function (response) {
        dispatch(getEquipmentsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateEquipment = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateEquipment, data)
      .then(function (response) {
        dispatch(getEquipments());
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

// ==================================================================
// =========================== ADMIN ================================
// ==================================================================

var lastEstablishmentId = null;
export const getEstablishmentEquipments = function (
  establishmentId,
  successCallback,
) {
  // If none is provided, fetch & remember last
  if (!establishmentId) {
    establishmentId = lastEstablishmentId;
  } else {
    lastEstablishmentId = establishmentId;
  }

  if (!establishmentId) return;

  return function (dispatch) {
    return axios
      .get(APIUrl.getEstablishementEquipments + establishmentId)
      .then(function (response) {
        dispatch(getEquipmentsAction(response.data));
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getUnassignedEquipments = function () {
  return function (dispatch) {
    return axios
      .post(APIUrl.getUnassignedEquipments)
      .then(function (response) {
        dispatch(getEquipmentsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const checkIfEquipmentExists = function (
  data,
  existsCallback,
  noExistsCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.checkIfEquipmentExists, data)
      .then(function (response) {
        if (response.status === 200) existsCallback();
        else noExistsCallback();
      });
  };
};

export const addEquipment = function (establishmentId, data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addEquipment, data)
      .then(function (response) {
        dispatch(getEstablishmentEquipments(establishmentId));
        successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateEquipmentAdmin = function (
  establishmentId,
  data,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateEquipmentAdmin, data)
      .then(function (response) {
        dispatch(getEstablishmentEquipments(establishmentId, successCallback));
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteEquipment = function (
  establishmentId,
  data,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteEquipment, data)
      .then(function (response) {
        dispatch(getEstablishmentEquipments(establishmentId, successCallback));
        dispatch(getAllRequests());
      })
      .catch(function (err) {
        throw err;
      });
  };
};

// Assign equipment to establishment
export const assignEquipment = function (companyId, data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.assignEquipment, data)
      .then(function (response) {
        dispatch(getUnassignedEquipments(companyId, successCallback));
        dispatch(getAllRequests());
        dispatch(getMaintenances());
      })
      .catch(function (err) {
        throw err;
      });
  };
};

// Unassign equipment from establishment
export const unassignEquipment = function (
  establishmentId,
  data,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.unassignEquipment, data)
      .then(function (response) {
        dispatch(getEstablishmentEquipments(establishmentId, successCallback));
        dispatch(getAllRequests());
        dispatch(getMaintenances());
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addEquipmentFiles = function (
  establishmentId,
  equipmentId,
  data,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addEquipmentFiles + equipmentId, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        dispatch(getEstablishmentEquipments(establishmentId, successCallback));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteEquipmentFileById = function (
  establishmentId,
  equipmentId,
  fileName,
  successCallback,
) {
  return async function (dispatch) {
    try {
      await axios.post(APIUrl.deleteEquipmentFileById, {
        equipmentId,
        fileName,
      });
      dispatch(getEstablishmentEquipments(establishmentId, successCallback));
    } catch (err) {
      console.error(err);
    }
  };
};

export const getAllEquipments = function (successCallback) {
  return function (dispatch) {
    return axios
      .get(APIUrl.getAllEquipments)
      .then(function (response) {
        dispatch(getEquipmentsAction(response.data));
        if (successCallback) return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getEquipment = function (equipmentId, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.getEquipment, { equipmentId })
      .then(function (response) {
        return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const hasAlreadyRequest = function (
  equipmentId,
  requestType,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.hasAlreadyRequest, { equipmentId, requestType })
      .then(function (response) {
        return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

// Import equipments
export const importEquipments = function (
  establishmentId,
  data,
  successCallback,
  failureCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.importEquipments, data)
      .then(function (response) {
        dispatch(getEstablishmentEquipments(establishmentId, successCallback));
        dispatch(getBrands());
        dispatch(getModels());
        return successCallback(response.data);
      })
      .catch(function (err) {
        failureCallback(err.response.data);
        throw err;
      });
  };
};

export const resetEquipments = function () {
  lastEstablishmentId = null;
};
