import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { deleteEquipmentsRequest } from "../../../actions/equipmentsRequest/equipmentsRequest";
import EquipmentRequestRow from "./EquipmentRequestRow";
import EquipmentRequestModal from "./EquipmentRequestModal";
import Icon from "../../sub/Icon";
import TableToolbar from "../../sub/bootstrap/TableToolbar";
import ModalManager from "../../sub/modals/ModalManager";
import { Alert, Button } from "react-bootstrap";
import { nanoid } from "nanoid";
import EquipmentModalAdmin from "../admin/EquipmentModalAdmin";
import Roles from "../../../enums/Roles";

class EquipmentsRequest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      establishment_id: "",
      description: "",
      material_type_id: "",
      creation_date: "",
      modal: null,
    };

    // Update existing equipment
    if (this.props.equipmentsRequest) {
      this.state = {
        establishment_id: this.props.equipmentsRequest.establishment_id,
        description: this.props.equipmentsRequest.description,
        material_type_id: this.props.equipmentsRequest.material_type_id,
        creation_date: this.props.equipmentsRequest.creation_date,
      };
    }
  }

  viewEquipmentRequest(equipmentRequest) {
    this.setState({
      modal: (
        <EquipmentRequestModal
          equipment_request={equipmentRequest}
          establishmentId={equipmentRequest.establishment_id}
          mode={"view"}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  newEquipment(equipmentRequest) {
    if (this.props.user.role === Roles.CLIENT) return;
    this.setState({
      modal: (
        <EquipmentModalAdmin
          close={(e) => {
            if (e) {
              this.props.onDeleteEquipmentsRequest(
                { _id: equipmentRequest._id },
                () => this.closeModal(),
              );
            } else {
              this.closeModal();
            }
          }}
          barcode={nanoid(10)}
          establishmentId={equipmentRequest.establishment_id}
          equipmentRequest={equipmentRequest}
        />
      ),
    });
  }

  newEquipmentRequest() {
    this.setState({
      modal: (
        <EquipmentRequestModal
          establishmentId={this.props.establishmentId}
          mode={"new"}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  editEquipmentRequest(equipmentRequest) {
    this.setState({
      modal: (
        <EquipmentRequestModal
          equipment_request={equipmentRequest}
          establishmentId={equipmentRequest.establishment_id}
          mode={"edit"}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  deleteEquipmentRequest(equipmentRequest) {
    const title = <FormattedMessage id={"Equipment.Request.Delete"} />;
    const content = (
      <FormattedMessage id={"Equipment.Request.Delete.Content"} />
    );
    this.setState({
      modal: (
        <ModalManager
          showModal={true}
          title={title}
          content={content}
          mandatoryConfirmation
          successCallback={() => {
            this.props.onDeleteEquipmentsRequest(
              { _id: equipmentRequest._id },
              () => this.closeModal(),
            );
          }}
          closeModal={() => this.closeModal()}
          modalType="confirmation"
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  render() {
    const equipments_request = this.props.equipmentsRequest
      ? this.props.establishmentId
        ? this.props.equipmentsRequest.filter(
            (r) => r.establishment_id === this.props.establishmentId,
          )
        : this.props.equipmentsRequest
      : [];

    let i = 0;
    const equipmentsNode = equipments_request.map((equipmentRequest) => {
      if (this.props.limit && ++i > this.props.limit) return null;

      return (
        <EquipmentRequestRow
          key={equipmentRequest._id}
          equipmentRequest={equipmentRequest}
          establishmentId={this.props.establishmentId}
          viewEquipmentRequest={(equipmentRequest) =>
            this.viewEquipmentRequest(equipmentRequest)
          }
          editEquipmentRequest={(equipmentRequest) =>
            this.editEquipmentRequest(equipmentRequest)
          }
          newEquipment={(equipmentRequest) =>
            this.newEquipment(equipmentRequest)
          }
          deleteEquipmentRequest={(equipmentRequest) =>
            this.deleteEquipmentRequest(equipmentRequest)
          }
          limit={this.props.limit && true}
        />
      );
    });

    return (
      <>
        {this.props.user.role === Roles.CLIENT && (
          <TableToolbar>
            <Button
              className="ml-auto"
              variant="info"
              onClick={(e) => {
                this.newEquipmentRequest();
              }}
            >
              <Icon icon="circle-plus" className="mr-2" />
              <FormattedMessage id="New.Request" />
            </Button>
          </TableToolbar>
        )}

        {equipments_request && equipments_request.length === 0 && (
          <Alert variant="secondary">
            <FormattedMessage id="Empty.Requests" />
          </Alert>
        )}

        {equipments_request && equipments_request.length > 0 && (
          <table id="equipments_request" className="table tablee4coll">
            <thead>
              <tr className="d-flex align-items-center h-100">
                {!this.props.establishmentId && (
                  <th scope="col" className="col text-left">
                    <FormattedMessage id="Establishment" />
                  </th>
                )}
                <th scope="col" className="col text-left">
                  <FormattedMessage id="Date" />
                </th>
                <th scope="col" className="col text-center">
                  <FormattedMessage id="Desired.Date" />
                </th>
                <th scope="col" className="col  text-center">
                  <strong>
                    <FormattedMessage id="Type" />
                  </strong>
                </th>
                <th scope="col" className="col text-center">
                  <FormattedMessage id="Client" />
                </th>
                {!this.props.limit && (
                  <th scope="col" className="col text-center">
                    <FormattedMessage id="Actions" />
                  </th>
                )}
              </tr>
            </thead>
            <tbody>{equipmentsNode}</tbody>
          </table>
        )}

        {this.state.modal}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    equipmentsRequest: state.equipmentsRequest,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteEquipmentsRequest: (equipmentRequest, successCallback) =>
      dispatch(deleteEquipmentsRequest(equipmentRequest, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(EquipmentsRequest));
