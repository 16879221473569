import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import CollaboratorAssignmentsRow from "./CollaboratorAssignmentsRow";
import Paginator from "../../../sub/Paginator";

class CollaboratorAssignments extends React.Component {
  constructor(props) {
    super(props);

    this.paginator = new Paginator(this);
  }

  getEstablishment(establishmentId) {
    for (let establishment of this.props.establishments) {
      if (establishment._id === establishmentId) return establishment;
    }
  }

  render() {
    if (
      !this.props.collaborator ||
      !this.props.collaborator.establishments ||
      this.props.collaborator.establishments.length === 0
    )
      return null;

    this.paginator.init(this.props.collaborator.establishments.length);
    var hasValues = false;
    var establishmentsNode = this.props.collaborator.establishments.map((e) => {
      if (!this.paginator.keep()) return null;

      var establishment = this.getEstablishment(e.establishment_id);

      if (!establishment) return null;
      else hasValues = true;

      return (
        <CollaboratorAssignmentsRow
          key={e.establishment_id}
          collaborator={this.props.collaborator}
          establishment={establishment}
        />
      );
    });

    if (!hasValues) return null;

    return (
      <React.Fragment>
        <table className="table table-striped col-12 tablee4coll">
          <thead>
            <tr>
              <th>
                <FormattedMessage id="Establishment" />
              </th>
              <th className="d-none d-md-table-cell">
                <FormattedMessage id="Address" />
              </th>
              <th className="d-none d-md-table-cell">
                <FormattedMessage id="Mercurial" />
              </th>
              <th className="d-none d-lg-table-cell">
                <FormattedMessage id="Total.Cmd.Excl.Tax" />
              </th>
              <th className="d-none d-lg-table-cell">
                <FormattedMessage id="Requests" />
              </th>
              <th className="d-none d-lg-table-cell">
                <FormattedMessage id="Equipments" />
              </th>
              <th className="text-center">
                <FormattedMessage id="Actions" />
              </th>
            </tr>
          </thead>
          <tbody>{establishmentsNode}</tbody>
        </table>

        {this.paginator.render()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    establishments: state.establishments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollaboratorAssignments);
