import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import UserModal from "./UserModal";
import Users from "./Users";
import TableToolbar from "../sub/bootstrap/TableToolbar";
import Roles from "../../enums/Roles";

class EstablishmentUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  closeModal() {
    this.setState({ modal: null });
  }

  openModal() {
    this.setState({
      modal: (
        <UserModal
          establishment={this.props.establishment}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.user.role === Roles.ADMIN && (
          <TableToolbar>
            <button
              className="btn btn-info ml-auto"
              onClick={(e) => this.openModal()}
            >
              <FormattedMessage id="User.Add" />
            </button>
          </TableToolbar>
        )}
        {this.props.clients && this.props.clients.length !== 0 ? (
          <Users clients={this.props.clients} />
        ) : (
          <div className="alert alert-secondary mt-3" role="alert">
            <FormattedMessage id="Empty.Users" />
          </div>
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EstablishmentUsers);
