import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import OrderProductsAdmin from "./OrderProductsAdmin";
import { splitOrderAdmin } from "../../../actions/orders/orders";
import CustomLabel from "../../sub/CustomLabel";
import { Button, Modal } from "react-bootstrap";
import TextareaCounter from "react-textarea-counter";

class SplitOrderModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: true,
      comment: "",
    };
  }

  close() {
    this.props.closeConfModal();
  }

  getSplittedProducts() {
    var productsAvailable = [];
    var productsUnavailable = [];

    var inputsUnavailable = document.getElementsByClassName(
      "unavailable-product",
    );
    var inputsAvailable = document.getElementsByClassName("available-product");

    for (var p of this.props.order.products) {
      var product = JSON.parse(JSON.stringify(p));

      for (var inputUnavailable of inputsUnavailable) {
        if (
          inputUnavailable.getAttribute("id") ===
            "unavailable-" + product.id_product &&
          inputUnavailable.value > 0
        ) {
          product.quantity = inputUnavailable.value;
          product.total_ht = product.prix_u_ht_emera * inputUnavailable.value;
          product.total_ttc =
            product.prix_u_ht_emera * inputUnavailable.value +
            product.prix_u_ht_emera * inputUnavailable.value * product.tva;
          productsUnavailable.push(product);
        }
      }

      product = JSON.parse(JSON.stringify(p));

      for (var inputAvailable of inputsAvailable) {
        if (
          inputAvailable.getAttribute("id") ===
            "available-" + product.id_product &&
          inputAvailable.value > 0
        ) {
          product.quantity = inputAvailable.value;
          product.total_ht = product.prix_u_ht_emera * inputAvailable.value;
          product.total_ttc =
            product.prix_u_ht_emera * inputAvailable.value +
            product.prix_u_ht_emera * inputAvailable.value * product.tva;
          productsAvailable.push(product);
        }
      }
    }

    return {
      productsAvailable: productsAvailable,
      productsUnavailable: productsUnavailable,
    };
  }

  checkProductsDisponibility() {
    var data = this.getSplittedProducts();
    this.setState({
      disabled:
        data.productsAvailable.length === 0 ||
        data.productsUnavailable.length === 0,
    });
  }

  split(e, order) {
    if (this.state.disabled) return;

    var data = this.getSplittedProducts();

    data.orderId = order._id;

    if (this.state.comment) data.comment = this.state.comment;

    var successCallback = () => {
      this.close();
    };

    this.setState({ disabled: true });

    this.props.onSplitOrderAdmin(data, successCallback);
  }

  onChange(key, value) {
    this.setState({ [key]: value });
  }

  render() {
    return (
      <Modal
        show={true}
        onHide={() => this.close()}
        backdrop={"static"}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>
              {" "}
              <FormattedMessage id="Order" /> {this.props.order.ref}{" "}
            </span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <OrderProductsAdmin
            order={this.props.order}
            onClickCallback={() => this.checkProductsDisponibility()}
            paginate={true}
          />
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Comment" })}
            htmlFor="comment"
            labelClassName="mt-4 mb-2"
          />

          <TextareaCounter
            countLimit={1000}
            rows={3}
            className="textareaCounter"
            value={this.state.message}
            id="comment"
            name="comment"
            onChange={(e) => this.onChange("comment", e.target.value)}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.close()}>
            <FormattedMessage id="Cancel" />
          </Button>
          <Button
            variant="info"
            onClick={(e) => this.split(e, this.props.order)}
            disabled={this.state.disabled}
          >
            <FormattedMessage id="Split" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSplitOrderAdmin: (data, successCallback) =>
      dispatch(splitOrderAdmin(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(SplitOrderModalAdmin));
