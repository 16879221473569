import ProductsTotalUtil from "./ProductsTotalUtil";
import Maths from "./Maths";

export default class EstablishmentTotalUtil {
  constructor(
    establishmentId,
    clients,
    orders,
    products,
    oldProducts,
    getOldProductsCallback,
  ) {
    this.establishmentId = establishmentId;
    this.clients = clients;
    this.orders = orders;
    this.products = products;
    this.oldProducts = oldProducts;
    this.getOldProductsCallback = getOldProductsCallback;
  }

  getTotalHT() {
    // First, find out all the orders from the establishment
    var orders = [];
    for (let client of this.clients) {
      // Client does not belong to the establishment
      if (client.establishment_id !== this.establishmentId) continue;

      for (let order of this.orders) {
        if (order.id_client === client._id) orders.push(order);
      }
    }

    var totalHT = 0;
    for (let order of orders) {
      totalHT += this.getCmdTotalHT(order);
    }

    return Maths.round(totalHT);
  }

  getCmdTotalHT(order) {
    var productsTotalUtil = new ProductsTotalUtil(
      order.products,
      this.products,
      this.oldProducts,
      (data) => this.getOldProductsCallback(data),
    );

    return Maths.round(productsTotalUtil.totalHt);
  }
}
