import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import EstablishmentInfo from "./EstablishmentInfo";
import EstablishmentConfiguration from "./EstablishmentConfiguration";
import EstablishmentUsers from "./EstablishmentUsers";
import ApiConfiguration from "../api/must/ApiConfiguration";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Modal } from "react-bootstrap";

class ClientsModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      latLongEstablishement: undefined,
    };
  }

  componentDidMount() {
    if (
      this.props.establishment !== undefined &&
      this.props.establishment.address !== undefined &&
      this.props.establishment.postal_code !== undefined &&
      this.props.establishment.city !== undefined
    ) {
      this.getLatLong(
        this.props.establishment.address,
        this.props.establishment.postal_code,
        this.props.establishment.city,
      );
    }
  }
  updateChildState(data) {
    this.setState(data);
  }

  getLatLong(address, postal_code, city) {
    if (
      address !== "" &&
      address !== undefined &&
      postal_code !== "" &&
      postal_code !== undefined &&
      city !== "" &&
      city !== undefined
    ) {
      var addressCompl =
        address.replace(/\s+/g, "+") + "+" + postal_code + "+" + city;

      async function promiseLatLongEstablishement() {
        var APIaddress =
          "https://api-adresse.data.gouv.fr/search/?q=" + addressCompl;
        var config = {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };
        const promiseLatLongEstablishement = fetch(APIaddress, config);
        return await promiseLatLongEstablishement;
      }

      var getLatLong = async (callbackFunction) => {
        try {
          const LatLongEstablishement =
            await promiseLatLongEstablishement().then((res) => {
              return res.json();
            });
          return callbackFunction(LatLongEstablishement);
        } catch (e) {
          // console.log(e);
        }
      };

      const callbackFunction = (result, callback) => {
        this.setState({
          latLongEstablishement: {
            lat: result.features[0].geometry.coordinates[0],
            lon: result.features[0].geometry.coordinates[1],
          },
        });
      };

      getLatLong(callbackFunction);
    }
  }

  confirm() {
    this.props.successCallback();
    this.props.closeModal();
  }

  getEstablishmentSettings(establishmentId) {
    for (let establishmentSettings of this.props.establishmentsSettings) {
      if (establishmentSettings.establishment_id === establishmentId)
        return establishmentSettings;
    }
  }

  getClients(establishmentId) {
    var clients = [];

    for (let client of this.props.clients) {
      if (client.establishment_id === establishmentId) {
        clients.push(client);
      }
    }

    return clients;
  }

  render() {
    var establishmentSettings = null;
    if (this.props.establishment)
      establishmentSettings = this.getEstablishmentSettings(
        this.props.establishment._id,
      );

    var clients = [];
    if (this.props.establishment)
      clients = this.getClients(this.props.establishment._id);

    // Wait for the settings object
    if (this.props.establishment && !establishmentSettings) return null;

    let target = this.props.target || "clientInfo";

    // If the user adds a client (no establishment), then disable the additionnal tabs
    var aClassName = this.props.establishment ? "" : "disabled";

    // https://stackoverflow.com/questions/37308719/react-component-wait-for-required-props-to-render
    // https://zaiste.net/posts/javascript-destructuring-assignment-default-values/
    const { enabled: crmEnabled = false, software: crmSoftware = null } = this
      .props.company.crm
      ? this.props.company.crm
      : {};

    return (
      <Modal
        show={true}
        onHide={() => this.props.closeModal()}
        backdrop={"static"}
        size="xl"
      >
        <Modal.Body>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => this.props.closeModal()}
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <Tabs defaultActiveKey={target} id="clientTabs">
            <Tab
              eventKey="clientInfo"
              title={this.props.intl.formatMessage({
                id: "Informations",
              })}
            >
              <EstablishmentInfo
                establishment={this.props.establishment}
                latLong={this.state.latLongEstablishement}
                updateParentState={this.updateChildState.bind(this)}
                openModal={(establishment) =>
                  this.props.openModal(establishment)
                }
                closeModal={() => this.props.closeModal()}
              />
            </Tab>
            <Tab
              eventKey="clientSettings"
              title={this.props.intl.formatMessage({ id: "Settings" })}
              disabled={aClassName}
            >
              <EstablishmentConfiguration
                establishment={this.props.establishment}
                establishmentSettings={establishmentSettings}
              />
            </Tab>
            <Tab
              eventKey="clientUsers"
              title={this.props.intl.formatMessage({ id: "Users" })}
              disabled={aClassName}
            >
              <EstablishmentUsers
                establishment={this.props.establishment}
                clients={clients}
              />
            </Tab>
            {
              // Check if account is linked to an external CRM and display associated tab to PSDM admin only
              crmEnabled && this.props.user.role === 1 && (
                <Tab
                  eventKey="apiConfiguration"
                  title={this.props.intl.formatMessage(
                    { id: "API.CRM.App.Link" },
                    { crmSoftware: crmSoftware },
                  )}
                  disabled={aClassName}
                >
                  <ApiConfiguration
                    establishment={this.props.establishment}
                    clients={clients}
                  />
                </Tab>
              )
            }
          </Tabs>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    establishmentsSettings: state.establishmentsSettings,
    clients: state.clients,
    user: state.user,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ClientsModalAdmin));
