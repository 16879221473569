import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import ActionMenu from "../../sub/ActionMenu";
import dateUtil from "../../../util/DateUtil";
import Roles from "../../../enums/Roles";
import Icon from "../../sub/Icon";
class EquipmentRequestRow extends React.Component {
  getEstablishmentNameByEstablishmentId(establishmentId) {
    for (let establishment of this.props.establishments) {
      if (establishment._id === establishmentId) {
        return establishment.name;
      }
    }
  }

  render() {
    const {
      equipmentRequest,
      viewEquipmentRequest,
      newEquipment,
      editEquipmentRequest,
      deleteEquipmentRequest,
      key,
      user,
    } = this.props;

    const establishmentName =
      this.getEstablishmentNameByEstablishmentId(
        equipmentRequest.establishment_id,
      ) || "";

    const menuItems = [];
    menuItems.push({
      id: "equipmentRequestView" + equipmentRequest._id,
      icon: "eye",
      action: () => viewEquipmentRequest(equipmentRequest),
      text: <FormattedMessage id="Consult" />,
    });
    user.role === Roles.CLIENT &&
      menuItems.push({
        id: "equipmentRequestEdit" + equipmentRequest._id,
        icon: "pen-to-square",
        action: () => editEquipmentRequest(equipmentRequest),
        text: <FormattedMessage id="Modify" />,
      });
    user.role !== Roles.CLIENT &&
      menuItems.push({
        id: "equipmentNew" + this.props.equipmentRequest._id,
        icon: "circle-plus",
        action: () => newEquipment(this.props.equipmentRequest),
        text: <FormattedMessage id="Add.Equipment" />,
      });
    menuItems.push({
      id: "equipmentRequestDelete" + equipmentRequest._id,
      icon: "trash",
      action: () => deleteEquipmentRequest(equipmentRequest),
      text: <FormattedMessage id="Delete" />,
    });

    const menuAction = (
      <ActionMenu items={menuItems} size="2xl" className="ml-auto mr-2" />
    );

    const dateNow = new Date();
    const dateDesired = equipmentRequest.desired_availability_date
      ? new Date(equipmentRequest.desired_availability_date)
      : "-";

    let classNameColor;

    if (dateDesired === "-") classNameColor = "badge-light";
    else if (dateDesired < dateNow) classNameColor = "badge-danger";
    else if (
      dateDesired.getTime() / 1000 - 3600 * 24 * 7 <
      dateNow.getTime() / 1000
    )
      classNameColor = "badge-warning";
    else classNameColor = "badge-success";

    return (
      <tr className="d-flex align-items-center" key={key}>
        {!this.props.establishmentId && (
          <td className="col text-left">{establishmentName}</td>
        )}
        <td className="col text-left">
          {dateUtil.toDate(equipmentRequest.creation_date)}
        </td>
        <td className="col text-center">
          <span className={"w-100 badge " + classNameColor}>
            {equipmentRequest.desired_availability_date
              ? dateUtil.toDate(equipmentRequest.desired_availability_date)
              : "-"}
          </span>
        </td>
        <td className="col text-center">
          {equipmentRequest.material_type_name}
        </td>
        <td
          className={
            equipmentRequest.client_is_deleted
              ? "col text-center text-danger"
              : "col text-center"
          }
        >
          {equipmentRequest.client_name}
          {equipmentRequest.client_is_deleted && (
            <Icon
              icon="exclamation-triangle"
              className="ml-1"
              hover={this.props.intl.formatMessage({ id: "User.Deleted" })}
            />
          )}
        </td>
        {!this.props.limit && <td className="col text-center">{menuAction}</td>}
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    establishments: state.establishments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(EquipmentRequestRow));
