import APIUrl from "../../../APIUrl";
import axios from "axios";

export const GET_MATERIOVIGILANCE_EQUIPEMENT =
  "GET_MATERIOVIGILANCE_EQUIPEMENT";

// ==================================================================
// =========================== ADMIN ================================
// ==================================================================

function getMateriovigilanceEquipementAction(materiovigilance) {
  return {
    type: GET_MATERIOVIGILANCE_EQUIPEMENT,
    materiovigilance: materiovigilance,
  };
}

export const addEquipementToMateriovigilance = function (
  data,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addMateriovigilance, data)
      .then(function (response) {
        dispatch(getMateriovigilanceEquipement());
        successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getMateriovigilanceEquipement = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getMateriovigilance)
      .then(function (response) {
        if (response) {
          dispatch(getMateriovigilanceEquipementAction(response.data));
        }
      })
      .catch(function (err) {
        throw err;
      });
  };
};
export const deleteMateriovigilanceEquipement = function (model_id) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteMateriovigilance, model_id)
      .then(function (response) {
        if (response) {
          dispatch(getMateriovigilanceEquipement(response.data));
        }
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateEquipementToMateriovigilance = function (
  data,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateMateriovigilance, data)
      .then(function (response) {
        dispatch(getMateriovigilanceEquipement());
        successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addDocMateriovigilance = function (data, file, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.postDocMateriovigilance + data, file, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        dispatch(getMateriovigilanceEquipement());
        // successCallback(response);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const downloadMateriovigilanceDoc = function (
  model_id,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.getMateriovigilanceDoc + model_id)
      .then(function (response) {
        // dispatch(getMateriovigilanceEquipement());
        successCallback(response);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const removeMateriovigilanceFile = function (
  materiovigilanceId,
  successCallback,
) {
  // console.log(successCallback)
  return function (dispatch) {
    return axios
      .post(APIUrl.removeMateriovigilanceFile + materiovigilanceId)
      .then(function (response) {
        successCallback(response);
        // dispatch(getMateriovigilanceEquipement());
      })
      .catch(function (err) {
        console.log("err", err);
        throw err;
      });
  };
};
