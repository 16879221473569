import React from "react";
import { connect } from "react-redux";
import Barcode from "react-barcode";
import StringUtil from "../../../util/StringUtil";
import { nanoid } from "nanoid";

const BAR_CODE_MAX = 24;

class BarCodesPDF extends React.Component {
  generateBarCodes(establishmentId, equipments) {
    if (!establishmentId || !equipments) return;
    if (equipments.length <= 0) return;

    let barCodes = [];
    for (let equipment of equipments) {
      if (equipment.establishment_id === establishmentId) {
        barCodes.push(equipment.bar_code);
      }
    }
    return barCodes;
  }

  generateRandomBarCodes(barCodeNumberToGenerate) {
    if (!barCodeNumberToGenerate) barCodeNumberToGenerate = BAR_CODE_MAX;

    let barCodes = [];
    for (let i = 0; i < barCodeNumberToGenerate; i++) barCodes.push(nanoid(10));
    return barCodes;
  }

  displayBarCodes(companyName, barCodes) {
    if (!companyName) companyName = "";
    if (!barCodes) return;
    if (barCodes.length <= 0) return;

    return barCodes.map((uuid, index) => (
      <div key={index} className="barcode">
        <div className="companyName">
          {StringUtil.truncate(companyName, 20)}
        </div>
        <Barcode width={1} height={40} key={uuid} value={uuid} fontSize={12} />
      </div>
    ));
  }

  displayBarCode(companyName, barCode, position) {
    if (!companyName) companyName = "";
    if (!barCode) return;
    if (!position) position = 0;

    let barCodes = new Array(BAR_CODE_MAX).fill(barCode);

    return barCodes.map((uuid, index) => (
      <div key={index} className="barcode">
        <div className={index === position ? "companyName" : "invisible"}>
          {StringUtil.truncate(companyName, 20)}
        </div>
        <div className={index === position ? "" : "invisible"}>
          <Barcode
            width={1}
            height={40}
            key={uuid}
            value={uuid}
            fontSize={12}
          />
        </div>
      </div>
    ));
  }

  render() {
    const {
      establishmentId,
      equipments,
      company,
      barCode,
      barCodeIndex,
      barCodeNumber,
      id,
    } = this.props;

    let uuids = [];
    let barcodeNodes = null;

    // Print fleet barCodes
    if (establishmentId && equipments && equipments.length > 0) {
      uuids = this.generateBarCodes(establishmentId, equipments);
      barcodeNodes = this.displayBarCodes(company.name, uuids);

      // Print equipment barCode
    } else if (barCodeIndex >= 0) {
      barcodeNodes = this.displayBarCode(company.name, barCode, barCodeIndex);

      // Print new random barCodes
    } else if (barCodeNumber) {
      uuids = this.generateRandomBarCodes(barCodeNumber);
      barcodeNodes = this.displayBarCodes(company.name, uuids);
    }

    return (
      <div className="pdf-text-center" id={id}>
        <div className="pdf-barcode">{barcodeNodes}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BarCodesPDF);
