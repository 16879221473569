import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../../../util/Util";
import { addCollaboratorEstablishment } from "../../../../actions/collaborators/collaborators";
import CollaboratorAssignments from "./CollaboratorAssignments";
import CustomLabel from "../../../sub/CustomLabel";
import Icon from "../../../sub/Icon";

class Assignments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      establishment_id: "",

      disabled: false,
    };
  }

  isUpdate() {
    return (
      this.props.collaborator !== undefined && this.props.collaborator !== null
    );
  }

  close() {
    this.props.close();
  }

  stopEvent(e) {
    e.stopPropagation();
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  addEstablishment() {
    if (Util.emptyString(this.state.establishment_id) || this.state.disabled)
      return;

    var data = {
      collaboratorId: this.props.collaborator._id,
      establishmentId: this.state.establishment_id,
    };

    this.setState({ disabled: true });

    var successCallback = () => {
      this.setState({ disabled: false });
    };

    this.props.onAddCollaboratorEstablishment(data, successCallback);
  }

  render() {
    if (!this.props.collaborator) return null;

    var establishmentsNode = this.props.establishments.map((establishment) => {
      var disabled = false;

      // Disable the options of the select list when establishment is already assigned to current user
      for (let collaborator of this.props.collaborators) {
        if (
          collaborator.establishments &&
          collaborator._id === this.props.collaborator._id
        ) {
          for (let e of collaborator.establishments) {
            if (establishment._id === e.establishment_id) {
              disabled = true;
              break;
            }
          }
        }
      }

      return (
        <option
          value={establishment._id}
          key={establishment._id}
          disabled={disabled}
        >
          {establishment.name}
        </option>
      );
    });

    return (
      <React.Fragment>
        <div className="col-12 col-lg-9 col-xl-8">
          <div className="form-group row d-flex align-items-center">
            <CustomLabel
              label={this.props.intl.formatMessage({
                id: "Assign.Establishment",
              })}
              htmlFor="establishment_id"
              labelClassName="col-12 col-md-5"
            />
            <div className="col-11 col-md-6">
              <select
                className="form-control d-inline"
                id="establishment_id"
                defaultValue={this.state.establishment_id}
                onChange={(e) =>
                  this.onChange("establishment_id", e.target.value)
                }
              >
                <option value=""></option>
                {establishmentsNode}
              </select>
            </div>

            <div className="col-1 text-left align-left float-left p-0">
              <Icon
                id={"assign"}
                className="text-success"
                onClick={() => this.addEstablishment()}
                disabled={
                  Util.emptyString(this.state.establishment_id) ||
                  this.state.disabled
                }
                icon="square-plus"
                clickable
                size="xl"
                hover={
                  Util.emptyString(this.state.establishment_id) ||
                  this.state.disabled ? (
                    ""
                  ) : (
                    <FormattedMessage id="Assign" />
                  )
                }
              />
            </div>
          </div>
        </div>

        <CollaboratorAssignments collaborator={this.props.collaborator} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    collaborators: state.collaborators,
    establishments: state.establishments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddCollaboratorEstablishment: (data, successCallback) =>
      dispatch(addCollaboratorEstablishment(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Assignments));
