import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import DateUtil from "../../util/DateUtil";
import APIUrl from "../../APIUrl";
import { Badge, Card } from "react-bootstrap";

class EquipmentOverview extends React.Component {
  getResident(residentId) {
    for (var resident of this.props.residents) {
      if (resident._id === residentId) return resident;
    }
  }

  getBrand(brandId) {
    for (var brand of this.props.brands) {
      if (brand._id === brandId) return brand;
    }
  }

  getModel(modelId) {
    for (var model of this.props.models) {
      if (model._id === modelId) return model;
    }
  }

  getMaterialType(materialTypeId) {
    for (let materialType of this.props.materialsTypes) {
      if (materialType._id === materialTypeId) return materialType;
    }
  }

  getMateriovigilance(equipmentModelId) {
    return this.props.materiovigilance.find(
      (m) => m.model_id === equipmentModelId,
    );
  }

  getEstabRep(clientId) {
    if (!clientId) return null;
    return this.props.clients.find((c) => c._id === clientId);
  }

  render() {
    const { equipment, estabRepId } = this.props;

    if (!equipment) return null;

    const brandName = this.getBrand(equipment.brand_id).name.toUpperCase();
    const modelName = this.getModel(equipment.model_id).name.toUpperCase();
    const resident = equipment.resident_id
      ? this.getResident(equipment.resident_id)
      : null;
    const materialType = this.getMaterialType(equipment.material_type_id);
    const materiovigilance = this.getMateriovigilance(equipment.model_id);
    const estabRep = this.getEstabRep(estabRepId);

    let mvClassName = "success";
    let mvName = "0";

    if (materiovigilance !== undefined && materiovigilance !== "") {
      const materiovigilanceValue = parseInt(materiovigilance.materiovigilance);
      const materiovigilanceMapping = {
        1: { className: "danger", name: materiovigilanceValue },
        2: { className: "info", name: materiovigilanceValue },
        3: { className: "warning", name: materiovigilanceValue },
      };

      if (materiovigilanceMapping[materiovigilanceValue]) {
        const mapping = materiovigilanceMapping[materiovigilanceValue];
        mvClassName = mapping.className;
        mvName = mapping.name;
      }
    }

    const imgSrc =
      APIUrl.getMaterialTypeImg +
      materialType._id +
      "/" +
      materialType.name +
      "/" +
      Math.random() +
      "?token=" +
      APIUrl.jwtToken;

    return (
      <>
        {this.props.isMaintenance && (
          <Card className="mb-4">
            <Card.Header>
              <strong>
                <FormattedMessage id="Intervention" />
              </strong>
            </Card.Header>
            <Card.Body>
              <div className="col-md-12 mb-1">
                <strong>
                  <FormattedMessage id="Date" />
                </strong>{" "}
                : {this.props.maintenanceDate}
              </div>
              <div className="col-md-12 mb-1">
                <strong>
                  <FormattedMessage id="Technician" />
                </strong>{" "}
                : {this.props.user.first_name}{" "}
                {this.props.user.name.toUpperCase()}
              </div>
              {estabRep && (
                <div className="col-md-12 mb-1">
                  <strong>
                    <FormattedMessage id="Estab.Rep" />
                  </strong>{" "}
                  : {estabRep.first_name} {estabRep.name.toUpperCase()}
                </div>
              )}
              <div className="col-md-12 mb-1">
                <strong>
                  <FormattedMessage id="Type" />
                </strong>{" "}
                : <FormattedMessage id={"Maintenance"} />
              </div>
            </Card.Body>
          </Card>
        )}

        <Card className="mb-4">
          <Card.Header>
            <strong>
              <FormattedMessage id="Equipment" />
            </strong>
          </Card.Header>
          <Card.Body>
            <table
              className="table mb-3 table-borderless"
              style={{ width: "100%" }}
            >
              <tbody>
                <tr>
                  {materialType.file && (
                    <td>
                      <img
                        id="img-equip"
                        src={imgSrc}
                        alt="material_type_img"
                        height="150"
                        width="150"
                      />
                    </td>
                  )}
                  <td>
                    <div>
                      <div className="col-md-12 mb-1">
                        <strong>
                          <FormattedMessage id="Brand" />
                        </strong>{" "}
                        : {brandName}
                      </div>
                      <div className="col-md-12 mb-1">
                        <strong>
                          <FormattedMessage id="Model" />
                        </strong>{" "}
                        : {modelName}
                      </div>
                      <div className="col-md-12 mb-1">
                        <strong>
                          <FormattedMessage id="Reference" />
                        </strong>{" "}
                        :{" "}
                        <Badge variant="info">
                          {this.props.equipment.internal_ref}
                        </Badge>
                      </div>
                      <div className="col-md-12 mb-1">
                        <strong>
                          <FormattedMessage id="Room" />
                        </strong>{" "}
                        : {this.props.equipment.room}{" "}
                        {this.props.equipment.floor && (
                          <>
                            (<FormattedMessage id="Floor" />{" "}
                            {this.props.equipment.floor})
                          </>
                        )}
                      </div>
                      {resident && (
                        <div className="col-md-12 mb-1">
                          <strong>
                            <FormattedMessage id="Resident" />
                          </strong>{" "}
                          :{" "}
                          {`${
                            resident.first_name
                          } ${resident.name.toUpperCase()}`}
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className="col-md-12 mb-1">
                        <strong>
                          <FormattedMessage id="Warranty" />
                        </strong>{" "}
                        :{" "}
                        {
                          <FormattedMessage
                            id={this.props.equipment.warranty ? "Yes" : "No"}
                          />
                        }
                      </div>
                      <div className="col-md-12 mb-1">
                        <strong>
                          <FormattedMessage id="Owner" />
                        </strong>{" "}
                        :{" "}
                        {
                          <FormattedMessage
                            id={"EqOwner." + this.props.equipment.owner}
                          />
                        }
                      </div>
                      <div className="col-md-12 mb-1">
                        <strong>
                          <FormattedMessage id="State" />
                        </strong>{" "}
                        :{" "}
                        {
                          <FormattedMessage
                            id={"EqState." + this.props.equipment.state}
                          />
                        }
                      </div>
                      <div className="col-md-12 mb-1">
                        <strong>
                          <FormattedMessage id="Materiovigilance" />
                        </strong>{" "}
                        :{" "}
                        {
                          <Badge variant={mvClassName}>
                            <FormattedMessage
                              id={"EqMateriovigilance." + mvName}
                            />
                          </Badge>
                        }
                      </div>
                      {this.props.equipment.purchase_date && (
                        <div className="col-md-12 mb-1">
                          <strong>
                            <FormattedMessage id="Purchase.Date" />
                          </strong>{" "}
                          :{" "}
                          {DateUtil.toDate(this.props.equipment.purchase_date)}
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Card.Body>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    residents: state.residents,
    brands: state.brands,
    models: state.models,
    materialsTypes: state.materialsTypes,
    materiovigilance: state.materiovigilance,
    clients: state.clients,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentOverview);
