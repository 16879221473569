import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import DateUtil from "../../../util/DateUtil";
import RequestStatus from "../../../enums/RequestStatus";
import RequestTypes from "../../../enums/RequestTypes";
import {
  updateRequest,
  deleteRequest,
} from "../../../actions/requests/requests";
import ModalManager from "../../sub/modals/ModalManager";
import {
  getEquipment,
  updateEquipmentAdmin,
} from "../../../actions/equipments/equipments";
import EqState from "../../../enums/EqState";
import ActionMenu from "../../sub/ActionMenu";

class RequestRowAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newEquipmentState: "" + EqState.GOOD, // Converted to string
    };
  }

  updateParentState(data) {
    this.props.updateParentState(data);
  }

  openRequestModalAdmin(request) {
    this.props.openRequestModalAdmin(request);
  }

  deleteRequestAdmin(request) {
    const deleteCbk = () =>
      this.props.onDeleteRequest({ requestId: request._id });

    this.updateParentState({
      modal: (
        <ModalManager
          showModal={true}
          title={<FormattedMessage id={"Confirm"} />}
          content={<FormattedMessage id={"Request.Remove.Confirmation"} />}
          context="danger"
          successCallback={deleteCbk}
          closeModal={() => this.closeModal()}
          modalType="confirmation"
        />
      ),
    });
  }

  closeModal() {
    this.props.closeModal();
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  displayConfirmModal(equipmentId, data, abortCallback) {
    this.props.onGetEquipment(equipmentId, (equipment) => {
      let eqStateNode = Object.values(EqState).map((option) => {
        return (
          <option key={option} value={option}>
            {this.props.intl.formatMessage({ id: "EqState." + option })}
          </option>
        );
      });
      let modalTitle = <FormattedMessage id="Request.Intervention.Close" />;
      let modalContent = (
        <>
          <p className="card-text ">
            <FormattedMessage
              id="Update.Equipment.State.P1"
              values={{ equipmentRef: equipment.internal_ref }}
            />
          </p>
          <p className="card-text ">
            <FormattedMessage id="Update.Equipment.State.P2" />
          </p>
          <select
            id="equipment-state"
            className={"form-control mt-3"}
            onChange={(e) => this.onChange("newEquipmentState", e.target.value)}
            defaultValue={this.state.newEquipmentState}
          >
            {eqStateNode}
          </select>
        </>
      );

      let successCallbackOfSuccessCallback = () =>
        this.props.onUpdateRequest(data);
      let successCallback = () =>
        this.updateEquipmentState(
          equipmentId,
          this.state.newEquipmentState,
          successCallbackOfSuccessCallback,
        );

      this.props.openConfModal(
        modalTitle,
        modalContent,
        successCallback,
        abortCallback,
      );
    });
  }

  // Check the status of the request set in the select element.
  // If status === DONE, we have to display a confirmation modal to define the state of the DM when closing the request.
  // Else, we just update the request status in database
  updateRequest(target, request) {
    let data = {
      updatedValue: target.value,
      updatedField: "status",
      requestId: request._id,
    };

    if (
      parseInt(request.request_type) === parseInt(RequestTypes.INTERVENTION) &&
      parseInt(target.value) === parseInt(RequestStatus.DONE)
    ) {
      let abortCallback = () => {
        target.value = this.props.request.status;
      };

      this.displayConfirmModal(request.equipment_id, data, abortCallback);
    } else {
      this.props.onUpdateRequest(data);
    }
  }

  updateEquipmentState(equipmentId, value, successCallback) {
    let data = {
      establishmentId: this.props.establishmentId,
      equipmentId: equipmentId,
      updatedField: "state",
      updatedValue: value,
    };

    this.props.onUpdateEquipmentAdmin(
      this.props.establishmentId,
      data,
      successCallback,
    );
  }

  render() {
    let statusNode = Object.values(RequestStatus).map((status) => {
      return (
        <option key={status} value={status}>
          {this.props.intl.formatMessage({ id: "Request.Status." + status })}
        </option>
      );
    });

    let statusClassName = "light";

    switch (true) {
      case parseInt(this.props.request.status) === RequestStatus.NEW:
        statusClassName = "danger";
        break;
      case parseInt(this.props.request.status) === RequestStatus.PENDING:
        statusClassName = "warning";
        break;
      case parseInt(this.props.request.status) === RequestStatus.DONE:
        statusClassName = "success";
        break;
      case parseInt(this.props.request.status) === RequestStatus.CANCEL:
        statusClassName = "info";
        break;
      default:
        break;
    }

    let menuItems = [];

    menuItems.push(
      // edit establishment
      {
        icon:
          this.props.request.status === RequestStatus.NEW ||
          this.props.request.status === RequestStatus.PENDING
            ? "pen-to-square"
            : "eye",
        action: (e) => this.openRequestModalAdmin(this.props.request),
        text: (
          <FormattedMessage
            id={
              this.props.request.status === RequestStatus.NEW ||
              this.props.request.status === RequestStatus.PENDING
                ? "Modify"
                : "Consult"
            }
          />
        ),
      },
      // show users
      {
        icon: "trash",
        action: (e) => this.deleteRequestAdmin(this.props.request),
        text: <FormattedMessage id="Delete" />,
      },
    );

    let menuAction = <ActionMenu items={menuItems} />;

    return (
      <tr key={this.props.key} className="d-flex">
        <td className="col">
          {this.props.clientName
            ? this.props.clientName
            : this.props.establishment}
        </td>
        {this.props.includeType && (
          <td className="col">
            <FormattedMessage
              id={
                this.props.request.type_of_renewal === undefined
                  ? "Request.Type." + this.props.request.request_type
                  : this.props.request.type_of_renewal === 0
                    ? "Request.Type.1"
                    : this.props.request.type_of_renewal === 1 &&
                      "Request.Type.3"
              }
            />
          </td>
        )}
        <td className="col">{this.props.materialType}</td>
        <td className="col">{this.props.request.precisions}</td>
        <td className="col">
          {DateUtil.toDate(this.props.request.creation_date)}
        </td>
        <td className={!this.props.limit ? "col col-2" : "col"}>
          {this.props.request.status !== RequestStatus.DONE ? (
            <select
              className={"form-control alert-" + statusClassName}
              onChange={(e) => this.updateRequest(e.target, this.props.request)}
              defaultValue={this.props.request.status}
            >
              {statusNode}
            </select>
          ) : (
            <div className={"form-control alert-" + statusClassName}>
              &nbsp;
              {this.props.intl.formatMessage({
                id: "Request.Status." + this.props.request.status,
              })}
              &nbsp;
            </div>
          )}
        </td>
        {(!this.props.limit && this.props.equipment
          ? this.props.equipment.establishment_id
          : true) && <td className="col col-1 text-center">{menuAction}</td>}
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    equipments: state.equipments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetEquipment: (equipmentId, successCallback) =>
      dispatch(getEquipment(equipmentId, successCallback)),
    onUpdateRequest: (data) => dispatch(updateRequest(data)),
    onDeleteRequest: (requestId) => dispatch(deleteRequest(requestId)),
    onUpdateEquipmentAdmin: (establishmentId, data, successCallback) =>
      dispatch(updateEquipmentAdmin(establishmentId, data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(RequestRowAdmin));
