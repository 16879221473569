import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import MaterialsTypesAdmin from "./materialsTypes/MaterialsTypesAdmin";
import ModelsAdmin from "./models/ModelsAdmin";
import BrandsAdmin from "./brands/BrandsAdmin";

class MenuSettingsEquipmentsAdmin extends React.Component {
  render() {
    return (
      <Tabs defaultActiveKey="materialsTypes" id="configurationTabs">
        <Tab
          eventKey="materialsTypes"
          title={this.props.intl.formatMessage({ id: "Types.Dm" })}
        >
          <MaterialsTypesAdmin />
        </Tab>
        <Tab
          eventKey="brands"
          title={this.props.intl.formatMessage({ id: "Brands" })}
        >
          <BrandsAdmin />
        </Tab>
        <Tab
          eventKey="models"
          title={this.props.intl.formatMessage({ id: "Models" })}
        >
          <ModelsAdmin />
        </Tab>
      </Tabs>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(MenuSettingsEquipmentsAdmin));
