import APIUrl from "../../APIUrl";
import axios from "axios";
import { getAllEquipments, getEquipments } from "../equipments/equipments";

export const GET_RESIDENTS = "GET_RESIDENTS";

function getResidentsAction(residents) {
  return { type: GET_RESIDENTS, residents: residents };
}

export const getResidents = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getResidents)
      .then(function (response) {
        dispatch(getResidentsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const checkIfResidentExists = function (
  resident,
  existsCallback,
  noExistsCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.checkIfResidentExists, resident)
      .then(function (response) {
        if (response.status === 200) existsCallback();
        else noExistsCallback();
      });
  };
};

export const addResident = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addResident, data)
      .then(function (response) {
        dispatch(getResidents());
        successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
export const updateResident = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateResident, data)
      .then(function (response) {
        dispatch(getResidents());
        successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteResident = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteResident, data)
      .then(function (response) {
        dispatch(getResidents());
        dispatch(getEquipments());
        successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

// =======================================================================
// =============================== ADMIN =================================
// =======================================================================

export const getAllResidents = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getAllResidents)
      .then(function (response) {
        dispatch(getResidentsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addResidentAdmin = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addResidentAdmin, data)
      .then(function (response) {
        dispatch(getAllResidents());
        successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const checkIfResidentExistsAdmin = function (
  resident,
  existsCallback,
  noExistsCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.checkIfResidentExistsAdmin, resident)
      .then(function (response) {
        if (response.status === 200) existsCallback();
        else noExistsCallback();
      });
  };
};

export const deleteResidentAdmin = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteResidentAdmin, data)
      .then(function (response) {
        dispatch(getAllResidents());
        dispatch(getAllEquipments());
        successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateResidentAdmin = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateResidentAdmin, data)
      .then(function (response) {
        dispatch(getAllResidents());
        successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
