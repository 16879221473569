import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import ResidentsModal from "../residents/ResidentsModal";
import Equipments from "./Equipments";
import FleetSummaryModal from "./FleetSummaryModal";
import { getMaintenance } from "../../actions/maintenances/maintenances";
import EquipmentsRequest from "./equipmentRequest/EquipmentsRequest";
import TableToolbar from "../sub/bootstrap/TableToolbar";
import ActionMenu from "../sub/ActionMenu";
import { Modal } from "react-bootstrap";

class Fleet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };

    this.props.onGetMaintenance(this.props.user.establishment_id);
  }

  openResidentsModal() {
    this.setState({
      modal: <ResidentsModal close={() => this.closeModal()} />,
    });
  }

  openSummaryModal(establishmentId) {
    this.setState({
      modal: (
        <FleetSummaryModal
          close={() => this.closeModal()}
          equipments={this.props.equipments}
        />
      ),
    });
  }
  openEquipmentsRequestModal(establishmentId) {
    this.setState({
      modal: (
        <Modal
          show={true}
          onHide={() => this.closeModal()}
          backdrop={"static"}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="Equipments.Request" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <EquipmentsRequest
              close={() => this.closeModal()}
              establishmentId={establishmentId}
            />
          </Modal.Body>
        </Modal>
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  render() {
    let content = null;

    // No equipment yet
    if (this.props.equipments.length === 0) {
      content = (
        <div className="alert alert-secondary" role="alert">
          <FormattedMessage id="Empty.Fleet.Client" />
        </div>
      );
    } else {
      // Equipment in DB
      var MaintenancesToSignArray = [];
      this.props.maintenances.filter((items) => {
        for (let each of this.props.equipments) {
          if (
            items.equipment_id === each._id &&
            items.rep_signature === undefined &&
            items.tech_signature === undefined
          ) {
            MaintenancesToSignArray.push(items.equipment_id);
          }
        }
        return MaintenancesToSignArray;
      });

      content = (
        <Equipments
          equipments={this.props.equipments}
          maintenancesToSign={MaintenancesToSignArray}
          establishmentId={this.props.user.establishment_id}
        />
      );
    }

    let menuItems = [];

    menuItems.push({
      id: null,
      icon: "rectangle-list",
      action: (e) => this.openSummaryModal(this.state.establishmentId),
      text: <FormattedMessage id="Park.Summary" />,
    });
    menuItems.push({
      id: null,
      icon: "person-cane",
      action: (e) => this.openResidentsModal(),
      text: <FormattedMessage id="Residents.Management" />,
    });
    menuItems.push({
      id: null,
      icon: "truck-arrow-right",
      action: (e) =>
        this.openEquipmentsRequestModal(this.props.user.establishment_id),
      text: <FormattedMessage id="Equipments.Request" />,
    });

    let menuAction = (
      <ActionMenu items={menuItems} size="2xl" className="ml-auto mr-2" />
    );

    return (
      <div>
        <div className="text-right" role="alert">
          <TableToolbar>{menuAction}</TableToolbar>
        </div>
        {content}
        {this.state.modal}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    residents: state.residents,
    equipments: state.equipments,
    brands: state.brands,
    models: state.models,
    maintenances: state.maintenances,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetMaintenance: (establishmentId) =>
      dispatch(getMaintenance(establishmentId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Fleet);
