import React from "react";
import { connect } from "react-redux";
import PDFUtil from "../../../util/PDFUtil";
import DateUtil from "../../../util/DateUtil";
import { FormattedMessage, injectIntl } from "react-intl";
import OrderProducts from "../OrderProducts";
import OrderPDF from "../OrderPDF";
import PDFViewer from "../../pdf/PDFViewer";
import OrderStatus from "../../../enums/OrderStatus";
import {
  deleteOrderAdmin,
  updateAdmin,
  duplicateAdmin,
  syncOrderMustAdmin,
  syncOrderLomacoAdmin,
} from "../../../actions/orders/orders";
import Icon from "../../sub/Icon";
import ActionMenu from "../../sub/ActionMenu";
import Maths from "../../../util/Maths";
import { getOldProducts } from "../../../actions/products/oldProducts";
import { getListProductsAdmin } from "../../../actions/products/products";
import { Badge, Col, Row } from "react-bootstrap";
import { CrmProviders } from "../../../enums/crmProviders";

class OrderRowAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      displayPDF: false,
      disableExport: false,
      disabledDuplicat: false,
      crmSyncIcon: false, // Store FontAwesome class to make icons spin on sync (if CRM enabled)
      crmSyncStyle: "",
      modal: null,
    };

    // List of products we have already attempted to fetch from BE
    this.alreadySent = {};

    // Enabled to know if the order contains outdated products or not
    this.outdated = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.order !== this.props.order) {
      this.forceUpdate();
    }
  }

  collapse() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  checkProductsExistById(productsId) {
    for (let i = 0; i < this.props.productsForCrm.length; i++) {
      if (this.props.productsForCrm[i]._id === productsId) {
        return false;
      }
    }
    return true;
  }

  deleteOrderAdmin(e, orderId) {
    let data = { orderId: orderId };
    this.props.onDeleteOrderAdmin(data);
  }

  syncOrderWithCRM(crmSoftware, orderId) {
    const failureCallback = (data) => {
      this.setState({ crmSyncIcon: false });

      let modalTitle = <FormattedMessage id="API.CRM.Sync.Error" />;
      let modalContent = (
        <div>
          <p className="alert alert-danger text-justify">
            <FormattedMessage
              id="API.CRM.Sync.Error.Message"
              values={{ crmSoftware: crmSoftware }}
            />
          </p>
          <div className="alert alert-light text-break">
            <p>
              <FormattedMessage id="Error.Detail" />:
            </p>
            <code>{JSON.stringify(data)}</code>
          </div>
        </div>
      );

      const successCallback = (e) => {};

      this.props.openErrorModal(modalTitle, modalContent, successCallback);
    };

    // let data = { orderId: orderId };
    let successCallback = (crmResponse) => {
      this.setState({ crmSyncIcon: false });

      // Check if an error occured while syncing with CRM
      if (crmSoftware === CrmProviders.MUST.software) {
        const crmError = crmResponse.data.SaveDossier3CEResult.Erreur;

        if (crmError.HasError) {
          let modalTitle = <FormattedMessage id="API.CRM.Sync.Error" />;
          let modalContent = (
            <div>
              <p className="alert alert-danger text-justify">
                <FormattedMessage
                  id="API.CRM.Sync.Error.Message"
                  values={{ crmSoftware: crmSoftware }}
                />
              </p>
              <div className="alert alert-light text-break">
                <p>
                  <FormattedMessage id="Error.Detail" />:
                </p>
                <code>{JSON.stringify(crmError)}</code>
              </div>
            </div>
          );

          let successCallback = (e) => {};

          this.props.openErrorModal(modalTitle, modalContent, successCallback);
        }
      } else if (crmSoftware === CrmProviders.LOMACO.software) {
        if (crmResponse.status === 200) {
          let modalTitle = (
            <FormattedMessage id="API.CRM.Already.Synchronized.With" />
          );
          let modalContent = (
            <div>
              <p className="alert alert-danger text-justify">
                <FormattedMessage
                  id="API.CRM.Sync.Order.Success.Content"
                  values={{ crmSoftware: crmSoftware }}
                />
              </p>
            </div>
          );

          const successCallback = (e) => {};

          this.props.openSuccessModal(
            modalTitle,
            modalContent,
            successCallback,
          );
        } else {
          let modalTitle = <FormattedMessage id="API.CRM.Sync.Error" />;
          let modalContent = (
            <div>
              <p className="alert alert-danger text-justify">
                <FormattedMessage
                  id="API.CRM.Sync.Error.Message"
                  values={{ crmSoftware: crmSoftware }}
                />
              </p>
              <div className="alert alert-light text-break">
                <p>
                  <FormattedMessage
                    id="API.CRM.Sync.Error.Message"
                    values={{ crmSoftware: crmSoftware }}
                  />
                </p>
              </div>
            </div>
          );

          const successCallback = (e) => {};

          this.props.openErrorModal(modalTitle, modalContent, successCallback);
        }
      } else {
        let modalTitle = <FormattedMessage id="API.CRM.Sync.Error" />;
        let modalContent = (
          <div>
            <p className="alert alert-danger text-justify">
              <FormattedMessage id="API.CRM.Unknown.Title" />
            </p>
            <div className="alert alert-light text-break">
              <p>
                <FormattedMessage id="API.CRM.Unknown.Content" />
              </p>
            </div>
          </div>
        );

        const successCallback = (e) => {};

        this.props.openErrorModal(modalTitle, modalContent, successCallback);
      }
    };
    if (this.props.company.crm.software === CrmProviders.LOMACO.software) {
      this.props.onSyncOrderLomacoAdmin({ orderId: orderId }, successCallback);
    } else if (this.props.company.crm.software === CrmProviders.MUST.software) {
      this.props.onSyncOrderMustAdmin(
        { orderId: orderId },
        successCallback,
        failureCallback,
      );
    }
  }

  togglePDF(pdfElemId, pdfFileName) {
    if (this.state.disableExport) return;
    if (process.env.REACT_APP_PDF_OVERVIEW === "true") {
      // Possibility n°1 (dev env) : display preview of PDF (without header, footer, pages etc.)
      this.setState({ displayPDF: !this.state.displayPDF });
    } else {
      // Possibility n°2 (prod env) : directly save to PDF
      this.setState({ disableExport: true });
      let successCallback = () => this.setState({ disableExport: false });
      PDFUtil.toPDF(
        this.props.generalSettings,
        this.props.company,
        pdfElemId,
        pdfFileName,
        "/css/orderPdf.css",
        successCallback,
      );
    }
  }

  duplicatOrderAdmin(orderId) {
    if (this.state.disabledDuplicat) return;

    this.setState({ disabledDuplicat: true });

    let successCallback = () => this.setState({ disabledDuplicat: false });

    this.props.onDuplicateAdmin(orderId, successCallback);
  }

  updateStatus(e, order) {
    let data = {
      updatedValue: e.target.value,
      updatedField: "status",
      orderId: order._id,
    };

    this.props.onUpdateAdmin(data);
  }

  // Check if the mercurial that relates to the current order is synced with a crm or not
  isMercurialSynced(orderProducts) {
    let synced = false;
    if (orderProducts.length === 0) return false;
    let orderProductSampleId = orderProducts[0]["id_product"];
    let iterableProducts =
      this.props.productsForCrm.length === 0
        ? this.props.oldProducts
        : this.props.productsForCrm;
    for (let currentProduct of iterableProducts) {
      if (currentProduct._id === orderProductSampleId) {
        for (let currentMercurial of this.props.mercurials) {
          if (currentProduct.mercurial_id === currentMercurial._id) {
            synced = currentMercurial.synced_with_crm;
          }
        }
      }
    }
    return synced;
  }

  // Display a sync button with crm (if linked)
  syncButton(crmSoftware) {
    let resquestedProducts = [];
    for (let y = 0; y < this.props.order.products.length; y++) {
      if (
        this.checkProductsExistById(this.props.order.products[y].id_product) &&
        !resquestedProducts.includes(this.props.order.products[y].id_product)
      ) {
        resquestedProducts.push(this.props.order.products[y].id_product);
      }
    }
    if (resquestedProducts.length !== 0) {
      this.props.onGetProductsAdmin(resquestedProducts);
    }
    let syncMessage = "API.CRM.Synchronize.With";
    let disabled = true;
    let btnOnClick = () => {
      return false;
    };

    let modalTitle = <FormattedMessage id="Confirmation.Required" />;
    let modalContent = (
      <div>
        <p>
          <FormattedMessage
            id="API.CRM.Synchronize.Message"
            values={{ crmSoftware: crmSoftware }}
          />
        </p>
        <p>
          <FormattedMessage id="Please.Confirm" />
        </p>
      </div>
    );
    let successCallback = (e) => {
      // Start sync icon animation
      this.setState({
        crmSyncIcon: "refresh",
        crmSyncStyle: "fa-spin icon-big text-info",
      });
      this.syncOrderWithCRM(crmSoftware, this.props.order._id);
    };

    if (
      this.props.establishment &&
      this.props.establishment.crm_id &&
      this.isMercurialSynced(this.props.order.products) &&
      this.props.order.synced_with_crm === false
    ) {
      btnOnClick = (e) => {
        this.props.openConfModal(modalTitle, modalContent, successCallback);
      };
      disabled = false;
    } else if (
      !this.props.establishment.crm_id &&
      this.isMercurialSynced(this.props.order.products) &&
      this.props.order.synced_with_crm === true
    ) {
      syncMessage = "API.CRM.Already.Synchronized.With";
      disabled = true;
    } else if (!this.props.establishment.crm_id) {
      syncMessage = "API.CRM.Sync.Unavailable";
      disabled = true;
    } else {
      if (this.isMercurialSynced(this.props.order.products)) {
        syncMessage = "API.CRM.Already.Synchronized.With";
        disabled = true;
      } else {
        syncMessage = "API.CRM.Sync.Unavailable";
        disabled = true;
      }
    }

    // Sync orders
    return {
      id: "sync" + this.props.order._id,
      icon: "refresh",
      disabled: disabled,
      action: btnOnClick,
      text: (
        <FormattedMessage
          id={syncMessage}
          values={{ crmSoftware: crmSoftware }}
        />
      ),
    };
  }

  syncIcon(crmSoftware) {
    if (
      this.isMercurialSynced(this.props.order.products) &&
      this.props.order.synced_with_crm === false
    ) {
      return (
        <Icon
          icon={
            this.state.crmSyncIcon ? this.state.crmSyncIcon : "circle-xmark"
          }
          variant="far"
          size="xl"
          className={
            this.state.crmSyncIcon ? this.state.crmSyncStyle : "text-danger"
          }
          hover={
            <FormattedMessage
              id="API.CRM.Sync.Nok"
              values={{ crmSoftware: crmSoftware }}
            />
          }
        />
      );
    } else {
      if (this.isMercurialSynced(this.props.order.products)) {
        return (
          <Icon
            icon={
              this.state.crmSyncIcon ? this.state.crmSyncIcon : "circle-check"
            }
            size="xl"
            className={
              this.state.crmSyncIcon ? this.state.crmSyncStyle : "text-success"
            }
            hover={
              <FormattedMessage
                id="API.CRM.Sync.Ok"
                values={{ crmSoftware: crmSoftware }}
              />
            }
          />
        );
      } else {
        return (
          <Icon
            icon={
              this.state.crmSyncIcon ? this.state.crmSyncIcon : "circle-xmark"
            }
            variant="far"
            size="xl"
            className={
              this.state.crmSyncIcon ? this.state.crmSyncStyle : "text-muted"
            }
            hover={
              <FormattedMessage
                id="API.CRM.Sync.Unavailable"
                values={{ crmSoftware: crmSoftware }}
              />
            }
          />
        );
      }
    }
  }

  defineOrderStatusOption(orderHasProducts) {
    let orderStatusClassName = "light";

    switch (parseInt(this.props.order.status)) {
      case OrderStatus.NEW:
        orderStatusClassName = "danger";
        break;
      case OrderStatus.PENDING:
        orderStatusClassName = "info";
        break;
      case OrderStatus.SENT:
        orderStatusClassName = "success";
        break;
      case OrderStatus.BLOCKED:
        orderStatusClassName = "warning";
        break;
      case OrderStatus.CANCELED:
        orderStatusClassName = "secondary";
        break;
      case OrderStatus.SENDING:
        orderStatusClassName = "info";
        break;
      default:
        break;
    }

    const statusToBeDisplayed = Object.assign({}, OrderStatus);

    /**
     * Do not display the "to be validated" option in search fiter select (useless)
     */
    delete statusToBeDisplayed.TBV;
    const statusNode = Object.values(statusToBeDisplayed).map((option) => {
      return (
        <option key={option} value={option}>
          {this.props.intl.formatMessage({ id: "Order.Status." + option })}
        </option>
      );
    });

    const selectStatus = (
      <select
        className={
          !orderHasProducts
            ? "form-control"
            : "form-control alert-" + orderStatusClassName
        }
        onChange={(e) => this.updateStatus(e, this.props.order)}
        defaultValue={this.props.order.status}
        disabled={!orderHasProducts}
      >
        {statusNode}
      </select>
    );
    const syncStatus = (
      <div
        className={"form-control alert-" + orderStatusClassName}
        defaultValue={this.props.order.status}
        disabled={!orderHasProducts}
      >
        <Row className="d-flex items-align-center">
          <Col sm={9} className="pr-0">
            {this.props.intl.formatMessage({
              id: "Order.Status." + this.props.order.status,
            })}
          </Col>
          <Col sm={3} className="text-right pr-1 pl-0">
            <small>
              <Badge>Auto</Badge>
            </small>
          </Col>
        </Row>
      </div>
    );

    let output;

    switch (true) {
      case this.isMercurialSynced(this.props.order.products) &&
        this.props.order.synced_with_crm &&
        (this.props.company.crm?.options?.must_allow_sync_order_status ||
          this.props.company.crm?.options?.lomaco?.allow_sync_order_status):
        output = syncStatus;
        break;
      case !(
        this.props.company.crm?.options?.must_allow_sync_order_status ||
        this.props.company.crm?.options?.lomaco?.allow_sync_order_status
      ):
      default:
        output = selectStatus;
        break;
    }

    return output;
  }

  render() {
    let isUrgent = this.props.order.urgent_costs > 0;
    let orderHasProducts = this.props.order.products.length > 0;

    const orderDate = DateUtil.getDateWithOffset(
      this.props.order.date,
      0,
      true,
    );
    const shippingDate = DateUtil.getDateWithOffset(
      this.props.order.date,
      this.props.order.shipping_delay,
    );

    // 'Delete order' modal setup
    let modalTitle = <FormattedMessage id="Confirm" />;
    let modalContent = <FormattedMessage id="Order.Remove.Confirmation" />;
    let successCallback = (e) => this.deleteOrderAdmin(e, this.props.order._id);

    let pdfElemId = "order-pdf-" + this.props.order._id;
    let pdfFileName = "order-" + this.props.order._id;
    let pdfOrder = null;

    // https://stackoverflow.com/questions/37308719/react-component-wait-for-required-props-to-render
    // https://zaiste.net/posts/javascript-destructuring-assignment-default-values/
    const { enabled: crmEnabled = false, software: crmSoftware = null } = this
      .props.company.crm
      ? this.props.company.crm
      : {};

    if (orderHasProducts) {
      pdfOrder = (
        <OrderPDF
          id={pdfElemId}
          order={this.props.order}
          admin={this.props.admin}
          establishment={this.props.establishment}
          clientName={this.props.clientName}
        />
      );
    }

    let menuItems = [];

    menuItems.push(
      // Show/hide products
      {
        icon: this.state.collapsed ? "eye" : "eye-slash",
        action: () => this.collapse(),
        disabled: !orderHasProducts,
        text: this.state.collapsed ? (
          <FormattedMessage id="Display.Product.Order" />
        ) : (
          <FormattedMessage id="Hide.Product.Order" />
        ),
      },
      // Split orders
      orderHasProducts && {
        id: "split" + this.props.order._id,
        icon: "scissors",
        disabled:
          this.props.order.status === OrderStatus.SENT ||
          this.props.order.status === OrderStatus.CANCELED ||
          !orderHasProducts,
        action: () =>
          orderHasProducts && this.props.openSplitModal(this.props.order),
        text: <FormattedMessage id="Split.Order" />,
      },
      // Download PDF
      {
        id: "downloadPdf" + this.props.order._id,
        icon: "file-pdf",
        disabled: !orderHasProducts,
        action: () =>
          orderHasProducts && this.togglePDF(pdfElemId, pdfFileName),
        text: <FormattedMessage id="Download.PDF" />,
      },
      // Delete order
      {
        id: "delete" + this.props.order._id,
        icon: "trash",
        action: () =>
          this.props.openConfModal(modalTitle, modalContent, successCallback),
        text: <FormattedMessage id="Delete" />,
      },
      crmEnabled && this.syncButton(crmSoftware),
    );
    let menuVariant;
    let menuIcon;
    let menuHeader;

    switch (true) {
      case orderHasProducts && isUrgent:
        menuIcon = "bell";
        menuVariant = "warning";
        menuHeader = (
          <span className="text-danger">
            <Icon icon="exclamation-triangle" className="mr-2" />
            <FormattedMessage id="Order.Urgent" />
          </span>
        );
        break;
      /*
    case (this.props.order.status === OrderStatus.TBV && this.props.user.validator):
      menuIcon = "thumbs-up";
      menuVariant = "warning";
      menuHeader = <span className="text-danger"><Icon icon="exclamation-triangle" className="mr-2" /><FormattedMessage id="Waiting.Validation" /></span>;
      break;
      */
      default:
        break;
    }

    let menuAction = (
      <ActionMenu
        items={menuItems}
        variant={menuVariant}
        icon={menuIcon}
        header={menuHeader}
        processing={this.state.disableExport}
      />
    );

    return (
      <React.Fragment>
        <tr
          key={this.props.order._id}
          className={
            orderHasProducts
              ? "d-flex align-items-center"
              : "d-flex font-italic text-muted align-items-center"
          }
        >
          <td className="col col-2 align-middle">
            {this.props.establishment && this.props.establishment.name}
            <div className="d-md-none text-muted mt-2">
              <FormattedMessage id="Total" /> <FormattedMessage id="Incl.Tax" />
              &nbsp;: {Maths.round(this.props.order.total_ttc)}€
            </div>
          </td>
          <td className="col align-middle">{this.props.clientName}</td>
          <td className="col align-middle">
            {this.props.order.ref}
            {this.props.order.comment && this.props.comment !== "" && (
              <Icon
                icon="comments"
                size="xl"
                className="text-info ml-1"
                hover={
                  <>
                    <FormattedMessage id="Available.Comment" /> :<br />
                    {this.props.order.comment}
                  </>
                }
              />
            )}
          </td>
          <td className="col align-middle">{orderDate}</td>
          <td className="col align-middle">{shippingDate}</td>
          <td className="col align-middle">
            <div>{Maths.round(this.props.order.total_ht)}€</div>
          </td>
          <td className="col col-2 align-middle">
            {this.defineOrderStatusOption(orderHasProducts)}
          </td>
          {crmEnabled && (
            <td className="col col-1 text-center align-middle">
              {this.syncIcon(crmSoftware)}
            </td>
          )}
          {!this.props.limit && (
            <td className="col text-center align-middle">
              {menuAction}
              {
                <PDFViewer
                  open={this.state.displayPDF}
                  toggle={(pdfElemId, pdfFileName) => {
                    if (orderHasProducts)
                      this.togglePDF(pdfElemId, pdfFileName);
                  }}
                  template={pdfOrder}
                  fileName={pdfFileName}
                />
              }
            </td>
          )}
        </tr>

        {!this.state.collapsed && (
          <tr className="d-flex">
            <td colSpan="8" className="col p-1">
              {orderHasProducts && (
                <OrderProducts order={this.props.order} paginate={true} />
              )}
            </td>
          </tr>
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    oldProducts: state.oldProducts,
    generalSettings: state.generalSettings,
    company: state.company,
    ordersSettingsAdmin: state.ordersSettingsAdmin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteOrderAdmin: (data) => dispatch(deleteOrderAdmin(data)),
    onUpdateAdmin: (data) => dispatch(updateAdmin(data)),
    onDuplicateAdmin: (data, successCallback) =>
      dispatch(duplicateAdmin(data, successCallback)),
    onGetOldProducts: (data) => dispatch(getOldProducts(data)),
    onGetProductsAdmin: (data) => dispatch(getListProductsAdmin(data)),
    onSyncOrderLomacoAdmin: (data, successCallback) =>
      dispatch(syncOrderLomacoAdmin(data, successCallback)),
    onSyncOrderMustAdmin: (data, successCallback, failureCallback) =>
      dispatch(syncOrderMustAdmin(data, successCallback, failureCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(OrderRowAdmin));
