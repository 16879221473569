import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import Icon from "../../sub/Icon";

class MaintenanceImgPreview extends React.Component {
  render() {
    const { index, src, deleteImage, onClick } = this.props;

    return (
      <div className="p-2 pb-0" style={{ height: "150px", width: "150px" }}>
        <div className="position-absolute">
          <Button
            onClick={() => deleteImage(index)}
            variant="danger"
            className="btn-sm"
          >
            <Icon icon="trash" clickable />
          </Button>
        </div>
        <img
          className="img-thumbnail"
          style={{ width: "150px", height: "150px", border: "2px solid #f00" }}
          src={src}
          alt=""
          onClick={onClick}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(injectIntl(MaintenanceImgPreview)),
);
