import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import RequestTypes from "../../enums/RequestTypes";
import EstimateStatus from "../../enums/EstimateStatus";
import DateUtil from "../../util/DateUtil";
import StringUtil from "../../util/StringUtil";
import Util from "../../util/Util";
import EstimateModal from "./EstimateModal";
import Paginator from "../sub/Paginator";
import CustomLabel from "../sub/CustomLabel";
import Roles from "../../enums/Roles";
import EstimatesRow from "./EstimatesRow";
import TableToolbar from "../sub/bootstrap/TableToolbar";
import MenuButton from "../sub/bootstrap/MenuButton";

class Estimates extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      clientFilter: "",
      keywordFilter: "",
      dateFilter: "",
      statusFilter: "",
    };

    this.paginator = new Paginator(this);
  }

  openEstimateModal(request) {
    this.setState({
      modal: (
        <EstimateModal request={request} close={() => this.closeModal()} />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  countMaterial(requests) {
    var tot = 0;
    for (let request of requests)
      if (request.request_type === RequestTypes.NEW_MATERIAL) tot++;
    return tot;
  }

  areResultsFiltered = () => {
    return (
      !Util.emptyString(this.state.clientFilter) ||
      !Util.emptyString(this.state.keywordFilter) ||
      !Util.emptyString(this.state.dateFilter) ||
      !Util.emptyString(this.state.statusFilter)
    );
  };

  resetSearchFields() {
    this.setState({
      clientFilter: "",
      keywordFilter: "",
      dateFilter: "",
      statusFilter: "",
    });
  }

  updateChildState(data) {
    this.setState(data);
  }

  render() {
    var materialNbr = this.countMaterial(this.props.requests);

    let requests = this.props.requests.filter(
      (request) => request.request_type === RequestTypes.NEW_MATERIAL,
    );
    this.paginator.init(requests.length);

    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    var i = 0;
    let requestRowNode = requests.map((request) => {
      if (this.state.clientFilter && this.state.clientFilter !== "") {
        if (request.client_id !== this.state.clientFilter) return null;
      }

      if (this.state.keywordFilter && this.state.keywordFilter !== "") {
        if (request.precisions.indexOf(this.state.keywordFilter) === -1)
          return null;
      }

      if (this.state.dateFilter && this.state.dateFilter !== "") {
        if (
          DateUtil.toDate(request.creation_date) !==
          DateUtil.toDate(this.state.dateFilter)
        )
          return null;
      }

      if (this.state.statusFilter && this.state.statusFilter !== "") {
        if (parseInt(request.status) !== parseInt(this.state.statusFilter))
          return null;
      }

      if (this.props.limit && ++i > this.props.limit) return null;

      if (!this.paginator.keep()) return null;

      return (
        <EstimatesRow
          key={request._id}
          request={request}
          clientName={request.client_name}
          updateParentState={this.updateChildState.bind(this)}
          openEstimateModal={(request) => this.openEstimateModal(request)}
          limit={this.props.limit && true}
        />
      );
    });

    let clientNode = this.props.clients.map((client) => {
      return (
        <option key={client._id} value={client._id}>
          {client.name.toUpperCase() +
            " " +
            StringUtil.ucFirst(client.first_name)}
        </option>
      );
    });

    let statusFilterNode = Object.values(EstimateStatus).map((status) => {
      return (
        <option key={status} value={status}>
          {this.props.intl.formatMessage({ id: "Estimate.Status." + status })}
        </option>
      );
    });

    return (
      <React.Fragment>
        {materialNbr === 0 && (
          <TableToolbar>
            <div className="alert alert-info w-100">
              <FormattedMessage id="Empty.Requests" />
            </div>
            <button
              className="btn btn-info ml-auto"
              onClick={(e) => this.openEstimateModal(undefined)}
            >
              <FormattedMessage id="Create.Request" />
            </button>
          </TableToolbar>
        )}
        {materialNbr > 0 && (
          <React.Fragment>
            {!this.props.limit && (
              <TableToolbar>
                {(this.props.user.role === Roles.ADMIN ||
                  this.props.user.role === Roles.SALES_REP) && (
                  <>
                    <CustomLabel
                      label={this.props.intl.formatMessage({
                        id: "Client",
                      })}
                      htmlFor="search_client"
                      labelClassName="my-1 mr-2"
                    />
                    <select
                      id="search_client"
                      className="form-control mr-sm-3"
                      onChange={(e) => {
                        this.setState({ clientFilter: e.target.value });
                      }}
                      value={this.state.clientFilter}
                      disabled={disableFormInput}
                    >
                      <option value="">
                        {this.props.intl.formatMessage({ id: "All" })}
                      </option>
                      {clientNode}
                    </select>
                  </>
                )}

                {this.props.user.role === Roles.CLIENT && (
                  <>
                    <CustomLabel
                      label={this.props.intl.formatMessage({
                        id: "Keyword",
                      })}
                      htmlFor="search_keyword"
                      labelClassName="my-1 mr-2"
                    />
                    <input
                      id="search_keyword"
                      className="form-control mr-sm-3"
                      onChange={(e) => {
                        this.setState({
                          keywordFilter: e.target.value,
                        });
                      }}
                      disabled={disableFormInput}
                      value={this.state.keywordFilter}
                    />
                  </>
                )}
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Date" })}
                  htmlFor="search_date"
                  labelClassName="my-1 mr-2"
                />
                <input
                  id="search_date"
                  className="form-control mr-sm-3"
                  type="date"
                  onChange={(e) => {
                    this.setState({ dateFilter: e.target.value });
                  }}
                  value={this.state.dateFilter}
                  disabled={disableFormInput}
                />
                <CustomLabel
                  label={this.props.intl.formatMessage({
                    id: "Status",
                  })}
                  htmlFor="search_status"
                  labelClassName="my-1 mr-2"
                />
                <select
                  id="search_status"
                  className="form-control mr-sm-3"
                  onChange={(e) => {
                    this.setState({ statusFilter: e.target.value });
                  }}
                  value={this.state.statusFilter}
                  disabled={disableFormInput}
                >
                  <option value="">
                    {this.props.intl.formatMessage({ id: "All" })}
                  </option>
                  {statusFilterNode}
                </select>
                <MenuButton
                  onClick={() => this.resetSearchFields()}
                  hover={
                    this.areResultsFiltered() &&
                    !disableFormInput && <FormattedMessage id="Remove.Filter" />
                  }
                  variant={
                    this.areResultsFiltered() ? "warning" : "outline-secondary"
                  }
                  icon="filter"
                  disabled={!this.areResultsFiltered() || disableFormInput}
                />
                <button
                  className="btn btn-info ml-auto"
                  onClick={(e) => this.openEstimateModal(undefined)}
                >
                  <FormattedMessage id="Create.Request" />
                </button>
              </TableToolbar>
            )}

            <table className="table tablee4coll">
              <thead>
                <tr className="d-flex">
                  <th scope="col" className="col col-2">
                    <FormattedMessage
                      id={
                        this.props.user.role === Roles.CLIENT
                          ? "Buyer"
                          : "Client"
                      }
                    />
                  </th>
                  <th scope="col" className="col">
                    <FormattedMessage id="Estimate.Request" />
                  </th>
                  <th
                    scope="col"
                    className={!this.props.limit ? "col col-2" : "col col-3"}
                  >
                    <FormattedMessage id="Date" />
                  </th>
                  <th
                    scope="col"
                    className={!this.props.limit ? "col col-2" : "col col-2"}
                  >
                    <FormattedMessage id="Status" />
                  </th>
                  {!this.props.limit && (
                    <th scope="col" className="col col-1 text-center">
                      <FormattedMessage id="Actions" />
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>{requestRowNode}</tbody>
            </table>

            {!this.props.limit && this.paginator.render()}
          </React.Fragment>
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requests: state.requests,
    clients: state.clients,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Estimates));
