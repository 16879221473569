import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { deleteMateriovigilanceEquipement } from "../../../../actions/settings/materiovigilance/materiovigilance";
import DateUtil from "../../../../util/DateUtil";
import APIUrl from "../../../../APIUrl";
import ActionMenu from "../../../sub/ActionMenu";
class MateriovigilanceAdminRow extends React.Component {
  deleteMateriovigilance(materiovigilanceId) {
    var data = { materiovigilanceId: materiovigilanceId };
    this.props.onDeleteMateriovigilance(data);
  }

  render() {
    // 'Delete material type' modal setup
    var modalTitle = (
      <span className="text-red">
        <FormattedMessage id="Delete.Materiovigilance" />
      </span>
    );
    var modalContent = (
      <p className="text-justify">
        <FormattedMessage id="Materiovigilance.Delete.Warning.Content" />
      </p>
    );
    var successCallback = () =>
      this.deleteMateriovigilance(this.props.materiovigilance.model_id);

    var mvClassName = "";
    if (parseInt(this.props.materiovigilance.materiovigilance) === 1)
      mvClassName = "alert-danger";
    else if (parseInt(this.props.materiovigilance.materiovigilance) === 2)
      mvClassName = "alert-info";
    else if (parseInt(this.props.materiovigilance.materiovigilance) === 3)
      mvClassName = "alert-warning";

    let menuItems = [];

    menuItems.push(
      // edit
      {
        icon: "pen-to-square",
        action: () => this.props.openEditModal(this.props.materiovigilance),
        text: <FormattedMessage id="Modify" />,
      },
      // delete
      {
        icon: "trash",
        action: () =>
          this.props.openConfModal(modalTitle, modalContent, successCallback),
        text: <FormattedMessage id="Delete" />,
      },
      // download attachment
      this.props.materiovigilance.doc_url !== undefined &&
        this.props.materiovigilance.doc_url !== null && {
          icon: "download",
          href:
            APIUrl.getMateriovigilanceFile +
            this.props.materiovigilance._id +
            "/" +
            this.props.materiovigilance._id +
            "." +
            this.props.materiovigilance.doc_url.split(".")[1] +
            "?token=" +
            APIUrl.jwtToken,
          text: <FormattedMessage id="Download.Attachment" />,
        },
    );

    let menuAction = <ActionMenu items={menuItems} />;

    // var pathToDownloadDoc =  this.onDownloadMateriovigilanceDoc(this.props.materiovigilance.model_id)
    return (
      <React.Fragment>
        <tr key={this.props.key} className="d-flex">
          <td className="col">{this.props.brand + " " + this.props.model}</td>
          <td className="col col-3">
            <div className={"text-center form-control " + mvClassName}>
              <FormattedMessage
                id={
                  "EqMateriovigilance." +
                  this.props.materiovigilance.materiovigilance
                }
              />
            </div>
          </td>
          <td className="col col-2">
            <div>{DateUtil.toDate(this.props.materiovigilance.createdAt)}</div>
          </td>
          <td className="col col-2">
            <div>{DateUtil.toDate(this.props.materiovigilance.date)}</div>
          </td>
          <td className="text-center col col-1">{menuAction}</td>
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.user.lang,
    models: state.models,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteMateriovigilance: (data) =>
      dispatch(deleteMateriovigilanceEquipement(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MateriovigilanceAdminRow);
