import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import DateUtil from "../../../util/DateUtil";
import { FormattedMessage } from "react-intl";
import APIUrl from "../../../APIUrl";
import EqOwner from "../../../enums/EqOwner";
import EqState from "../../../enums/EqState";
import ActionMenu from "../../sub/ActionMenu";
import Icon from "../../sub/Icon";
import RequestTypes from "../../../enums/RequestTypes";

class EquipmentRowAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  getMaterialType(materialTypeId) {
    for (let materialType of this.props.materialsTypes) {
      if (materialType._id === materialTypeId) return materialType;
    }
  }

  getBrandName(brandId) {
    for (let brand of this.props.brands) {
      if (brand._id === brandId) return brand.name.toString().toUpperCase();
    }
  }

  getModelName(modelId) {
    for (let model of this.props.models) {
      if (model._id === modelId) return model.name;
    }
  }

  closeModal() {
    this.setState({ modal: null });
  }

  hasPendingRequest(requestType) {
    for (let r of this.props.requests) {
      if (r.request_type === requestType && r.status <= 1) {
        return true;
      }
    }
    return false;
  }

  birthdayDateAlert(dateBirthday, dateBirthdayString) {
    let date = new Date(dateBirthday);
    let yearBirthday = date.getFullYear() + 1;
    let monthBirthday = date.getMonth();
    let dayBirthday = date.getDate();
    let dateBirthdayTime =
      new Date(yearBirthday, monthBirthday, dayBirthday).getTime() / 1000;
    let dateNowTime = new Date().getTime() / 1000;

    if (dateBirthdayTime < dateNowTime)
      return (
        <span className="w-100 badge badge-danger">{dateBirthdayString}</span>
      );
    if (dateBirthdayTime - 3600 * 24 * 60 < dateNowTime)
      return (
        <span className="w-100 badge badge-warning">{dateBirthdayString}</span>
      );
    return (
      <span className="w-100 badge badge-success">{dateBirthdayString}</span>
    );
  }

  getActualAffectation(resident_id, residents) {
    for (let resident of residents) {
      if (resident._id === resident_id) {
        return resident.first_name + " " + resident.name;
      }
    }
    return this.props.intl.formatMessage({ id: "Unspecified" });
  }

  formatHeading(element) {
    return <h6>{element}</h6>;
  }

  render() {
    var lastMaintenanceDate = null;
    let pendingMaintenance =
      this.props.maintenances[this.props.maintenances.length - 1];
    let pendingMaintenanceState = false;

    if (this.props.maintenances.length > 0) {
      if (
        this.props.maintenanceToSign !== undefined &&
        this.props.maintenanceToSign.includes(this.props.equipment._id)
      ) {
        pendingMaintenanceState =
          pendingMaintenance.general_state !== undefined
            ? pendingMaintenance.general_state
            : 0;

        if (pendingMaintenance.signature_date === undefined) {
          lastMaintenanceDate = (
            <div className="w-100 badge badge-info">
              {DateUtil.toDate(pendingMaintenance.creation_date)}
            </div>
          );
        } else {
          lastMaintenanceDate = (
            <div className="w-100 badge badge-info">
              {DateUtil.toDate(pendingMaintenance.signature_date)}
            </div>
          );
        }
      } else {
        if (pendingMaintenance.signature_date === undefined) {
          lastMaintenanceDate = this.birthdayDateAlert(
            pendingMaintenance.creation_date,
            DateUtil.toDate(pendingMaintenance.creation_date),
          );
        } else {
          lastMaintenanceDate = this.birthdayDateAlert(
            pendingMaintenance.signature_date,
            DateUtil.toDate(pendingMaintenance.signature_date),
          );
        }
      }
    } else {
      lastMaintenanceDate = <div className="w-100 badge badge-light">-</div>;
    }

    var equipment = this.props.equipment;
    var residents = this.props.residents;
    let materiovigilance = <span className={"w-100 badge badge-light"}>-</span>;
    if (this.props.materiovigilance !== undefined) {
      var mvClassName = "";
      if (parseInt(this.props.materiovigilance) === 1) mvClassName = "danger";
      else if (parseInt(this.props.materiovigilance) === 2)
        mvClassName = "info";
      else if (parseInt(this.props.materiovigilance) === 3)
        mvClassName = "warning";

      materiovigilance = (
        <span className={"w-100 badge badge-" + mvClassName}>
          {this.props.intl.formatMessage({
            id: "EqMateriovigilance." + this.props.materiovigilance,
          })}
        </span>
      );
    } else {
      materiovigilance = (
        <span className={"w-100 badge badge-success"}>
          {this.props.intl.formatMessage({ id: "EqMateriovigilance.0" })}
        </span>
      );
    }

    // Styling owner of the DM
    let currentOwner = <span className={"w-100 badge badge-light"}>-</span>;
    if (equipment.owner !== undefined) {
      if (this.props.mode !== "quickview") {
        currentOwner = this.props.intl.formatMessage({
          id: "EqOwner." + equipment.owner,
        });
      } else {
        currentOwner = this.props.intl.formatMessage({
          id: "EqOwner.quickview." + equipment.owner,
        });
      }
      let ownerClassName;

      switch (equipment.owner) {
        case 0:
          ownerClassName = "warning";
          break;
        case 1:
          ownerClassName = "success";
          break;
        case 2:
          ownerClassName = "danger";
          currentOwner = this.props.intl.formatMessage({ id: "External" });
          break;
        default:
          ownerClassName = "light";
          break;
      }

      currentOwner = (
        <span className={"w-100 badge badge-" + ownerClassName}>
          {currentOwner}
        </span>
      );
    }

    let equipmentState = <span className={"w-100 badge badge-light"}>-</span>;

    if (equipment.state !== undefined) {
      let stateToDisplay;
      let equipmentClassName = "light";

      if (pendingMaintenanceState) {
        stateToDisplay = pendingMaintenanceState;
        equipmentClassName = "info";
      } else {
        stateToDisplay = equipment.state;

        switch (stateToDisplay) {
          case EqState.GOOD:
            equipmentClassName = "success";
            break;
          case EqState.AVERAGE:
            equipmentClassName = "warning";
            break;
          case EqState.BAD:
            equipmentClassName = "danger";
            break;
          default:
            break;
        }
      }

      equipmentState = this.props.intl.formatMessage({
        id: "EqState." + stateToDisplay,
      });

      equipmentState = (
        <span className={"w-100 badge badge-" + equipmentClassName}>
          {equipmentState}
        </span>
      );
    }

    var materialType = this.getMaterialType(equipment.material_type_id);
    if (!materialType) return null;
    var canDoMaintenance =
      materialType.checkpoints &&
      materialType.checkpoints.length > 0 &&
      equipment.owner !== EqOwner.EXTERNAL;
    var canDoIntervention = equipment.owner !== EqOwner.EXTERNAL;

    var imgSrc =
      APIUrl.getMaterialTypeImg +
      materialType._id +
      "/" +
      materialType.name +
      "/" +
      Math.random() +
      "?token=" +
      APIUrl.jwtToken;
    var img = (
      <img
        src={imgSrc}
        alt={"material_type_img" + Math.random()}
        height="100"
        width="100"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "/images/no_image_512.png";
        }}
      />
    );

    let equipmentAffectation = () => {
      if (equipment.affectation !== undefined) {
        if (equipment.affectation === 0) {
          return (
            <Icon
              id={"affectation-" + equipment._id}
              size="xl"
              icon="user"
              hover={this.getActualAffectation(
                equipment.resident_id,
                residents,
              )}
            />
          );
        } else {
          return (
            <Icon
              id={"affectation-" + equipment._id}
              size="xl"
              icon="users"
              hover={<FormattedMessage id="EqAffectation.1" />}
            />
          );
        }
      } else {
        return this.props.intl.formatMessage({ id: "Non.Available.Abbr" });
      }
    };

    let hasPendingRenewal = () => {
      return this.hasPendingRequest(RequestTypes.RENEWAL);
    };

    let hasPendingIntervention = () => {
      return this.hasPendingRequest(RequestTypes.INTERVENTION);
    };

    let menuItems = [];

    /**
     * Define action menu items depending of context (view mode)
     */
    switch (this.props.mode) {
      // Used to be displayed when maintenance have to be
      case "maintenance":
        menuItems.push({
          id: null,
          icon: "eye",
          disabled: false,
          action: () => this.editMaintenance(equipment),
          text: <FormattedMessage id="Display" />,
        });
        menuItems.push({
          id: null,
          icon: "trash",
          disabled: false,
          action: () => this.deleteMaintenance(equipment),
          text: <FormattedMessage id="Delete" />,
        });
        break;
      case "quickview":
        menuItems.push({
          id: "modify-" + equipment._id,
          icon: "pen-to-square",
          disabled: false,
          action: () => this.props.editEquipment(equipment),
          text: <FormattedMessage id="Modify" />,
        });
        break;
      default:
        if (equipment.establishment_id === null) {
          menuItems.push({
            id: "assign-" + equipment._id,
            icon: "right-to-bracket",
            // disabled: canDoMaintenance ? false : true,
            action:
              equipment.owner === EqOwner.DM_FROM_PSDM
                ? () => {
                    this.props.openAssignModal(equipment);
                  }
                : false,
            text: <FormattedMessage id="Assign" />,
          });
          menuItems.push({
            id: null,
            icon: "clipboard-list",
            disabled: equipment.owner !== EqOwner.EXTERNAL ? false : true,
            action: () => {
              canDoMaintenance &&
                this.props.openMaintenancesHistoryModal(equipment);
            },
            text: <FormattedMessage id="Maintenances" />,
          });
          menuItems.push({
            id: "intervention" + equipment._id,
            icon: hasPendingRenewal() ? "recycle" : "wrench",
            notification:
              (hasPendingRenewal() || hasPendingIntervention()) && 1,
            disabled: !canDoIntervention,
            action: canDoIntervention
              ? () => {
                  this.props.openInterventionModal(equipment);
                }
              : false,
            text: <FormattedMessage id="Interventions" />,
          });
          menuItems.push({
            id: null,
            icon: "trash",
            disabled: false,
            action: () => this.props.deleteEquipment(equipment),
            text: <FormattedMessage id="Delete" />,
          });
        } else {
          menuItems.push({
            id: null,
            icon: "pen-to-square",
            disabled: false,
            action: () => this.props.editEquipment(equipment),
            text: <FormattedMessage id="Modify" />,
          });
          // If equipment is not property of PSDM, we dissalow a number of actions
          menuItems.push({
            id: null,
            icon: "clipboard-list",
            disabled:
              equipment.owner === EqOwner.EXTERNAL ||
              !materialType.checkpoints ||
              materialType.checkpoints.length === 0,
            action: () => {
              canDoMaintenance &&
                this.props.openMaintenancesHistoryModal(equipment);
            },
            text: <FormattedMessage id="Maintenances" />,
          });
          menuItems.push({
            id: "intervention" + equipment._id,
            icon: hasPendingRenewal() ? "recycle" : "wrench",
            notification:
              (hasPendingRenewal() || hasPendingIntervention()) && 1,
            disabled: !canDoIntervention,
            action: canDoIntervention
              ? () => {
                  this.props.openInterventionModal(equipment);
                }
              : false,
            text: <FormattedMessage id="Interventions" />,
          });
          // Only PSDM equipments can be moved. Others may be deleted
          if (equipment.owner === EqOwner.DM_FROM_PSDM) {
            menuItems.push({
              id: "assign-" + equipment._id,
              icon: "right-from-bracket",
              disabled: hasPendingIntervention(),
              action:
                equipment.owner === EqOwner.DM_FROM_PSDM
                  ? () => {
                      this.props.openAssignModal(equipment);
                    }
                  : false,
              text: <FormattedMessage id="Get.Back" />,
            });
          }
          menuItems.push({
            id: null,
            icon: "trash",
            disabled: false,
            action: () => this.props.deleteEquipment(equipment),
            text: <FormattedMessage id="Delete" />,
          });
        }
        break;
    }

    let actionMenuIcon, actionMenuVariant;

    switch (true) {
      case hasPendingRenewal():
        actionMenuIcon = "recycle";
        actionMenuVariant = "warning";
        break;
      case hasPendingIntervention():
        actionMenuIcon = "wrench";
        actionMenuVariant = "warning";
        break;
      default:
        break;
    }

    let header = "";

    switch (equipment.owner) {
      case EqOwner.EXTERNAL:
      case EqOwner.INTERNAL_EHPAD:
        header = (
          <span className="text-danger">
            <Icon icon="exclamation-triangle" /> <FormattedMessage id="Owner" />
            :{" "}
            {this.props.intl.formatMessage({
              id: "EqOwner.quickview." + equipment.owner,
            })}
          </span>
        );
        break;
      case EqOwner.DM_FROM_PSDM:
        header = (
          <span className="text-success">
            <Icon icon="circle-check" /> <FormattedMessage id="Owner" />
          </span>
        );
        break;
      default:
        header = (
          <span className="text-danger">
            <Icon icon="exclamation-triangle" /> <FormattedMessage id="Owner" />{" "}
            <FormattedMessage id="Undefined" />
          </span>
        );
        break;
    }

    const menuAction = (
      <ActionMenu
        items={menuItems}
        variant={actionMenuVariant}
        icon={actionMenuIcon}
        header={header}
        size="2xl"
      />
    );

    return (
      <React.Fragment>
        <tr key={equipment._id} className="d-flex align-items-center">
          <td className="col text-center">
            {this.props.mode !== "quickview" && <div>{img}</div>}
            <div>{materialType.name}</div>
            {/* </div> */}
          </td>
          <td className="col smallColText">
            <div>
              <strong>{this.getBrandName(equipment.brand_id)}</strong>
            </div>
            <div className="small-font">
              <strong>{this.getModelName(equipment.model_id)}</strong>
            </div>
            <div className="small-font">
              <strong>
                <FormattedMessage id="Intern.Ref.Short" />
              </strong>
            </div>
            <div className="small-font">{equipment.internal_ref}</div>
            <div className="small-font">
              <strong>
                <FormattedMessage id="Serial.Number.Short" />
              </strong>
            </div>
            <div className="small-font">{equipment.serial_nbr}</div>
          </td>
          <td className="col">
            <div>
              <Icon
                icon="turn-up"
                title={this.props.intl.formatMessage({ id: "Floor" })}
              />{" "}
              {equipment.floor === 0 ? (
                <FormattedMessage id="Ground.Floor.Abbr" />
              ) : equipment.floor !== undefined && equipment.floor !== "" ? (
                equipment.floor
              ) : (
                this.props.intl.formatMessage({ id: "Non.Available.Abbr" })
              )}
            </div>
            <div>
              <Icon
                title={this.props.intl.formatMessage({ id: "Room" })}
                icon="bed"
              />{" "}
              {equipment.room !== undefined && equipment.room !== null
                ? equipment.room
                : this.props.intl.formatMessage({ id: "Non.Available.Abbr" })}
            </div>
          </td>
          {this.props.mode !== "quickview" && (
            <td className="col text-center">{equipmentAffectation()}</td>
          )}
          {this.props.mode !== "quickview" && (
            <td className="col text-center">
              {equipment.warranty ? (
                <Icon icon="circle-check" className="text-success" size="xl" />
              ) : (
                <Icon icon="circle-xmark" className="text-danger" size="xl" />
              )}
            </td>
          )}
          <td className="col text-center">
            {this.formatHeading(lastMaintenanceDate)}
          </td>
          <td className="col text-center">
            {this.formatHeading(equipmentState)}
          </td>
          <td className="col text-center">
            {this.formatHeading(currentOwner)}
          </td>
          <td className="col text-center">
            {this.formatHeading(materiovigilance)}
          </td>
          <td className="col text-center">{menuAction}</td>
        </tr>

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    materialsTypes: state.materialsTypes,
    brands: state.brands,
    models: state.models,
    residents: state.residents,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(EquipmentRowAdmin));
