import React from "react";
import { connect } from "react-redux";
import {
  deleteMaintenance,
  getMaintenanceById,
} from "../../../actions/maintenances/maintenances";
import DateUtil from "../../../util/DateUtil";
import { FormattedMessage } from "react-intl";
import PDFViewer from "../../pdf/PDFViewer";
import PDFUtil from "../../../util/PDFUtil";
import MaintenancePDF from "./MaintenancePDF";
import ActionMenu from "../../sub/ActionMenu";
import Roles from "../../../enums/Roles";
import APIUrl from "../../../APIUrl";
import ModalManager from "../../sub/modals/ModalManager";
import { Badge } from "react-bootstrap";

class MaintenanceRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pdfViewer: null,
      displayPDF: false,
      disableExport: false,
      inProgress: false,
    };
  }

  openConfModal(maintenance) {
    const data = {
      maintenanceId: maintenance._id,
      equipmentId: this.props.equipment._id,
    };
    this.props.updateParentState({
      modal: (
        <ModalManager
          showModal={true}
          context="danger"
          mandatoryConfirmation
          title={<FormattedMessage id="Confirmation.Required" />}
          content={
            <div>
              <p>
                <FormattedMessage id="Maintenance.Remove.Equipment.State" />
              </p>
              <FormattedMessage id="Please.Confirm" />
            </div>
          }
          successCallback={() =>
            this.props.onDeleteMaintenance(
              this.props.establishment._id,
              data,
              () => this.props.closeModal(),
            )
          }
          closeModal={() => this.props.closeModal()}
          modalType="confirmation"
        />
      ),
    });
  }

  savePDF(maintenance, template, successCallback) {
    const id = "maintenance-pdf-" + this.props.equipment._id;
    const fileName = maintenance._id;

    const resetCallback = () => {
      this.setState({
        pdfViewer: null,
      });

      successCallback();
    };

    const toPDFCallback = () => {
      PDFUtil.toPDFWithCSSAsString(
        this.props.generalSettings,
        this.props.company,
        id,
        fileName,
        embeddedCSS,
        resetCallback,
        {
          type: "maintenance",
          equipmentId: this.props.equipment._id,
          url: "toMaintenancePDF",
        },
        false,
      );
    };

    this.setState(
      {
        pdfViewer: (
          <PDFViewer
            open={false}
            toggle={() => {}}
            template={template}
            fileName={fileName}
          />
        ),
      },
      toPDFCallback,
    );
  }

  getEstablishmentNameByEstablishmentId(establishmentId) {
    for (let establishment of this.props.establishments) {
      if (establishment._id === establishmentId) {
        return establishment.name;
      }
    }
  }

  openMaintenancePdf(maintenance, maintenanceDate) {
    this.setState({ inProgress: true });

    const url = encodeURI(
      APIUrl.getMaintenancesPDF +
        this.props.equipment._id +
        "/" +
        maintenance._id +
        "?token=" +
        APIUrl.jwtToken,
    );
    const openDocument = () => {
      window.open(url, "_system", "transitionstyle=fliphorizontal");
      this.setState({ inProgress: false });
    };

    fetch(url, { method: "GET" }).then((response) => {
      if (response.status === 200) {
        openDocument();
      } else {
        this.props.onGetMaintenanceById(maintenance._id, (response) => {
          const pdfTemplate = (
            <MaintenancePDF
              id={"maintenance-pdf-" + this.props.equipment._id}
              establishment={this.props.establishment}
              admin={this.props.admin}
              equipment={this.props.equipment}
              maintenance={response}
              maintenanceDate={maintenanceDate}
            />
          );
          this.savePDF(response, pdfTemplate, openDocument);
        });
      }
    });
  }

  render() {
    const { maintenance, equipment, user } = this.props;

    const isPendingMaintenance =
      !maintenance.rep_signature && !maintenance.tech_signature;

    const maintenanceDate = maintenance.signature_date
      ? DateUtil.toDate(maintenance.signature_date)
      : DateUtil.toDate(maintenance.creation_date);

    const maintenanceDateBadge = (
      <Badge
        variant={!isPendingMaintenance ? "success" : "info"}
        className="w-100"
      >
        {maintenanceDate}
      </Badge>
    );

    const establishmentName =
      this.getEstablishmentNameByEstablishmentId(
        maintenance.establishment_id,
      ) || "";

    const menuItems = [];

    menuItems.push({
      icon: "file-pdf",
      action: () => this.openMaintenancePdf(maintenance, maintenanceDate),
      text: <FormattedMessage id="Display.PDF" />,
      disabled: this.state.disableExport,
    });

    if (
      user.role === Roles.ADMIN &&
      (equipment ? equipment.establishment_id : true)
    ) {
      menuItems.push({
        id: null,
        icon: "trash",
        action: () => this.openConfModal(maintenance),
        text: <FormattedMessage id="Delete" />,
      });
    }

    let menuAction = (
      <ActionMenu
        items={menuItems}
        processing={this.state.inProgress}
        size="2xl"
        className="mr-auto ml-2"
      />
    );

    return (
      <>
        <tr key={maintenance._id}>
          <td className="col col-4">{maintenanceDateBadge}</td>
          <td className="col col-7">
            {user.role === Roles.ADMIN && establishmentName}
          </td>
          <td className="col col-1">{menuAction}</td>
        </tr>
        {this.state.pdfViewer}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    maintenances: state.maintenances,
    generalSettings: state.generalSettings,
    company: state.company,
    establishments: state.establishments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteMaintenance: (establishmentId, data, successCallback) =>
      dispatch(deleteMaintenance(establishmentId, data, successCallback)),
    onGetMaintenanceById: (maintenanceId, successCallback) =>
      dispatch(getMaintenanceById(maintenanceId, successCallback)),
  };
};

const embeddedCSS = `
strong {
    font-family: "arial black" !important;
}
h3 {
    font-size: 24px !important;
}
h5 {
    font-size: 16px !important;
}
.pagebreak {
    page-break-before: always
}
`;

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceRow);
