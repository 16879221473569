import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import APIUrl from "../../APIUrl";
// Enums
import RequestTypes from "../../enums/RequestTypes";
// Components
import RenewalRequest from "./RenewalRequest";
import InterventionRequest from "./InterventionRequest";

import CustomLabel from "../sub/CustomLabel";
import { Modal } from "react-bootstrap";

class RequestModal extends React.Component {
  getRequest(requestId) {
    for (let request of this.props.requests) {
      if (request._id === requestId) return request;
    }
  }

  render() {
    var request = this.getRequest(this.props.requestId);

    var clientRequest = null;
    var requestType = null;
    if (this.props.requestType === RequestTypes.RENEWAL) {
      requestType = "Renewal";
      clientRequest = (
        <RenewalRequest
          equipment={this.props.equipment}
          close={() => this.props.close()}
          request={request}
          required={this.props.required}
        />
      );
    } else if (this.props.requestType === RequestTypes.INTERVENTION) {
      requestType = "Intervention";
      clientRequest = (
        <InterventionRequest
          authorRole={this.props.user.role}
          equipment={this.props.equipment}
          establishment={this.props.establishment}
          close={() => this.props.close()}
          request={request}
          required={this.props.required}
        />
      );
    }

    var adminResponseNode = null;
    if (request && request.response) {
      adminResponseNode = (
        <div className="form-group row mt-3">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Response" })}
            htmlFor="response"
            className="col-12 col-sm-5"
          />
          <div className="col-12 col-sm-7">
            <p className="alert alert-secondary">{request.response}</p>
          </div>
        </div>
      );
    }

    var filesNode = null;
    if (request && request.files && request.files.length > 0) {
      filesNode = request.files.map((file) => {
        return (
          <div key={file}>
            <a
              role="button"
              href={
                APIUrl.getRequestFile +
                request._id +
                "/" +
                file +
                "?token=" +
                APIUrl.jwtToken
              }
              rel="noopener noreferrer"
              download={file}
              target="_blank"
            >
              {file}
            </a>
          </div>
        );
      });

      filesNode = (
        <div className="form-group row mt-3">
          <div className="col-12 col-sm-5">
            <span>
              <FormattedMessage id="Files" />
            </span>
          </div>
          <div className="col-12 col-sm-7">{filesNode}</div>
        </div>
      );
    }

    return (
      <Modal
        show={true}
        onHide={() => this.props.close()}
        backdrop={"static"}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id={"Request." + requestType} />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {clientRequest}

          {(filesNode || adminResponseNode) && (
            <>
              <hr />

              {filesNode}

              {adminResponseNode}
            </>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requests: state.requests,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(RequestModal));
