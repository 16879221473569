import React from "react";
import { FormattedMessage } from "react-intl";
import Maths from "../../../util/Maths";
import Icon from "../../sub/Icon";
import ProductsParserUtil from "../../../util/ProductsParserUtil";

class OrderProductRowAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false,
      unavailable: 0,
      available: this.props.product.quantity,
    };
  }

  changeQuantityAvailable(value) {
    if (value > this.props.product.quantity || value < 0 || value === "")
      return;

    this.setState(
      {
        available: value,
        unavailable: this.props.product.quantity - value,
      },
      this.props.onClickCallback,
    );
  }

  changeQuantityUnavailable(value) {
    if (value > this.props.product.quantity || value < 0 || value === "")
      return;

    this.setState(
      {
        unavailable: value,
        available: this.props.product.quantity - value,
      },
      this.props.onClickCallback,
    );
  }

  render() {
    let isUrgentClass = this.props.product.urgent ? "text-danger" : "";

    return (
      <tr
        key={this.props.keyRow}
        id={"product-" + this.props.product.id_product}
        className={isUrgentClass}
      >
        <td className="align-middle">{this.props.product.ref}</td>
        <td className="align-middle">
          {ProductsParserUtil.swapDesignationDelimiter(
            this.props.product.designation,
          ) + " "}
          {this.props.product.urgent && (
            <Icon
              id={"item_" + this.props.product.ref}
              icon="triangle-exclamation"
              hover={<FormattedMessage id="Urgent" />}
            />
          )}
        </td>
        <td className="align-middle">{this.props.product.quantity}</td>
        <td className="align-middle">
          {Maths.round(this.props.product.prix_u_ht_emera)}€
        </td>
        <td className="align-middle">
          {Maths.round(this.props.product.total_ht)}€
        </td>
        <td className="text-center card-body">
          <input
            id={"unavailable-" + this.props.product.id_product}
            type="number"
            value={this.state.unavailable}
            min="0"
            max={this.props.product.quantity}
            onChange={(e) => this.changeQuantityUnavailable(e.target.value)}
            className="form-control unavailable-product"
          />
        </td>
        <td className="text-center card-body">
          <input
            id={"available-" + this.props.product.id_product}
            type="number"
            value={this.state.available}
            min="0"
            max={this.props.product.quantity}
            onChange={(e) => this.changeQuantityAvailable(e.target.value)}
            className="form-control available-product"
          />
        </td>
      </tr>
    );
  }
}

export default OrderProductRowAdmin;
