import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import ModalManager from "../../sub/modals/ModalManager";
import {
  assignEquipment,
  checkIfEquipmentExists,
  unassignEquipment,
} from "../../../actions/equipments/equipments";
import Alert from "react-bootstrap/Alert";

class EquipmentAssignModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEstablishmentId: null,
      modal: null,
    };
  }

  close() {
    this.props.closeModal();
  }

  // Establishment to which equipment will be linked to
  selectEstablishment = (establishmentId) => {
    this.setState({ selectedEstablishmentId: establishmentId });
  };

  // Do assign
  assignEquipment = (equipment, selectedEstablishmentId) => {
    this.props.onCheckIfEquipmentExists(
      {
        establishmentId: selectedEstablishmentId,
        internRef: equipment.internal_ref,
      },
      () => {
        this.openDataIntegrityModal(equipment.internal_ref, "assign");
      },
      () => {
        this.props.onAssignEquipment(this.props.equipment.company_id, {
          equipmentId: equipment._id,
          internalRef: equipment.internal_ref,
          establishmentId: selectedEstablishmentId,
        });
      },
    );
  };

  // Do unassign
  unassignEquipment = (equipment, selectedEstablishmentId) => {
    this.props.onCheckIfEquipmentExists(
      { establishmentId: null, internRef: equipment.internal_ref },
      () => {
        this.openDataIntegrityModal(equipment.internal_ref, "unassign");
      },
      () => {
        this.props.onUnassignEquipment(selectedEstablishmentId, {
          equipmentId: equipment._id,
          internalRef: equipment.internal_ref,
        });
      },
    );
  };

  openDataIntegrityModal(internalRef, mode) {
    let establishmentName = <FormattedMessage id="Dms.Unassigned" />;

    if (mode === "assign") {
      establishmentName = this.getEstablishmentName();
    }

    let errorModalTitle = <FormattedMessage id="Error" />;
    let errorModalContent = (
      <div>
        <div className="alert alert-danger">
          <div>
            {mode === "assign" ? (
              <FormattedMessage
                id="Equipment.Can.Not.Be.affected"
                values={{
                  internalRef: internalRef,
                  establishmentName: establishmentName,
                }}
              />
            ) : (
              <FormattedMessage
                id="Equipment.Can.Not.Be.unaffected"
                values={{
                  internalRef: internalRef,
                  establishmentName: establishmentName,
                }}
              />
            )}
          </div>
        </div>
        <table className="table table-striped tablee4coll">
          <thead>
            <tr className="d-flex">
              <th scope="col" className="col col-3">
                <FormattedMessage id="Establishment" />
              </th>
              <th scope="col" className="col col-3">
                <FormattedMessage id="Intern.Ref" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="d-flex">
              <td className="col col-3">{establishmentName}</td>
              <td className="col col-3">{internalRef}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );

    this.props.openErrorModal(errorModalTitle, errorModalContent);
  }

  getEstablishmentName() {
    for (let establishment of this.props.establishments) {
      if (establishment._id === this.state.selectedEstablishmentId)
        return establishment.name;
    }
  }

  render() {
    let equipment = this.props.equipment;
    let establishment = this.props.establishment;
    let checkAssigned = equipment.establishment_id ? true : false;

    let establishmentsNode = this.props.establishments.map((establishment) => {
      return (
        <option key={establishment._id} value={establishment._id}>
          {establishment.name}
        </option>
      );
    });

    let content = checkAssigned ? (
      <div>
        <Alert variant="warning">
          <FormattedMessage
            id="Get.Back.Confirm.Message"
            values={{
              establishmentName: <strong>{establishment.name}</strong>,
            }}
          />
        </Alert>
        <p>
          <FormattedMessage id="Confirm.Choice" />
        </p>
      </div>
    ) : (
      <div>
        <Alert variant="warning">
          <FormattedMessage id="Assign.Confirm.Message" />
        </Alert>
        <select
          id="fleet-filter-establishmentId"
          className="form-control"
          onChange={(e) => this.selectEstablishment(e.target.value)}
        >
          <option key="" value="">
            {this.props.intl.formatMessage({
              id: "Select.Establishment",
            })}
          </option>
          {establishmentsNode}
        </select>
      </div>
    );

    let successCallback = () =>
      checkAssigned
        ? this.unassignEquipment(equipment, this.state.selectedEstablishmentId)
        : this.assignEquipment(equipment, this.state.selectedEstablishmentId);

    /*
        maintenance={this.props.maintenance}
        admin={this.props.admin}
        */

    return (
      <ModalManager
        showModal={true}
        title={
          checkAssigned ? (
            <FormattedMessage id="Get.Back.From.Establishment" />
          ) : (
            <FormattedMessage id="Assign.To.Establishment" />
          )
        }
        content={content}
        successCallback={successCallback}
        closeModal={() => this.close()}
        context={checkAssigned && "danger"}
        modalType="confirmation"
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    establishments: state.establishments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUnassignEquipment: (establishmentId, data) =>
      dispatch(unassignEquipment(establishmentId, data)),
    onAssignEquipment: (companyId, data) =>
      dispatch(assignEquipment(companyId, data)),
    onCheckIfEquipmentExists: (data, existsCallback, noExistsCallback) =>
      dispatch(checkIfEquipmentExists(data, existsCallback, noExistsCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(EquipmentAssignModal));
