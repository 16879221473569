import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import EquipmentModalAdmin from "./EquipmentModalAdmin";
import ImportColumnsModal from "./ImportColumnsModal";
import {
  getEstablishmentEquipments,
  getUnassignedEquipments,
  importEquipments,
} from "../../../actions/equipments/equipments";
import {
  getMaintenance,
  getMaintenances,
} from "../../../actions/maintenances/maintenances";
import EquipmentsAdmin from "./EquipmentsAdmin";
import FleetSummaryModal from "../FleetSummaryModal";
import BarCodesPDF from "./BarCodesPDF";
import { nanoid } from "nanoid";
import PDFViewer from "../../pdf/PDFViewer";
import PDFUtil from "../../../util/PDFUtil";
import DateUtil from "../../../util/DateUtil";
import BarCodeModal from "./BarCodeModal";
import ResidentsModal from "../../residents/ResidentsModal";
import EquipmentFileModal from "./EquipmentFileModal";
import { Alert, Modal } from "react-bootstrap";
import ActionMenu from "../../sub/ActionMenu";
import ExcelUtil from "../../../util/ExcelUtil";
import Util from "../../../util/Util";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import Icon from "../../sub/Icon";
import TableToolbar from "../../sub/bootstrap/TableToolbar";
import EquipmentsRequest from "../equipmentRequest/EquipmentsRequest";
import ModalManager from "../../sub/modals/ModalManager";

class FleetAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      establishmentId: null,

      // Import data
      fileName: null,
      fileData: null,

      modal: null,
      pdfViewer: null,
      disablePDFExport: false,
      inProgress: false,
    };
  }

  openEquipmentModal(establishmentId) {
    this.setState({
      modal: (
        <EquipmentModalAdmin
          close={() => this.closeModal()}
          barcode={nanoid(10)}
          establishmentId={establishmentId}
        />
      ),
    });
  }

  openSummaryModal(establishmentId) {
    this.setState({
      modal: (
        <FleetSummaryModal
          close={() => this.closeModal()}
          equipments={this.props.equipments}
        />
      ),
    });
  }

  // Reset all previous import params in state when aborting import process
  abortAndCloseModal() {
    this.setState({
      fileName: null,
      fileData: null,
    });

    this.closeModal();
  }

  // First modal when importing new equipments
  openBulkImportModal(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      mode: "create",
      modal: (
        <EquipmentFileModal
          closeModal={() => this.abortAndCloseModal()}
          onComplete={(fileData) => this.onCompleteStep1(fileData)}
        />
      ),
    });
  }

  // Action to do after the first modal (go to step 2)
  onCompleteStep1(file) {
    // Parse the excel file and then move on to next modal
    ExcelUtil.parse(file, 0, (fileData) => {
      // Save the passed params
      this.setState({
        fileName: file.name,
        fileData: fileData,
      });

      // Close current modal
      this.closeModal();

      // Open next step modal
      this.setState({
        modal: (
          <ImportColumnsModal
            establishmentId={this.state.establishmentId}
            closeModal={() => this.abortAndCloseModal()}
            fileData={fileData}
            onComplete={(columns) => this.onCompleteStep2(columns)}
          />
        ),
      });
    });
  }

  // Action to do after the second modal (columns mapping)
  onCompleteStep2(columns) {
    this.setState({
      columns: columns,
    });

    let successCallback;

    successCallback = () => {
      // Close current modal
      this.closeModal();

      // Notification
      let errorMessage = this.props.intl.formatMessage({
        id: "Equipments.Imported",
      });

      NotificationManager.success(errorMessage);
    };
    let failureCallback;
    failureCallback = (err) => {
      // Close current modal
      this.closeModal();
      var errorModalTitle = <FormattedMessage id="Error" />;
      var errorModalContent = (
        <div>
          <div className="alert alert-danger">
            <div>
              <FormattedMessage id="Mercurial.File.Missing.Data" />
            </div>
            <div>{JSON.stringify(err)}</div>
          </div>
        </div>
      );
      this.setState({
        modal: (
          <ModalManager
            showModal={true}
            size="xl"
            isOpen={true}
            title={errorModalTitle}
            content={errorModalContent}
            closeModal={() => this.closeModal()}
            buttonLabel={<FormattedMessage id="Cancel" />}
          />
        ),
      });
    };

    // Send the equipments to the BE
    this.importEquipments(
      this.state.establishmentId,
      this.state.fileName,
      this.state.fileData,
      columns,
      successCallback,
      failureCallback,
    );
  }

  /**
   * Send bulk equipments to the backend
   * @param {*} establishmentId
   * @param {*} fileName
   * @param {*} data
   * @param {*} columns
   * @param {*} callback
   */
  importEquipments(
    establishmentId,
    fileName,
    data,
    columns,
    callback,
    failureCallback,
  ) {
    // First, fix data with correct columns
    var equipments = this.fixEquipmentsCols(data, columns);

    // Then, send data to the BE
    let bulkData = {
      establishmentId: establishmentId,
      fileName: fileName,
      equipments: equipments,
    };
    this.props.onimportEquipments(
      establishmentId,
      bulkData,
      callback,
      failureCallback,
    );
  }

  // Perform checks on columns <-> data associations for equipments
  fixEquipmentsCols(equipments, columns) {
    let newEquipments = [];
    for (let e of equipments) {
      var newEquipment = {};
      for (let col of Object.keys(columns)) {
        if (col === "type" || col === "brand" || col === "model") {
          newEquipment[col] = e[columns[col]].toString().trim();
        } else {
          newEquipment[col] =
            typeof e[columns[col]] === "string"
              ? e[columns[col]].trim()
              : e[columns[col]];
        }
      }
      newEquipments.push(newEquipment);
    }

    return newEquipments;
  }

  // Check if id passed is the companyId of the PSDM
  isPsdm(id) {
    return id === this.props.company._id;
  }

  getEquipments(establishmentId) {
    if (this.isPsdm(establishmentId)) {
      this.props.onGetUnassignedEquipments(this.props.company._id);
    } else {
      this.props.onGetEquipmentsByEstablishmentId(establishmentId);
    }
  }

  closeModal() {
    this.setState({ modal: null });
  }

  selectEstablishment(establishmentId) {
    if (establishmentId === "") {
      return this.setState({ establishmentId: null });
    }
    if (establishmentId === this.props.company._id)
      this.props.onGetAllMaintenances();
    this.setState({ establishmentId: establishmentId });
    this.getEquipments(establishmentId);
    this.props.onGetMaintenance(establishmentId);
  }

  generateBarCodes(barCodeNumber) {
    if (this.state.disablePDFExport || !this.props.company) return;

    var now = DateUtil.toyyyyMMdd(Date.now());
    var id = "bar-codes-" + now;
    var fileName = "bar-codes-" + now;
    var template = (
      <BarCodesPDF
        id={id}
        company={this.props.company}
        barCodeNumber={barCodeNumber}
      />
    );

    var resetCallback = () =>
      this.setState({ pdfViewer: null, disablePDFExport: false });

    var toPDFCallback = () => {
      PDFUtil.toPDF(
        this.props.generalSettings,
        this.props.company,
        id,
        fileName,
        "/css/pdf.css",
        resetCallback,
      );
    };

    this.setState(
      {
        disablePDFExport: true,
        pdfViewer: (
          <PDFViewer
            open={false}
            toggle={() => {}}
            template={template}
            fileName={fileName}
          />
        ),
      },
      toPDFCallback,
    );
  }

  printFleetBarCodes(establishmentId, equipments) {
    if (this.state.disablePDFExport || !this.props.company) return;

    this.setState({ inProgress: true });

    var now = DateUtil.toyyyyMMdd(Date.now());
    var id = "fleet-bar-codes-" + now;
    var fileName = "fleet-bar-codes-" + now;
    var template = (
      <BarCodesPDF
        id={id}
        establishmentId={establishmentId}
        company={this.props.company}
        equipments={equipments}
      />
    );

    var resetCallback = () =>
      this.setState({
        pdfViewer: null,
        disablePDFExport: false,
        inProgress: false,
      });

    var toPDFCallback = () => {
      PDFUtil.toPDF(
        this.props.generalSettings,
        this.props.company,
        id,
        fileName,
        "/css/pdf.css",
        resetCallback,
      );
    };

    this.setState(
      {
        disablePDFExport: true,
        pdfViewer: (
          <PDFViewer
            open={false}
            toggle={() => {}}
            template={template}
            fileName={fileName}
          />
        ),
      },
      toPDFCallback,
    );
  }

  openBarCodeModal() {
    this.setState({
      modal: (
        <BarCodeModal
          close={() => this.closeModal()}
          successCallback={(barCodeNumber) =>
            this.generateBarCodes(barCodeNumber)
          }
        />
      ),
    });
  }
  openEquipmentsRequestModal(establishmentId) {
    this.setState({
      modal: (
        <Modal
          show={true}
          onHide={() => this.closeModal()}
          backdrop={"static"}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="Equipments.Request" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <EquipmentsRequest
              close={() => this.closeModal()}
              establishmentId={establishmentId}
            />
          </Modal.Body>
        </Modal>
      ),
    });
  }

  openResidentsModal() {
    this.setState({
      modal: (
        <ResidentsModal
          establishmentId={this.state.establishmentId}
          close={() => this.closeModal()}
        />
      ),
    });
  }
  countEquipmentsRequestsByEstablishment(establishment_id) {
    let count = 0;
    for (let equipmentResquest of this.props.equipmentsRequest) {
      if (equipmentResquest.establishment_id === establishment_id) {
        count++;
      }
    }
    return count;
  }

  render() {
    let disableActionMenu =
      this.state.establishmentId === null ||
      this.state.establishmentId === "" ||
      this.state.establishmentId === this.props.company._id;

    var establishmentsNode = this.props.establishments.map((establishment) => {
      return (
        <option key={establishment._id} value={establishment._id}>
          {establishment.name}
        </option>
      );
    });
    let countEquipmentsRequests = this.countEquipmentsRequestsByEstablishment(
      this.state.establishmentId,
    );
    let menuItems = [];
    menuItems.push({
      id: null,
      icon: "circle-plus",
      action: (e) => this.openEquipmentModal(this.state.establishmentId),
      text: <FormattedMessage id="Add.Equipment" />,
    });

    menuItems.push({
      id: null,
      icon: "person-cane",
      action: (e) => this.openResidentsModal(),
      text: <FormattedMessage id="Residents.Management" />,
    });

    menuItems.push({
      id: null,
      icon: "rectangle-list",
      action: (e) => this.openSummaryModal(this.state.establishmentId),
      text: <FormattedMessage id="Park.Summary" />,
    });

    menuItems.push({
      id: null,
      icon: "print",
      action: (e) =>
        this.printFleetBarCodes(
          this.state.establishmentId,
          this.props.equipments,
        ),
      text: <FormattedMessage id="Print.Fleet.BarCodes" />,
    });

    menuItems.push({
      id: null,
      icon: "barcode",
      action: (e) => this.openBarCodeModal(),
      text: <FormattedMessage id="Generate.BarCodes" />,
    });

    menuItems.push({
      id: null,
      icon: "upload",
      action: (e) => this.openBulkImportModal(e),
      text: <FormattedMessage id="Import.Equipments" />,
    });

    menuItems.push({
      id: null,
      icon: "truck-arrow-right",
      action: (e) =>
        this.openEquipmentsRequestModal(this.state.establishmentId),
      text: (
        <>
          <FormattedMessage id="Equipments.Request" />{" "}
          {countEquipmentsRequests > 0 && (
            <span className="badge badge-danger">
              {countEquipmentsRequests}
            </span>
          )}
        </>
      ),
      disabled: countEquipmentsRequests < 1,
    });

    let menuAction = (
      <ActionMenu
        items={menuItems}
        variant={countEquipmentsRequests > 0 && "danger"}
        icon={countEquipmentsRequests > 0 && "exclamation-triangle"}
        processing={this.state.inProgress}
        size="2xl"
        disabled={disableActionMenu}
        className="mr-auto ml-2"
      />
    );

    var establishmentSelectNode = (
      <TableToolbar>
        <Icon icon="building" className="ml-auto mr-4" />
        <select
          id="fleet-filter-establishmentId"
          className="form-control"
          onChange={(e) => this.selectEstablishment(e.target.value)}
        >
          <option key="" value="">
            {this.props.intl.formatMessage({
              id: "Select.Establishment",
            })}
            ...
          </option>
          <optgroup
            label={this.props.intl.formatMessage({
              id: "Establishments",
            })}
          >
            {establishmentsNode}
          </optgroup>
          <optgroup
            label={this.props.intl.formatMessage({
              id: "EqOwner.quickview.1",
            })}
          >
            <option key="psdmFleet" value={this.props.company._id}>
              {this.props.intl.formatMessage({
                id: "Dms.Unassigned",
              })}
            </option>
          </optgroup>
        </select>
        {menuAction}
      </TableToolbar>
    );

    // The admin hasn't picked a client yet
    if (
      !this.state.establishmentId ||
      Util.emptyString(this.state.establishmentId)
    )
      return establishmentSelectNode;

    var content = (
      <Alert variant="secondary">
        {this.state.establishmentId === this.props.company._id ? (
          <FormattedMessage id="Empty.Unassigned.Equipments" />
        ) : (
          <FormattedMessage id="Empty.Fleet" />
        )}
      </Alert>
    );

    var MaintenancesToSignArray = [];
    this.props.maintenances.filter((items) => {
      for (let each of this.props.equipments) {
        if (
          items.equipment_id === each._id &&
          items.rep_signature === undefined &&
          items.tech_signature === undefined
        ) {
          MaintenancesToSignArray.push(items.equipment_id);
        }
      }
      return MaintenancesToSignArray;
    });

    if (this.props.equipments.length > 0) {
      content = (
        <EquipmentsAdmin
          equipments={this.props.equipments}
          maintenancesToSign={MaintenancesToSignArray}
          establishmentId={this.state.establishmentId}
        />
      );
    }

    return (
      <React.Fragment>
        {establishmentSelectNode}

        {content}

        <div style={{ position: "absolute", marginLeft: "-10000px" }}>
          {this.state.pdfViewer}
        </div>

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    establishments: state.establishments,
    residents: state.residents,
    equipments: state.equipments,
    brands: state.brands,
    models: state.models,
    generalSettings: state.generalSettings,
    company: state.company,
    maintenances: state.maintenances,
    equipmentsRequest: state.equipmentsRequest,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onimportEquipments: (
      establishmentId,
      data,
      successCallback,
      failureCallback,
    ) =>
      dispatch(
        importEquipments(
          establishmentId,
          data,
          successCallback,
          failureCallback,
        ),
      ),
    onGetEquipmentsByEstablishmentId: (establishmentId) =>
      dispatch(getEstablishmentEquipments(establishmentId)),
    onGetUnassignedEquipments: (companyId) =>
      dispatch(getUnassignedEquipments(companyId)),
    onGetMaintenance: (establishmentId) =>
      dispatch(getMaintenance(establishmentId)),
    onGetAllMaintenances: () => dispatch(getMaintenances()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(FleetAdmin));
