import React from "react";
import { Row, Col, Card } from "react-bootstrap";

export default class TableToolbar extends React.Component {
  render() {
    return (
      <Row className="search-filters">
        <Col>
          <Card>
            <Card.Body>
              <div className="form-inline">{this.props.children}</div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}
