import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../../util/Util";
import DateUtil from "../../../util/DateUtil";
import EqAffectation from "../../../enums/EqAffectation";
import EqOwner from "../../../enums/EqOwner";
import EqState from "../../../enums/EqState";
import {
  addEquipment,
  checkIfEquipmentExists,
  updateEquipmentAdmin,
  addEquipmentFiles,
  deleteEquipmentFileById,
} from "../../../actions/equipments/equipments";
import ComboBox from "../../sub/ComboBox";
import Select from "react-select";
import { addBrand } from "../../../actions/configurables/brands";
import { addModel } from "../../../actions/configurables/models";
import FileDropZone from "../../sub/FileDropZone";
import APIUrl from "../../../APIUrl";
import FileUtil from "../../../util/FileUtil";
import Barcode from "react-barcode";
import CustomLabel from "../../sub/CustomLabel";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import { Button, Modal } from "react-bootstrap";
import EquipmentBarCodeModal from "./EquipmentBarCodeModal";
import Icon from "../../sub/Icon";
import ArrayUtil from "../../../util/ArrayUtil";
import MaintenanceImgPreview from "../maintenance/MaintenanceImgPreview";
import ModalManager from "../../sub/modals/ModalManager";

class EquipmentModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      barcode: this.props.barcode,
      material_type_id: this.props.equipmentRequest
        ? this.props.equipmentRequest.material_type_id
        : "",
      internal_ref: this.props.internalRef || "",
      brand_id: "",
      model_id: "",
      serial_nbr: "",
      purchase_date: "",
      warranty: false,
      affectation: "",
      resident_id: "",
      floor: "",
      room: "",
      owner: this.props.equipmentRequest
        ? this.props.equipmentRequest.type === 1
          ? EqOwner.INTERNAL_EHPAD
          : EqOwner.DM_FROM_PSDM
        : "",
      state: "",
      materiovigilance: "",
      misc: "",
      files: [],
      selectedResidentOption: null,

      materialTypeIdError: null,
      internalRefError: null,
      brandIdError: null,
      modelIdError: null,
      ownerError: null,
      stateError: null,

      disabledFileUpload: false,
      disabled: false,

      // Lightbox vars
      photoIndex: 0,
      lightboxIsOpen: false,

      modal: null,
    };

    // Update existing equipment
    if (this.isUpdate()) {
      var equipment = this.getEquipment();
      const resident = this.props.residents.find(
        (r) => r._id === equipment.resident_id,
      );

      this.state = {
        material_type_id: equipment.material_type_id,
        barcode: equipment.bar_code,
        internal_ref: equipment.internal_ref,
        brand_id: equipment.brand_id,
        model_id: equipment.model_id,
        serial_nbr: equipment.serial_nbr || "",
        purchase_date: DateUtil.toyyyyMMdd(equipment.purchase_date),
        warranty: this.boolValue(equipment.warranty),
        affectation: this.enumValue(equipment.affectation),
        resident_id: equipment.resident_id || "",
        floor: equipment.floor || "",
        room: equipment.room || "",
        owner: this.enumValue(equipment.owner),
        state: this.enumValue(equipment.state),
        materiovigilance: this.enumValue(equipment.materiovigilance),
        misc: equipment.misc || "",
        selectedResidentOption: resident
          ? {
              value: resident._id,
              label: `${resident.name} ${resident.first_name}`,
            }
          : null,

        materialTypeIdError: null,
        internalRefError: null,
        brandIdError: null,
        modelIdError: null,
        ownerError: null,
        stateError: null,

        disabledFileUpload: false,
        disabled: false,

        // Lightbox vars
        photoIndex: 0,
        lightboxIsOpen: false,

        modal: null,
      };
    }
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ModalManager
          showModal={true}
          title={title}
          content={content}
          successCallback={successCallback}
          closeModal={() => this.closeModal()}
          modalType="confirmation"
          context="danger"
        />
      ),
    });
  }

  deleteImage = (fileName) => {
    const title = <FormattedMessage id="Confirm" />;
    const content = <FormattedMessage id="Image.Confirm.Message" />;
    const successCallback = () =>
      this.props.onDeleteEquipmentFileById(
        this.props.establishmentId,
        this.props.equipmentId,
        fileName,
      );

    this.openConfModal(title, content, successCallback);
  };

  deleteDoc = (file) => {
    const title = <FormattedMessage id="Confirm" />;
    const content = <FormattedMessage id="Document.Confirm.Message" />;
    const successCallback = () =>
      this.props.onDeleteEquipmentFileById(
        this.props.establishmentId,
        this.props.equipmentId,
        file,
      );

    this.openConfModal(title, content, successCallback);
  };

  isUpdate() {
    if (this.props.equipmentId || this.props.internalRef) {
      var equipment = this.getEquipment();
      if (equipment) return true;
    }

    return false;
  }
  ifEquipmentExist(equipment) {
    if (equipment) {
      return equipment;
    }
    return null;
  }
  numValue(val) {
    if (val !== undefined && val !== null) return val;
    return 0;
  }

  enumValue(val) {
    if (val !== undefined && val !== null) return val;
    return "";
  }

  boolValue(val) {
    if (val !== undefined && val !== null) return val;
    return false;
  }

  getModel(modelId) {
    for (let model of this.props.models) {
      if (model._id === modelId) return model;
    }
  }

  onChange(field, value, lastValue) {
    if (field === "warranty")
      return this.setState({ warranty: !this.state.warranty });

    // If affectation is not resident, remove potential resident
    if (field === "affectation" && parseInt(value) !== EqAffectation.RESIDENT)
      this.setState({ resident_id: "", selectedResidentOption: null });

    if (field === "brand_id") {
      var model = this.getModel(this.state.model_id);
      if (!model || model.brand_id !== value) this.setState({ model_id: "" });
    }

    // Empty fields errors
    if (field === "material_type_id" && !Util.emptyString(value))
      this.setState({ materialTypeIdError: null });
    if (field === "brand_id" && !Util.emptyString(value))
      this.setState({ brandIdError: null });
    if (field === "model_id" && !Util.emptyString(value))
      this.setState({ modelIdError: null });
    if (field === "owner" && !Util.emptyString(value))
      this.setState({ ownerError: null });
    if (field === "state" && !Util.emptyString(value))
      this.setState({ stateError: null });

    // Check if internal ref is not empty and not already taken
    if (field === "internal_ref" && !Util.emptyString(value)) {
      // prevent lastValue error null if user creating equipment
      if (lastValue !== null) {
        //console.log("value:"+value+"\nLastValue:"+lastValue[field]);
        if (value !== lastValue[field]) {
          this.internalRefAlreadyExist(value);
        }
      } else {
        this.internalRefAlreadyExist(value);
      }
    }

    return this.setState({ [field]: value });
  }

  internalRefAlreadyExist(value) {
    this.setState({ internalRefError: null });
    this.props.onCheckIfEquipmentExists(
      { establishmentId: this.props.establishmentId, internRef: value },
      () => {
        this.setState({
          internalRefError: (
            <FormattedMessage id="InternalRef.Already.Exists" />
          ),
        });
      },
      () => {},
    );
  }

  scanErrors(noErrorCallback) {
    // Check for variables emptyness
    var isMaterialTypeEmpty = Util.emptyString(this.state.material_type_id);
    var isInternalRefEmpty = Util.emptyString(this.state.internal_ref);
    var isBrandEmpty = Util.emptyString(this.state.brand_id);
    var isModelEmpty = Util.emptyString(this.state.model_id);
    var isOwnerEmpty = Util.emptyString(this.state.owner);
    var isStateEmpty = Util.emptyString(this.state.state);

    // Empty fields errors
    if (isMaterialTypeEmpty) {
      this.setState({
        materialTypeIdError: (
          <FormattedMessage id="Please.Choose.Material.Type" />
        ),
      });
    }
    if (isInternalRefEmpty) {
      this.setState({
        internalRefError: <FormattedMessage id="Empty.InternalRef" />,
      });
    }
    if (isBrandEmpty) {
      this.setState({ brandIdError: <FormattedMessage id="Empty.Brand" /> });
    }
    if (isModelEmpty) {
      this.setState({ modelIdError: <FormattedMessage id="Empty.Model" /> });
    }
    if (isOwnerEmpty) {
      this.setState({ ownerError: <FormattedMessage id="Empty.Owner" /> });
    }
    if (isStateEmpty) {
      this.setState({ stateError: <FormattedMessage id="Empty.State" /> });
    }

    if (
      !isMaterialTypeEmpty &&
      !isInternalRefEmpty &&
      !isBrandEmpty &&
      !isModelEmpty &&
      !isOwnerEmpty &&
      !isStateEmpty
    )
      return noErrorCallback();
  }

  onComplete() {
    // Required fields
    var equipment = {
      establishment_id: this.props.establishmentId,
      material_type_id: this.state.material_type_id,
      bar_code: this.state.barcode,
      internal_ref: this.state.internal_ref,
      brand_id: this.state.brand_id,
      model_id: this.state.model_id,
      owner: this.state.owner,
      state: this.state.state,
    };

    // Optionnal fields
    if (!Util.emptyString(this.state.serial_nbr))
      equipment.serial_nbr = this.state.serial_nbr;
    if (!Util.emptyString(this.state.purchase_date))
      equipment.purchase_date = this.state.purchase_date;
    if (!Util.emptyString(this.state.warranty))
      equipment.warranty = this.state.warranty;
    if (!Util.emptyString(this.state.affectation))
      equipment.affectation = this.state.affectation;
    if (!Util.emptyString(this.state.resident_id))
      equipment.resident_id = this.state.resident_id;
    if (!Util.emptyString(this.state.floor)) equipment.floor = this.state.floor;
    if (!Util.emptyString(this.state.room)) equipment.room = this.state.room;
    if (!Util.emptyString(this.state.materiovigilance))
      equipment.materiovigilance = this.state.materiovigilance;
    if (!Util.emptyString(this.state.misc)) equipment.misc = this.state.misc;

    var sendFiles = (eq) => {
      if (!this.state.files || this.state.files.length === 0)
        return this.props.close("success");

      let formData = new FormData();
      for (let file of this.state.files) formData.append(file.name, file);

      this.props.onAddEquipmentFiles(
        this.props.establishmentId,
        eq._id,
        formData,
        () => this.props.close("success"),
      );
    };

    var addEquipment = () => {
      this.setState({ disabled: true, disabledFileUpload: true });

      this.props.onAddEquipment(
        this.props.establishmentId,
        equipment,
        sendFiles,
      );
    };

    this.scanErrors(() => addEquipment());
  }

  update(field, value, lastValue) {
    if (!this.isUpdate()) return;
    //console.log("field : "+field+" value : " +value+" lastvalue : "+lastValue[field]);
    //console.log("Value up:"+value+"\nLastValue up:"+lastValue[field])
    // Validate fields & send to BE
    if (field === "model_id" && value !== lastValue.model_id) {
      this.scanErrors(() => this._update("model_id", this.state["model_id"]));
      this.scanErrors(() => this._update("brand_id", this.state["brand_id"]));
    } else if (field === "internal_ref" && value !== lastValue.internal_ref) {
      this.props.onCheckIfEquipmentExists(
        { establishmentId: this.props.establishmentId, internRef: value },
        () => {
          this.scanErrors(() => this._update(field, lastValue.internal_ref));
        },
        () => {
          this.scanErrors(() => this._update(field, value));
        },
      );
    } else if (field === "warranty") {
      this.scanErrors(() => this._update(field, value));
    } else if (field === "affectation") {
      this.scanErrors(() =>
        this._update(field, value, () => this._update("resident_id", "")),
      );
    } else {
      if (value !== lastValue[field]) {
        this.scanErrors(() => this._update(field, value));
      }
    }
  }

  _update(field, value, successCallback) {
    var equipment = this.getEquipment();

    var data = {
      establishmentId: this.props.establishmentId,
      equipmentId: equipment._id,
      updatedField: field,
      updatedValue: value,
    };

    this.props.onUpdateEquipmentAdmin(
      this.props.establishmentId,
      data,
      successCallback,
    );
  }

  getModels(brandId, models) {
    let results = [];

    for (let model of models) {
      if (model.brand_id === brandId) results.push(model);
    }

    return results;
  }

  onDropFiles(files, clearCallback) {
    if (!files || files.length === 0) return;

    // Update mode. Immediately send files to BE
    if (this.isUpdate()) {
      this.setState({ disabledFileUpload: true });

      let formData = new FormData();
      for (let file of files) formData.append(file.name, file);

      var successCallback = () => {
        this.setState({ disabledFileUpload: false });
        clearCallback();
      };

      // Send to BE
      var equipment = this.getEquipment();
      this.props.onAddEquipmentFiles(
        this.props.establishmentId,
        equipment._id,
        formData,
        successCallback,
      );
    } else {
      // Create mode. Store the files and wait for the onComplete event
      this.setState({ files: files });
    }
  }

  getEquipment() {
    for (let equipment of this.props.equipments) {
      if (
        equipment.establishment_id === this.props.establishmentId &&
        (equipment._id === this.props.equipmentId ||
          equipment.internal_ref === this.props.internalRef)
      ) {
        return equipment;
      }
    }
  }

  closeModal() {
    this.setState({
      modal: null,
    });
  }

  openPrintEquipmentBarCodeModal() {
    this.setState({
      modal: (
        <EquipmentBarCodeModal
          close={() => this.closeModal()}
          internalRef={this.state.internal_ref}
          barCode={this.state.barcode}
        />
      ),
    });
  }

  getResidentsSelectOptions() {
    const options = [];
    const residents = this.props.residents
      .filter((r) => r.establishment_id === this.props.establishmentId)
      .sort((a, b) => ArrayUtil.ascendingSort(a.name, b.name));
    for (const r of residents) {
      options.push({ value: r._id, label: `${r.name} ${r.first_name}` });
    }
    return options;
  }

  render() {
    var equipment = this.getEquipment();

    var materialsTypesNode = this.props.materialsTypes.map(
      (material_type_id) => {
        return (
          <option key={material_type_id._id} value={material_type_id._id}>
            {material_type_id.name}
          </option>
        );
      },
    );

    var eqAffectationNode = Object.values(EqAffectation).map((option) => {
      return (
        <option key={option} value={option}>
          {this.props.intl.formatMessage({ id: "EqAffectation." + option })}
        </option>
      );
    });

    var eqOwnerNode = Object.values(EqOwner).map((option) => {
      return (
        <option key={option} value={option}>
          {this.props.intl.formatMessage({ id: "EqOwner." + option })}
        </option>
      );
    });

    let ownerClassName;
    if (parseInt(this.state.owner) === 0) ownerClassName = "alert-warning";
    if (parseInt(this.state.owner) === 1) ownerClassName = "alert-success";
    else if (parseInt(this.state.owner) === 2) ownerClassName = "alert-danger";

    var eqStateNode = Object.values(EqState).map((option) => {
      return (
        <option key={option} value={option}>
          {this.props.intl.formatMessage({ id: "EqState." + option })}
        </option>
      );
    });

    let statusClassName;
    if (parseInt(this.state.state) === 0) statusClassName = "alert-success";
    if (parseInt(this.state.state) === 1) statusClassName = "alert-warning";
    else if (parseInt(this.state.state) === 2) statusClassName = "alert-danger";

    var floorNode = () => {
      let floorOptions = [];

      for (let i = 0; i < 21; i++) {
        if (i === 0) {
          floorOptions.push(
            <option key={"floorNull"} value="">
              {" "}
            </option>,
          );
          floorOptions.push(
            <option
              key={"floor" + 0}
              value={this.props.intl.formatMessage({ id: "Ground.Floor.Abbr" })}
            >
              {this.props.intl.formatMessage({ id: "Ground.Floor.Abbr" })}
            </option>,
          );
        } else {
          floorOptions.push(
            <option key={"floor" + i} value={i}>
              {i}
            </option>,
          );
        }
      }

      return floorOptions;
    };

    let title = <FormattedMessage id="Add.Equipment" />;
    if (equipment) title = <FormattedMessage id="Modify.Equipment" />;

    var images = [];

    var docs = [];

    if (equipment && equipment.files) {
      for (let file of equipment.files) {
        var extension = FileUtil.extension(file);
        if (extension === "jpg" || extension === "jpeg" || extension === "png")
          images.push(file);
        else docs.push(file);
      }
    }

    // Lightbox
    const { photoIndex, lightboxIsOpen } = this.state;

    const lightboxImages = images.map((file) => {
      return (
        APIUrl.getEquipmentFile +
        equipment._id +
        "/" +
        file +
        "?token=" +
        APIUrl.jwtToken
      );
    });

    return (
      <Modal
        show={true}
        onHide={() => this.props.close()}
        backdrop={"static"}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row pl-3 pr-3">
            <div className="light-card barcode p-3 mb-3 mr-1 rounded align-middle">
              <div className="row p-3">
                <Barcode
                  width={1.1}
                  height={57.87}
                  value={this.state.barcode}
                />
              </div>
              <div className="row justify-content-center">
                <Button
                  variant="info"
                  onClick={() => this.openPrintEquipmentBarCodeModal()}
                >
                  <Icon icon="print" /> <FormattedMessage id="Print" />
                </Button>
              </div>
            </div>

            {this.isUpdate() && images && images.length > 0 && (
              <div
                className="light-card p-3 mb-3 rounded col-9"
                style={{
                  overflowX: "scroll",
                  whiteSpace: "nowrap",
                  display: "flex",
                }}
              >
                <div style={{ display: "flex" }}>
                  {images.map((fileName, i) => (
                    <MaintenanceImgPreview
                      key={"img" + i}
                      index={i}
                      src={
                        APIUrl.getEquipmentFile +
                        equipment._id +
                        "/" +
                        fileName +
                        "?token=" +
                        APIUrl.jwtToken
                      }
                      onClick={() => {
                        this.setState({
                          lightboxIsOpen: true,
                          photoIndex: i,
                        });
                      }}
                      deleteImage={() => this.deleteImage(fileName)}
                      height="150"
                      width="150"
                      alt=""
                      className="imageLightBox"
                    />
                  ))}
                </div>

                {/* Lightbox plugin begins here */}
                <div>
                  {lightboxIsOpen && (
                    <Lightbox
                      reactModalStyle={{
                        overlay: {
                          zIndex: 1080,
                          backgroundColor: "transparent",
                        },
                      }}
                      mainSrc={lightboxImages[photoIndex]}
                      nextSrc={
                        lightboxImages[(photoIndex + 1) % lightboxImages.length]
                      }
                      prevSrc={
                        lightboxImages[
                          (photoIndex + lightboxImages.length - 1) %
                            lightboxImages.length
                        ]
                      }
                      onCloseRequest={() =>
                        this.setState({ lightboxIsOpen: false })
                      }
                      onMovePrevRequest={() =>
                        this.setState({
                          photoIndex:
                            (photoIndex + lightboxImages.length - 1) %
                            lightboxImages.length,
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + 1) % lightboxImages.length,
                        })
                      }
                    />
                  )}
                </div>
                {/* Lightbox plugin ends here */}
              </div>
            )}
          </div>

          <div className="row pl-1">
            <div className="col-12 col-lg-6">
              <div className="form-group row">
                <div className="form-group row col-12 p-0 pt-1 pb-1 m-0">
                  <CustomLabel
                    label={this.props.intl.formatMessage({ id: "Type" })}
                    htmlFor="equipment-type"
                    labelClassName="col-sm-5"
                    required
                  />
                  <div id="equipment-type" className="col-sm-7">
                    <select
                      id="equipment-type"
                      className="form-control"
                      onChange={(e) =>
                        this.onChange(
                          "material_type_id",
                          e.target.value,
                          this.ifEquipmentExist(equipment),
                        )
                      }
                      onBlur={(e) =>
                        this.update(
                          "material_type_id",
                          e.target.value,
                          this.ifEquipmentExist(equipment),
                        )
                      }
                      defaultValue={this.state.material_type_id}
                      disabled={this.props.equipmentRequest ? true : false}
                    >
                      <option value="">
                        {this.props.intl.formatMessage({
                          id: "Select",
                        })}
                        ...
                      </option>
                      {materialsTypesNode}
                    </select>
                    <div className="text-danger">
                      <small>{this.state.materialTypeIdError}</small>
                    </div>
                  </div>
                </div>

                <div className="form-group row col-12 p-0 pt-1 pb-1 m-0">
                  <CustomLabel
                    label={this.props.intl.formatMessage({
                      id: "Intern.Ref",
                    })}
                    htmlFor="equipment-intern-ref"
                    labelClassName="col-sm-5"
                    required
                  />
                  <div id="equipment-int-ref" className="col-sm-7">
                    <input
                      type="text"
                      className="form-control"
                      id="equipment-int-ref"
                      autoComplete="off"
                      onChange={(e) =>
                        this.onChange(
                          "internal_ref",
                          e.target.value,
                          this.ifEquipmentExist(equipment),
                        )
                      }
                      onBlur={(e) =>
                        this.update(
                          "internal_ref",
                          e.target.value,
                          this.ifEquipmentExist(equipment),
                        )
                      }
                      value={this.state.internal_ref}
                    />
                    <div className="text-danger">
                      <small>{this.state.internalRefError}</small>
                    </div>
                  </div>
                </div>

                <div className="form-group row col-12 p-0 pt-1 pb-1 m-0">
                  <CustomLabel
                    label={this.props.intl.formatMessage({ id: "Brand" })}
                    htmlFor="equipment-brand"
                    labelClassName="col-sm-5"
                    required
                  />
                  <div id="equipment-brand" className="col-sm-7">
                    <ComboBox
                      onChange={(value) =>
                        this.onChange(
                          "brand_id",
                          value,
                          this.ifEquipmentExist(equipment),
                        )
                      }
                      onBlur={(e) =>
                        this.update(
                          "brand_id",
                          e,
                          this.ifEquipmentExist(equipment),
                        )
                      }
                      defaultOption={this.state.brand_id}
                      options={this.props.brands}
                      onCreateOption={(data, successCallback) =>
                        this.props.onAddBrand(data, successCallback)
                      }
                    />
                    <div className="text-danger">
                      <small>{this.state.brandIdError}</small>
                    </div>
                  </div>
                </div>

                <div className="form-group row col-12 p-0 pt-1 pb-1 m-0">
                  <CustomLabel
                    label={this.props.intl.formatMessage({ id: "Model" })}
                    htmlFor="equipment-model"
                    labelClassName="col-sm-5"
                    required
                  />
                  <div id="equipment-model" className="col-sm-7 pb-2">
                    <ComboBox
                      onChange={(value) =>
                        this.onChange(
                          "model_id",
                          value,
                          this.ifEquipmentExist(equipment),
                        )
                      }
                      onBlur={(e) =>
                        this.update(
                          "model_id",
                          e,
                          this.ifEquipmentExist(equipment),
                        )
                      }
                      defaultOption={this.state.model_id}
                      options={this.getModels(
                        this.state.brand_id,
                        this.props.models,
                      )}
                      onCreateOption={(data, successCallback) => {
                        if (Util.emptyString(this.state.brand_id)) return;
                        data.brand_id = this.state.brand_id; // Don't forget to add the brand_id
                        this.props.onAddModel(data, successCallback);
                      }}
                    />
                    <div className="text-danger">
                      <small>{this.state.modelIdError}</small>
                    </div>
                  </div>
                </div>
                <CustomLabel
                  label={this.props.intl.formatMessage({
                    id: "Serial.Number",
                  })}
                  htmlFor="equipment-serial-number"
                  labelClassName="col-sm-5"
                />
                <div id="equipment-serial-number" className="col-sm-7 pb-2">
                  <input
                    type="text"
                    className="form-control"
                    id="equipment-serial-number"
                    autoComplete="off"
                    onChange={(e) =>
                      this.onChange(
                        "serial_nbr",
                        e.target.value,
                        this.ifEquipmentExist(equipment),
                      )
                    }
                    onBlur={(e) =>
                      this.update(
                        "serial_nbr",
                        e.target.value,
                        this.ifEquipmentExist(equipment),
                      )
                    }
                    value={this.state.serial_nbr}
                  />
                </div>
                <CustomLabel
                  label={this.props.intl.formatMessage({
                    id: "Purchase.Date",
                  })}
                  htmlFor="equipment-purchase-date"
                  labelClassName="col-sm-5"
                />
                <div
                  id="equipment-series-purchase-date"
                  className="col-sm-7 pb-2 mb-2"
                >
                  <input
                    type="date"
                    className="form-control"
                    id="equipment-series-purchase-date"
                    autoComplete="off"
                    onChange={(e) =>
                      this.onChange(
                        "purchase_date",
                        e.target.value,
                        this.ifEquipmentExist(equipment),
                      )
                    }
                    onBlur={(e) =>
                      this.update(
                        "purchase_date",
                        e.target.value,
                        this.ifEquipmentExist(equipment),
                      )
                    }
                    value={this.state.purchase_date}
                  />
                </div>
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Warranty" })}
                  htmlFor="equipment-warranty"
                  labelClassName="col-sm-5"
                />
                <div className="col-sm-7">
                  <div className="custom-switch switch-primary">
                    <input
                      type="checkbox"
                      id="equipment-warranty"
                      className="custom-control-input switch-bg-blue"
                      onChange={(e) =>
                        this.onChange(
                          "warranty",
                          e.target.checked,
                          this.ifEquipmentExist(equipment),
                        )
                      }
                      onBlur={(e) =>
                        this.update(
                          "warranty",
                          e.target.checked,
                          this.ifEquipmentExist(equipment),
                        )
                      }
                      checked={this.state.warranty}
                    />
                    <CustomLabel
                      htmlFor="equipment-warranty"
                      labelClassName="custom-control-label"
                    />
                  </div>
                </div>

                <div className="col-12 mt-4" />
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Affectation" })}
                  htmlFor="equipment-affectation"
                  labelClassName="col-sm-5"
                />
                <div id="equipment-affectation" className="col-sm-7 pb-2">
                  <select
                    id="equipment-affectation"
                    className="form-control"
                    onChange={(e) =>
                      this.onChange(
                        "affectation",
                        e.target.value,
                        this.ifEquipmentExist(equipment),
                      )
                    }
                    onBlur={(e) =>
                      this.update(
                        "affectation",
                        e.target.value,
                        this.ifEquipmentExist(equipment),
                      )
                    }
                    defaultValue={this.state.affectation}
                  >
                    <option value="">
                      {this.props.intl.formatMessage({ id: "Select" })}...
                    </option>
                    {eqAffectationNode}
                  </select>
                </div>
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Resident" })}
                  htmlFor="equipment-resident"
                  labelClassName="col-sm-5"
                />
                <div id="equipment-resident" className="col-sm-7 pb-2">
                  <Select
                    placeholder={<FormattedMessage id="Select.Resident" />}
                    noOptionsMessage={() => (
                      <FormattedMessage id={"No.Result"} />
                    )}
                    onChange={(option) => {
                      this.setState({
                        selectedResidentOption: option,
                        resident_id: option.value,
                      });
                      this.isUpdate()
                        ? this.update(
                            "resident_id",
                            option.value,
                            this.ifEquipmentExist(equipment),
                          )
                        : this.onChange(
                            "resident_id",
                            option.value,
                            this.ifEquipmentExist(equipment),
                          );
                    }}
                    value={this.state.selectedResidentOption}
                    isDisabled={
                      parseInt(this.state.affectation) !==
                      EqAffectation.RESIDENT
                    }
                    options={this.getResidentsSelectOptions()}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="form-group row">
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Floor" })}
                  htmlFor="equipment-floor"
                  labelClassName="col-sm-5"
                />
                <div id="equipment-floor" className="col-sm-7 pb-2">
                  <select
                    className="form-control"
                    id="equipment-floor"
                    autoComplete="off"
                    onChange={(e) =>
                      this.onChange(
                        "floor",
                        e.target.value,
                        this.ifEquipmentExist(equipment),
                      )
                    }
                    onBlur={(e) =>
                      this.update(
                        "floor",
                        e.target.value,
                        this.ifEquipmentExist(equipment),
                      )
                    }
                    value={this.state.floor}
                  >
                    {floorNode()}
                  </select>
                </div>
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Room" })}
                  htmlFor="equipment-room"
                  labelClassName="col-sm-5"
                />
                <div id="equipment-room" className="col-sm-7 pb-2">
                  <input
                    type="text"
                    className="form-control"
                    id="equipment-room"
                    autoComplete="off"
                    onChange={(e) =>
                      this.onChange(
                        "room",
                        e.target.value,
                        this.ifEquipmentExist(equipment),
                      )
                    }
                    onBlur={(e) =>
                      this.update(
                        "room",
                        e.target.value,
                        this.ifEquipmentExist(equipment),
                      )
                    }
                    value={this.state.room}
                  />
                </div>

                <div className="form-group row col-12 p-0 pt-1 pb-1 m-0">
                  <CustomLabel
                    label={this.props.intl.formatMessage({ id: "Owner" })}
                    htmlFor="equipment-owner"
                    labelClassName="col-sm-5"
                    required
                  />
                  <div id="equipment-owner" className="col-sm-7">
                    <select
                      id="equipment-owner"
                      className={"form-control " + ownerClassName}
                      onChange={(e) =>
                        this.onChange(
                          "owner",
                          e.target.value,
                          this.ifEquipmentExist(equipment),
                        )
                      }
                      onBlur={(e) =>
                        this.update(
                          "owner",
                          e.target.value,
                          this.ifEquipmentExist(equipment),
                        )
                      }
                      defaultValue={this.state.owner}
                    >
                      <option value="">
                        {this.props.intl.formatMessage({
                          id: "Select",
                        })}
                        ...
                      </option>
                      {eqOwnerNode}
                    </select>
                    <div className="text-danger">
                      <small>{this.state.ownerError}</small>
                    </div>
                  </div>
                </div>
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "State" })}
                  htmlFor="equipment-state"
                  labelClassName="col-sm-5"
                  required
                />
                <div id="equipment-state" className="col-sm-7 pb-2">
                  {!this.isUpdate() && (
                    <div>
                      <select
                        id="equipment-state"
                        className={"form-control " + statusClassName}
                        onChange={(e) =>
                          this.onChange(
                            "state",
                            e.target.value,
                            this.ifEquipmentExist(equipment),
                          )
                        }
                        onBlur={(e) =>
                          this.update(
                            "state",
                            e.target.value,
                            this.ifEquipmentExist(equipment),
                          )
                        }
                        defaultValue={this.state.state}
                      >
                        <option value="">
                          {this.props.intl.formatMessage({ id: "Select" })}...
                        </option>
                        {eqStateNode}
                      </select>
                      <div className="text-danger">
                        <small>{this.state.stateError}</small>
                      </div>
                    </div>
                  )}

                  {this.isUpdate() && (
                    <div className={"form-control w-100 " + statusClassName}>
                      {this.props.intl.formatMessage({
                        id: "EqState." + this.state.state,
                      })}
                    </div>
                  )}
                </div>

                {equipment && (
                  <CustomLabel
                    label={this.props.intl.formatMessage({
                      id: "Materiovigilance",
                    })}
                    htmlFor="equipment-materiovigilance"
                    labelClassName="col-sm-5"
                  />
                )}
                {equipment && (
                  <div
                    id="equipment-materiovigilance"
                    className="col-sm-7 pb-2"
                  >
                    {this.props.materiovigilance}
                  </div>
                )}

                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Misc" })}
                  htmlFor="equipment-misc"
                  labelClassName="col-sm-5"
                />
                <div id="equipment-misc" className="col-sm-7 pb-2">
                  <input
                    type="text"
                    className="form-control"
                    id="equipment-misc"
                    autoComplete="off"
                    onChange={(e) =>
                      this.onChange(
                        "misc",
                        e.target.value,
                        this.ifEquipmentExist(equipment),
                      )
                    }
                    onBlur={(e) =>
                      this.update(
                        "misc",
                        e.target.value,
                        this.ifEquipmentExist(equipment),
                      )
                    }
                    value={this.state.misc}
                  />
                </div>
                <CustomLabel
                  label={this.props.intl.formatMessage({
                    id: "Attached.Documents",
                  })}
                  htmlFor="equipment-docs"
                  labelClassName="col-sm-5"
                />
                <div className="col-sm-7 pb-2">
                  <FileDropZone
                    disabled={this.state.disabledFileUpload}
                    onDropFiles={(files, clearCallback) =>
                      this.onDropFiles(files, clearCallback)
                    }
                    acceptedExtensions={["webp", "jpg", "jpeg", "png", "pdf"]}
                    multiple={true}
                  />

                  <div className="pt-3">
                    {docs &&
                      docs.length > 0 &&
                      docs.map((file) => (
                        <div key={file}>
                          <a
                            role="button"
                            href={
                              APIUrl.getEquipmentFile +
                              equipment._id +
                              "/" +
                              file +
                              "?token=" +
                              APIUrl.jwtToken
                            }
                            rel="noopener noreferrer"
                            download={file}
                            target="_blank"
                          >
                            {file}
                          </a>

                          <span>
                            <Button
                              variant="danger"
                              className="btn-sm m-2"
                              onClick={() => this.deleteDoc(file)}
                            >
                              <Icon icon="trash" clickable />
                            </Button>
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.modal}
        </Modal.Body>

        {!equipment && (
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.props.close()}>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button
              variant="info"
              onClick={() => this.onComplete()}
              disabled={this.state.disabled}
            >
              <FormattedMessage id="Add" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    materialsTypes: state.materialsTypes,
    residents: state.residents,
    brands: state.brands,
    models: state.models,
    equipments: state.equipments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddEquipment: (establishmentId, equipment, successCallback) =>
      dispatch(addEquipment(establishmentId, equipment, successCallback)),
    onCheckIfEquipmentExists: (data, existsCallback, noExistsCallback) =>
      dispatch(checkIfEquipmentExists(data, existsCallback, noExistsCallback)),
    onUpdateEquipmentAdmin: (establishmentId, data, successCallback) =>
      dispatch(updateEquipmentAdmin(establishmentId, data, successCallback)),
    onAddBrand: (data, successCallback) =>
      dispatch(addBrand(data, successCallback)),
    onAddModel: (data, successCallback) =>
      dispatch(addModel(data, successCallback)),
    onDeleteEquipmentFileById: (
      establishmentId,
      equipmentId,
      data,
      successCallback,
    ) =>
      dispatch(
        deleteEquipmentFileById(
          establishmentId,
          equipmentId,
          data,
          successCallback,
        ),
      ),

    onAddEquipmentFiles: (
      establishmentId,
      equipmentId,
      data,
      successCallback,
    ) =>
      dispatch(
        addEquipmentFiles(establishmentId, equipmentId, data, successCallback),
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(EquipmentModalAdmin));
