import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  deleteResident,
  deleteResidentAdmin,
  updateResident,
  updateResidentAdmin,
} from "../../actions/residents/residents";
import ModalManager from "../sub/modals/ModalManager";
import StringUtil from "../../util/StringUtil";
import ResidentModal from "../residents/ResidentModal";
import Roles from "../../enums/Roles";
import ActionMenu from "../sub/ActionMenu";
import MenuButton from "../sub/bootstrap/MenuButton";

class ResidentRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      firstNameFilter: "",
      nameFilter: "",
      collapsed: true,
    };
  }

  collapse() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }
  updateParentState(data) {
    this.props.updateParentState(data);
  }

  deleteResident(resident) {
    var title = <FormattedMessage id="Delete.Resident" />;
    var content = <FormattedMessage id="Delete.Resident.Confirmation" />;

    if (
      this.props.user.role === Roles.ADMIN ||
      this.props.user.role === Roles.SALES_REP
    ) {
      var data = {
        residentId: resident._id,
        establishment_id: this.props.establishmentId,
      };
      this.updateParentState({
        modal: (
          <ModalManager
            showModal={true}
            title={title}
            content={content}
            context="danger"
            successCallback={() =>
              this.props.onDeleteResidentAdmin(data, () =>
                this.props.closeModal(),
              )
            }
            closeModal={() => this.props.closeModal()}
            modalType="confirmation"
          />
        ),
      });
    } else {
      this.updateParentState({
        modal: (
          <ModalManager
            showModal={true}
            title={title}
            content={content}
            successCallback={() =>
              this.props.onDeleteResident({ residentId: resident._id }, () =>
                this.props.closeModal(),
              )
            }
            closeModal={() => this.props.closeModal()}
            modalType="confirmation"
          />
        ),
      });
    }
  }

  editResident(resident) {
    if (
      this.props.user.role === Roles.ADMIN ||
      this.props.user.role === Roles.SALES_REP
    ) {
      this.updateParentState({
        modal: (
          <ResidentModal
            resident={resident}
            establishmentId={this.props.establishmentId}
            successCallback={() =>
              this.props.onUpdateResidentAdmin(resident._id, () =>
                this.props.closeModal(),
              )
            }
            close={() => this.props.closeModal()}
          />
        ),
      });
    } else {
      this.updateParentState({
        modal: (
          <ResidentModal
            resident={resident}
            establishmentId={this.props.establishmentId}
            successCallback={() =>
              this.props.onUpdateResident(resident._id, () =>
                this.props.closeModal(),
              )
            }
            close={() => this.props.closeModal()}
          />
        ),
      });
    }
  }

  render() {
    var hasMissingData = this.props.equipment.length === 0;

    let menuItems = [];

    menuItems.push(
      // edit budget
      {
        icon: "pen-to-square",
        action: () => this.editResident(this.props.resident),
        text: <FormattedMessage id="Modify" />,
      },
      // delete budget
      {
        icon: "trash",
        action: () => this.deleteResident(this.props.resident),
        text: <FormattedMessage id="Delete" />,
      },
    );

    let menuAction = <ActionMenu items={menuItems} size="sm" />;

    return (
      <React.Fragment key={this.props.key}>
        <tr className="d-flex">
          <td className="col-9">
            {this.props.resident.name.toUpperCase()}{" "}
            {StringUtil.ucFirst(this.props.resident.first_name)}
          </td>
          <td className="col-2 text-center">
            <MenuButton
              icon={this.state.collapsed ? "eye" : "eye-slash"}
              size="sm"
              onClick={() => {
                this.collapse("equip-" + this.props.resident._id);
              }}
              hover={<FormattedMessage id="Associated.Equipments" />}
              disabled={hasMissingData}
            />
          </td>
          <td className="col-1">{menuAction}</td>
        </tr>
        {!this.state.collapsed && (
          <tr>
            <td className="d-flex" colSpan="2">
              <table className="table tablee4coll">
                <thead>
                  <tr className="d-flex">
                    <th scope="col" className="col">
                      <FormattedMessage id="Type" />
                    </th>
                    <th
                      scope="col"
                      className="col"
                      style={{ overflow: "visible" }}
                    >
                      <FormattedMessage id="Brand" />
                      /
                      <FormattedMessage id="Model" />
                    </th>
                    <th scope="col" className="col">
                      <FormattedMessage id="Location" />
                    </th>
                    <th scope="col" className="col text-center">
                      <FormattedMessage id="Maintenance" />
                    </th>
                    <th scope="col" className="col text-center">
                      <FormattedMessage id="State" />
                    </th>
                    <th scope="col" className="col text-center">
                      <FormattedMessage id="Owner" />
                    </th>
                    <th scope="col" className="col text-center">
                      <FormattedMessage id="Materiovigilance" />
                    </th>
                    <th scope="col" className="col text-center">
                      <FormattedMessage id="Actions" />
                    </th>
                  </tr>
                </thead>
                <tbody>{!hasMissingData && this.props.equipment}</tbody>
              </table>
            </td>
          </tr>
        )}
        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    brands: state.brands,
    models: state.models,
    materiovigilance: state.materiovigilance,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteResident: (residentId, successCallback) =>
      dispatch(deleteResident(residentId, successCallback)),
    onDeleteResidentAdmin: (residentId, successCallback) =>
      dispatch(deleteResidentAdmin(residentId, successCallback)),
    onUpdateResident: (residentId, successCallback) =>
      dispatch(updateResident(residentId, successCallback)),
    onUpdateResidentAdmin: (residentId, successCallback) =>
      dispatch(updateResidentAdmin(residentId, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ResidentRow));
