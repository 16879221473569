import React from "react";
import { connect } from "react-redux";
import OrderProducts from "./OrderProducts";
import DateUtil from "../../util/DateUtil";
import { FormattedMessage } from "react-intl";
import APIUrl from "../../APIUrl";
import Maths from "../../util/Maths";
import OrderStatus from "../../enums/OrderStatus";

class OrderPDF extends React.Component {
  getPaymentMethod(establishmentId) {
    if (!this.props.paymentMethods) return null;

    if (
      this.props.establishmentSettings &&
      this.props.establishmentSettings.payment_method_id
    ) {
      const paymentMethod = this.props.paymentMethods.find(
        (paymentMethod) =>
          paymentMethod._id ===
          this.props.establishmentSettings.payment_method_id,
      );

      if (!paymentMethod) return null;
      if (!paymentMethod.name) return null;

      return paymentMethod.name;
    } else if (this.props.establishmentsSettings) {
      const establishmentSettings = this.props.establishmentsSettings.find(
        (establishmentSetting) =>
          establishmentSetting &&
          establishmentSetting.establishment_id === establishmentId,
      );

      if (!establishmentSettings) return null;
      if (!establishmentSettings.payment_method_id) return null;

      const paymentMethod = this.props.paymentMethods.find(
        (paymentMethod) =>
          paymentMethod._id === establishmentSettings.payment_method_id,
      );

      if (!paymentMethod) return null;
      if (!paymentMethod.name) return null;

      return paymentMethod.name;
    }
    return null;
  }

  render() {
    var establishment = null;
    var admin = null;

    if (this.props.establishment) {
      establishment = (
        <div className="address">
          <h1>{this.props.establishment.name}</h1>
          <p>
            <FormattedMessage id="Order.Passed.By" />
            {this.props.clientName}
          </p>
          {this.props.establishment.address && (
            <p>{this.props.establishment.address}</p>
          )}
          <p>
            {this.props.establishment.postal_code &&
              this.props.establishment.postal_code}{" "}
            {this.props.establishment.city && this.props.establishment.city}
          </p>
        </div>
      );
    }

    if (this.props.admin) {
      admin = (
        <div>
          <h1>
            <FormattedMessage id="Your.Contact" />
          </h1>
          <p>{this.props.admin.first_name + " " + this.props.admin.name}</p>
          <p>{this.props.admin.phone && "Tel : " + this.props.admin.phone}</p>
          <p>{"Email : " + this.props.admin.email}</p>
        </div>
      );
    }

    let totalHT = Maths.round(
      this.props.order.total_ht +
        this.props.order.shipping_costs +
        this.props.order.urgent_costs,
    );

    let totalTTC = Maths.round(
      this.props.order.total_ttc +
        this.props.order.shipping_costs * 1.2 +
        this.props.order.urgent_costs * 1.2,
    );

    let totalTax = Maths.round(totalTTC - totalHT);

    const orderDate = DateUtil.getDateWithOffset(this.props.order.date, 0);
    const shippingDate = DateUtil.getDateWithOffset(
      this.props.order.date,
      this.props.order.shipping_delay,
    );

    return (
      <div id={this.props.id} className="pdf-container">
        <div className="pdf">
          <div className="div-logo">
            <div className="w-50-perc in-block">
              {this.props.generalSettings.logo && (
                <div className="e4coll-logo-container">
                  <img
                    src={
                      APIUrl.getLogo +
                      this.props.generalSettings._id +
                      "/" +
                      Math.random() +
                      "?token=" +
                      APIUrl.jwtToken
                    }
                    className="e4coll-logo"
                    alt="logo"
                  />
                </div>
              )}
              <div className="stamp">
                <p>
                  <FormattedMessage
                    id="Order.Info"
                    values={{
                      orderNum: this.props.order.ref,
                      orderDate: orderDate,
                    }}
                  />
                </p>
                {this.props.order.shipping_delay !== 0 &&
                  this.props.order.date &&
                  this.props.order.status !== OrderStatus.NEW &&
                  this.props.order.status !== OrderStatus.TBV && (
                    <p>
                      <FormattedMessage id="Order.Estimated.Delivery.Date" />:{" "}
                      {shippingDate}
                    </p>
                  )}
              </div>
            </div>
            {this.props.order.status === OrderStatus.NEW && (
              <div>
                <small className="order_notice">
                  <FormattedMessage id="Waiting.Delivery" />
                </small>
              </div>
            )}
            {this.props.order.status === OrderStatus.TBV && (
              <div>
                <small className="order_notice_red">
                  <FormattedMessage id="Waiting.Validation.Notice" />
                </small>
              </div>
            )}
          </div>

          <div className="div-responsables">
            <div className="contacts">
              {admin}
              {establishment}
            </div>
          </div>

          <div className="order-table">
            <div>
              <OrderProducts order={this.props.order} />
            </div>
          </div>

          <div className="summary-orderDetail">
            <div className="Park.Summary">
              <table className="table-total">
                <tbody>
                  <tr>
                    <td>
                      <FormattedMessage id="Total.Cart.Excl.Tax" />
                    </td>
                    <td className="font-bold">
                      <b>{Maths.round(this.props.order.total_ht)}€</b>
                    </td>
                  </tr>
                  {this.props.order.shipping_costs > 0 && (
                    <tr>
                      <td>
                        <FormattedMessage id="Shipping.Cost" />
                        &nbsp;
                      </td>
                      <td className="font-bold">
                        <b>{Maths.round(this.props.order.shipping_costs)}€</b>
                      </td>
                    </tr>
                  )}
                  {this.props.order.urgent_costs > 0 && (
                    <tr>
                      <td>
                        <FormattedMessage id="Emergency.Cost" />
                      </td>
                      <td className="font-bold">
                        <b>{Maths.round(this.props.order.urgent_costs)}€</b>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <FormattedMessage id="Total.Excl.Tax" />
                    </td>
                    <td className="font-bold">
                      <b>{totalHT}€</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="VAT" />
                    </td>
                    <td className="font-bold">
                      <b>{totalTax}€</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="Total.Incl.Tax" />
                    </td>
                    <td className="font-bold">
                      <b>{totalTTC}€</b>
                    </td>
                  </tr>
                  <tr className="order-detail">
                    <td>
                      <FormattedMessage id="Order.Shipping.Delay" />:
                    </td>
                    <td className="font-bold">
                      <b>
                        {this.props.order.shipping_delay}{" "}
                        <FormattedMessage id="Days" />
                      </b>
                    </td>
                  </tr>
                  <tr className="order-detail">
                    <td>
                      <FormattedMessage id="Order.Payment.Method" />:
                    </td>
                    <td className="font-bold">
                      <b>
                        {this.getPaymentMethod(this.props.establishment._id)}
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    generalSettings: state.generalSettings,
    establishmentSettings: state.establishmentSettings,
    establishmentsSettings: state.establishmentsSettings,
    paymentMethods: state.paymentMethods,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderPDF);
