import React from "react";
import { connect } from "react-redux";
import { removeCollaboratorEstablishment } from "../../../../actions/collaborators/collaborators";
import EstablishmentTotalUtil from "../../../../util/EstablishmentTotalUtil";
import EstablishmentRequestUtil from "../../../../util/EstablishmentRequestUtil";
import { FormattedMessage } from "react-intl";
import EstablishmentEquipmentUtil from "../../../../util/EstablishmentEquipmentUtil";
import { getOldProducts } from "../../../../actions/products/oldProducts";
import MenuButton from "../../../sub/bootstrap/MenuButton";

class CollaboratorAssignmentsRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
    };
  }

  getEstablishmentSettings(establishmentId) {
    for (let establishmentsSetting of this.props.establishmentsSettings) {
      if (establishmentsSetting.establishment_id === establishmentId)
        return establishmentsSetting;
    }
  }

  getMercurialName(establishmentId) {
    var establishmentsSetting = this.getEstablishmentSettings(establishmentId);

    for (let m of this.props.mercurials) {
      if (m._id === establishmentsSetting.mercurial_id) {
        let version = "";
        if (m.version !== 1) version = "(v" + m.version + ")";
        return m.name + " " + version;
      }
    }
  }

  removeEstablishment(establishmentId) {
    if (this.state.disabled) return;

    this.setState({ disabled: true });

    var data = {
      collaboratorId: this.props.collaborator._id,
      establishmentId: establishmentId,
    };

    var successCallback = () => {
      this.setState({ disabled: false });
    };

    this.props.onRemoveCollaboratorEstablishment(data, successCallback);
  }

  render() {
    var establishmentTotalUtil = new EstablishmentTotalUtil(
      this.props.establishment._id,
      this.props.clients,
      this.props.orders,
      this.props.products,
      this.props.oldProducts,
      (data) => this.props.onGetOldProducts(data),
    );

    var establishmentRequestUtil = new EstablishmentRequestUtil(
      this.props.establishment._id,
      this.props.requests,
      this.props.clients,
    );

    var establishmentEquipmentUtil = new EstablishmentEquipmentUtil(
      this.props.establishment._id,
      this.props.equipments,
    );

    return (
      <tr key={this.props.establishment._id}>
        <td>{this.props.establishment.name}</td>
        <td className="d-none d-md-table-cell">
          {this.props.establishment.address}
        </td>
        <td className="d-none d-md-table-cell">
          {this.getMercurialName(this.props.establishment._id)}
        </td>
        <td className="d-none d-lg-table-cell">
          {establishmentTotalUtil.getTotalHT()}€
        </td>
        <td className="d-none d-lg-table-cell">
          {establishmentRequestUtil.countRequests()}
        </td>
        <td className="d-none d-lg-table-cell">
          {establishmentEquipmentUtil.countEquipments()}
        </td>
        <td className="text-center">
          <MenuButton
            icon="unlink"
            onClick={() =>
              this.removeEstablishment(this.props.establishment._id)
            }
            hover={<FormattedMessage id="Separate" />}
            disabled={this.state.disabled}
          />
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    establishments: state.establishments,
    establishmentsSettings: state.establishmentsSettings,
    mercurials: state.mercurials,
    products: state.products,
    oldProducts: state.oldProducts,
    orders: state.orders,
    clients: state.clients,
    requests: state.requests,
    equipments: state.equipments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRemoveCollaboratorEstablishment: (data, successCallback) =>
      dispatch(removeCollaboratorEstablishment(data, successCallback)),
    onGetOldProducts: (data) => dispatch(getOldProducts(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollaboratorAssignmentsRow);
