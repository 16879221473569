import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { removeModel } from "../../../../../actions/configurables/models.js";
import ActionMenu from "../../../../sub/ActionMenu";

class ModelsAdminRow extends React.Component {
  getBrandName(brandId) {
    for (let brand of this.props.brands) {
      if (brand._id === brandId) return brand.name.toString().toUpperCase();
    }
  }

  getModelName(modelId) {
    for (let model of this.props.models) {
      if (model._id === modelId) return model.name;
    }
  }

  getEstablishmentName(establishmentId) {
    for (let establishment of this.props.establishments) {
      if (establishment._id === establishmentId) return establishment.name;
    }
  }

  checkModelEquipments(modelId) {
    let equipments = this.props.equipments;
    let result = [];

    for (let equipment of equipments) {
      if (equipment.model_id === modelId) {
        result.push(equipment);
      }
    }
    return result;
  }

  modalDeleteModel(modalTitle, modalContent, successCallback) {
    let { model, openConfModal } = this.props;
    let errors = this.checkModelEquipments(model._id);

    if (errors.length > 0) {
      this.openDataIntegrityModal(errors);
    } else {
      openConfModal(modalTitle, modalContent, successCallback);
    }
  }

  deleteModel(modelId) {
    var data = {
      modelId: modelId,
      equipments: this.props.equipments,
    };
    this.props.onDeleteModel(data);
  }

  openDataIntegrityModal(dataProblems) {
    var errorModalTitle = <FormattedMessage id="Error" />;
    var errorModalContent = (
      <div>
        <div className="alert alert-danger">
          <div>
            <FormattedMessage
              id="Model.Can.Not.Be.Deleted"
              values={{ model: this.props.model.name }}
            />
          </div>
          {/* {(Object.values(dataProblems).length === 30) && <div><FormattedMessage id="Mercurial.File.Error.Count" values={{ count: Object.values(dataProblems).length }} /></div>} */}
        </div>
        <table className="table table-striped tablee4coll">
          <thead>
            <tr className="d-flex">
              <th scope="col" className="col col-3">
                <FormattedMessage id="Establishment" />
              </th>
              <th scope="col" className="col col-3">
                <FormattedMessage id="Intern.Ref" />
              </th>
              <th scope="col" className="col col-3">
                <FormattedMessage id="Brand" />
              </th>
              <th scope="col" className="col col-3">
                <FormattedMessage id="Model" />
              </th>
            </tr>
          </thead>
          <tbody>
            {dataProblems.map((row, index) => {
              return (
                <tr key={index} className="d-flex">
                  <td className="col col-3">
                    {this.getEstablishmentName(row.establishment_id)}
                  </td>
                  <td className="col col-3">{row.internal_ref}</td>
                  <td className="col col-3">
                    {this.getBrandName(row.brand_id)}
                  </td>
                  <td className="col col-3">
                    {this.getModelName(row.model_id)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );

    this.props.openErrorModal(errorModalTitle, errorModalContent);
  }

  render() {
    let { model, key } = this.props;

    // 'Delete model modal setup
    var modalTitle = <FormattedMessage id="Confirmation" />;
    var modalContent = (
      <p className="card-text">
        <FormattedMessage id="Delete.Model" values={{ model: model.name }} />
      </p>
    );
    var successCallback = () => this.deleteModel(model._id);

    let menuItems = [];

    menuItems.push(
      // edit establishment
      {
        icon: "pen-to-square",
        action: () => this.props.openEditModal(model),
        text: <FormattedMessage id="Modify" />,
      },
      // show users
      {
        icon: "trash",
        action: () =>
          this.modalDeleteModel(modalTitle, modalContent, successCallback),
        text: <FormattedMessage id="Delete" />,
      },
    );

    let menuAction = <ActionMenu items={menuItems} />;

    return (
      <React.Fragment>
        <tr key={key} className="d-flex">
          <td className="col">{model.name}</td>
          <td className="col">{this.getBrandName(model.brand_id)}</td>
          <td className="text-center col col-1">{menuAction}</td>
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.user.lang,
    establishments: state.establishments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteModel: (data) => dispatch(removeModel(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelsAdminRow);
