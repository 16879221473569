import React from "react";
import { FormattedMessage } from "react-intl";
import ActionMenu from "../../../../sub/ActionMenu";
import { nanoid } from "nanoid";

function CheckpointsRow({
  checkpoint,
  checkpoints,
  onSort,
  openEditModal,
  openConfModal,
  onDeleteCheckpoint,
}) {
  const modalTitle = <FormattedMessage id="Confirm" />;
  const modalContent = <FormattedMessage id="Checkpoint.Remove.Confirmation" />;
  const successCallback = () => onDeleteCheckpoint(checkpoint);
  const disabledUp = checkpoint.sortIndex === 1;
  const disabledDown = checkpoints.length <= checkpoint.sortIndex;
  const menuItems = [];

  menuItems.push(
    {
      icon: "arrow-up",
      action: (e) => {
        !disabledUp && onSort(checkpoint, "up");
      },
      disabled: disabledUp,
      text: <FormattedMessage id="Move.Up" />,
    },
    {
      icon: "arrow-down",
      action: (e) => {
        !disabledDown && onSort(checkpoint, "down");
      },
      disabled: disabledDown,
      text: <FormattedMessage id="Move.Down" />,
    },
    {
      icon: "pen-to-square",
      action: (e) => openEditModal(checkpoint),
      text: <FormattedMessage id="Modify" />,
    },
    {
      icon: "trash",
      action: (e) => openConfModal(modalTitle, modalContent, successCallback),
      text: <FormattedMessage id="Delete" />,
    },
  );

  const menuAction = <ActionMenu items={menuItems} />;

  return (
    <tr key={nanoid()}>
      <td>{checkpoint.name}</td>
      <td className="text-center">{menuAction}</td>
    </tr>
  );
}

export default CheckpointsRow;
