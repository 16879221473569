import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import EquipmentOverview from "../../requests/EquipmentOverview";
import EstablishmentOverview from "./EstablishmentOverview";
import CheckpointState from "../../../enums/CheckpointState";
import { addMaintenance } from "../../../actions/maintenances/maintenances";
import EqState from "../../../enums/EqState";
import CustomLabel from "../../sub/CustomLabel";
import { Button, Modal } from "react-bootstrap";
import Icon from "../../sub/Icon";
import TextareaCounter from "react-textarea-counter";
import DateUtil from "../../../util/DateUtil";
import MaintenancePDF from "./MaintenancePDF";
import PDFUtil from "../../../util/PDFUtil";
import PDFViewer from "../../pdf/PDFViewer";
import FileDropZone from "../../sub/FileDropZone";
import MaintenanceImgPreview from "./MaintenanceImgPreview";
import { nanoid } from "nanoid";
import ModalManager from "../../sub/modals/ModalManager";
import SignaturePage from "../../sub/SignaturePage";
import ArrayUtil from "../../../util/ArrayUtil";

class AddMaintenanceModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      equipmentId: this.props.equipment._id,
      rep_id: "",
      selectedRepOption: null,
      checkpoints: [],
      general_state: EqState.GOOD,
      maintenance_action_id: null,
      comments: "",
      tech_signature: null,
      rep_signature: null,
      pictures: [],
      imgPreviewList: [],
      maxPictures: 5,
      modal: null,
      disabled: false,
      spinner: null,
      mode: "intervention",
    };

    const materialType = this.getMaterialType(
      this.props.equipment.material_type_id,
    );
    this.checkpoints = this.getCheckpoints(materialType.checkpoints);

    for (let checkpoint of this.checkpoints) {
      this.state.checkpoints.push({
        _id: checkpoint._id,
        name: checkpoint.name,
        state: CheckpointState.COMPLIANT,
        desc: "",
      });
    }
  }

  getCheckpointName(checkpointId) {
    for (let checkpoint of this.props.checkpoints) {
      if (checkpoint._id === checkpointId) return checkpoint.name;
    }
  }

  close(confirm) {
    if (confirm) {
      const title = <FormattedMessage id="Confirmation" />;
      const content = <FormattedMessage id="Really.Quit.Intervention" />;

      this.setState({
        modal: (
          <ModalManager
            showModal={true}
            context="danger"
            title={title}
            content={content}
            successCallback={() => this.props.closeModal()}
            closeModal={() => this.setState({ modal: null })}
            modalType="confirmation"
          />
        ),
      });
    } else {
      this.props.closeModal();
    }
  }

  getMaterialType(materialTypeId) {
    for (let materialType of this.props.materialsTypes) {
      if (materialType._id === materialTypeId) return materialType;
    }
  }

  getCheckpoints(mtCheckpoints) {
    var checkpoints = [];

    outer: for (let mtCheckpoint of mtCheckpoints) {
      for (let c of this.props.checkpoints) {
        if (c._id === mtCheckpoint.checkpoint_id) {
          checkpoints.push(c);
          continue outer;
        }
      }
    }

    return checkpoints;
  }

  onCheckpointChange(checkpointId, field, value) {
    this.setState((state) => {
      const checkpoints = state.checkpoints.map((checkpoint) => {
        if (checkpoint._id === checkpointId) checkpoint[field] = value;
        return checkpoint;
      });

      return { checkpoints };
    });
  }

  onChange(field, value) {
    this.setState({ [field]: value });

    // If we asked for signatures, we check them
    if (this.state.mode === "sign") {
      if (this.state.rep_signature.length && this.state.tech_signature.length) {
        this.setState({ disabled: false });
      }
    }
  }

  confirm() {
    let checkpoints = [];

    for (let checkpoint of this.state.checkpoints) {
      checkpoints.push({
        checkpoint_id: checkpoint._id,
        state: checkpoint.state,
        desc: checkpoint.desc,
      });
    }

    let requiredData = {
      equipment_id: this.state.equipmentId,
      rep_id: this.state.rep_id,
      checkpoints: checkpoints,
      general_state: this.state.general_state,
      comments: this.state.comments,
      maintenance_action_id: this.state.maintenance_action_id,
      representative_name: this.state.selectedRepOption?.label,
      tech_signature: this.state.tech_signature,
      rep_signature: this.state.rep_signature,
      rep_signature_checkbox:
        this.state.mode === "sign" ? { isEstablishmentRep: true } : null,
    };

    let optionalData = {
      comments: this.state.comments,
      maintenance_action_id: this.state.maintenance_action_id,
      pictures: this.state.pictures,
    };

    let data = Object.assign(requiredData, optionalData);

    let template = (
      <MaintenancePDF
        id={"maintenance-pdf-" + this.props.equipment._id}
        establishment={this.props.establishment}
        equipment={this.props.equipment}
        maintenance={data}
        admin={this.props.admin}
        maintenanceDate={
          data.creation_date
            ? data.signature_date
              ? DateUtil.toDate(data.signature_date)
              : DateUtil.toDate(data.creation_date)
            : DateUtil.toDate(Date.now())
        }
      />
    );

    if (this.state.mode === "sign" && this.state.tech_signature) {
      this.setState({
        disabled: true,
        pdfViewer: null,
        spinner: (
          <ModalManager
            showModal={true}
            message={this.props.intl.formatMessage({ id: "Save.Processing" })}
            variant="success"
            percentage="100"
            modalType="processing"
          />
        ),
      });

      // Hack to not detect maintenance has active one
      if (!data.rep_signature && data.rep_signature_checkbox.isEstablishmentRep)
        data.rep_signature = "checked";

      this.props.onAddMaintenance(
        this.props.establishment._id,
        data,
        (maintenance) => this.savePDF(maintenance, template),
      );
    } else if (this.state.mode === "preview") {
      this.setState({ modal: this.previewModal(template) });
    }
  }

  savePDF(maintenance, template) {
    let id = "maintenance-pdf-" + this.props.equipment._id;
    let fileName = maintenance._id;

    let resetCallback = () => {
      this.setState({
        pdfViewer: null,
        spinner: null,
      });

      this.close();
    };

    let toPDFCallback = () => {
      PDFUtil.toPDFWithCSSAsString(
        this.props.generalSettings,
        this.props.company,
        id,
        fileName,
        embeddedCSS,
        resetCallback,
        {
          type: "maintenance",
          equipmentId: this.props.equipment._id,
          url: "toMaintenancePDF",
        },
        false,
      );
    };

    this.setState(
      {
        pdfViewer: (
          <PDFViewer
            open={false}
            toggle={() => {}}
            template={template}
            fileName={fileName}
          />
        ),
      },
      toPDFCallback,
    );
  }

  previewModal(content) {
    return (
      <Modal
        show={true}
        onHide={() => this.setState({ modal: null })}
        backdrop="static"
        keyboard={false}
        size="xl fullscreen"
        // /!\ IMPORTANT : ANIMATION MUST BE DEACTIVATED WHEN USING CANVAS SIGNATURE
        // MIGHT PREVENT THE ABILITY TO DRAW !!!
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Preview" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="info"
            className="mr-auto"
            onClick={() => this.setState({ modal: null, mode: "intervention" })}
          >
            <Icon icon="arrow-circle-left" className="mr-2" />
            <FormattedMessage id="Edit" />
          </Button>
          <Button
            variant="warning"
            onClick={() =>
              this.setState({ modal: null, mode: "sign", disabled: true })
            }
          >
            <FormattedMessage id="Procede.Signature" />
            <Icon icon="arrow-circle-right" className="ml-2" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  closeModal() {
    this.setState({ modal: null });
  }

  deleteImage = (imgIndex) => {
    // Removing picture (file) from props
    let pos = this.state.imgPreviewList
      .map(function (image) {
        return image.id;
      })
      .indexOf(imgIndex);

    if (pos > -1) {
      this.state.pictures.splice(pos, 1);
    }

    // Removing picture from preview list
    const imgPreviewList = this.state.imgPreviewList.filter(
      (image) => image.id !== imgIndex,
    );
    this.setState({ imgPreviewList });
  };

  onDropFiles(files, that) {
    if (!files || files.length === 0) return;
    if (files[0].size > 1000000) return;
    this.state.pictures.push(files);

    const imgKey = "img" + nanoid();

    let addImage = (src) => {
      let newImage = {
        id: imgKey,
        src: src,
      };

      this.setState({
        imgPreviewList: [...this.state.imgPreviewList, newImage],
      });
    };

    var image = document.createElement("img");
    var reader = new FileReader();
    reader.onloadend = function () {
      var string = reader.result;
      image.src = string;

      addImage(string);

      that.state.pictures[that.state.pictures.length - 1] = string;
    };

    const uploadedFile = files[0][0];

    reader.readAsDataURL(uploadedFile);
  }

  getClients(establishmentId) {
    return this.props.clients
      .filter((c) => c.establishment_id === establishmentId)
      .sort((a, b) => ArrayUtil.ascendingSort(a.name, b.name));
  }

  getRepSelectOptions() {
    const options = [];
    const establishmentReps = this.getClients(this.props.establishment._id);
    for (const r of establishmentReps) {
      options.push({ value: r._id, label: `${r.name} ${r.first_name}` });
    }
    return options;
  }

  render() {
    var checkpointsNode = this.state.checkpoints.map((checkpoint) => {
      return (
        <CheckpointRow
          user={this.props.user}
          checkpoint={checkpoint}
          key={checkpoint._id}
          onChange={(checkpointId, field, value) =>
            this.onCheckpointChange(checkpointId, field, value)
          }
        />
      );
    });

    var eqStateNode = Object.values(EqState).map((option) => {
      return (
        <option key={option} value={option}>
          {this.props.intl.formatMessage({ id: "EqState." + option })}
        </option>
      );
    });

    return (
      <React.Fragment>
        <Modal
          show={true}
          backdrop={"static"}
          keyboard={false}
          onHide={() => this.close(true)}
          size={"xl"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="Equipment.Maintenance" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <EstablishmentOverview
              establishment={this.props.establishment}
              admin={this.props.admin}
            />

            <EquipmentOverview
              equipment={this.props.equipment}
              maintenanceDate={DateUtil.toDate(DateUtil.toyyyyMMdd(Date.now()))}
              isMaintenance
              estabRepId={this.state.rep_id}
            />

            {this.state.mode === "intervention" && (
              <div>
                <table className="table table-striped tablee4coll">
                  <thead>
                    <tr>
                      <th>
                        <div className="d-none d-sm-block">
                          <FormattedMessage id="Checkpoint" />
                        </div>
                        <div className="d-sm-none">
                          <FormattedMessage id="Checkpoint.Short" />
                        </div>
                      </th>
                      <th className="text-center col-2">
                        <div className="d-none d-sm-block">
                          <FormattedMessage id="CheckpointState.0" />
                        </div>
                        <div className="d-sm-none">
                          <FormattedMessage id="CheckpointState.Short.0" />
                        </div>
                      </th>
                      <th className="text-center col-2">
                        <div className="d-none d-sm-block">
                          <FormattedMessage id="CheckpointState.1" />
                        </div>
                        <div className="d-sm-none">
                          <FormattedMessage id="CheckpointState.Short.1" />
                        </div>
                      </th>
                      <th className="text-center col-2">
                        <div className="d-none d-sm-block">
                          <FormattedMessage id="CheckpointState.2" />
                        </div>
                        <div className="d-sm-none">
                          <FormattedMessage id="CheckpointState.Short.2" />
                        </div>
                      </th>
                      <th className="text-center col-2">
                        <FormattedMessage id="Comment" />
                      </th>
                    </tr>
                  </thead>

                  <tbody>{checkpointsNode}</tbody>
                </table>

                <hr />

                <div className="form-group row">
                  <CustomLabel
                    label={this.props.intl.formatMessage({
                      id: "General.State",
                    })}
                    htmlFor="checkpoint"
                    labelClassName="col-md-4 col-lg-3 col-form-label"
                  />
                  <div id="checkpoint" className="col-md-8 col-lg-5">
                    <select
                      id="general_state"
                      className="form-control mb-3 w-100"
                      defaultValue={this.state.general_state}
                      onChange={(e) =>
                        this.onChange("general_state", e.target.value)
                      }
                    >
                      {eqStateNode}
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <CustomLabel
                    label={this.props.intl.formatMessage({
                      id: "Suggested.Action",
                    })}
                    htmlFor="checkpoint"
                    labelClassName="col-md-4 col-lg-3 col-form-label"
                  />
                  <div id="checkpoint" className="col-md-8 col-lg-9">
                    <TextareaCounter
                      countLimit={1000}
                      className="textareaCounter"
                      id="comment"
                      value={this.state.comments}
                      onChange={(e) =>
                        this.onChange("comments", e.target.value)
                      }
                    />
                  </div>
                </div>

                {parseInt(this.state.imgPreviewList.length) <
                  parseInt(this.state.maxPictures) && (
                  <div className="container">
                    <CustomLabel
                      label={this.props.intl.formatMessage({ id: "Pictures" })}
                      labelClassName="col-md-4 col-lg-3 col-form-label"
                    />
                    {this.state.imgPreviewList.length <
                      this.state.maxPictures && (
                      <FileDropZone
                        onDropFiles={(result) =>
                          this.onDropFiles([result], this)
                        }
                        acceptedExtensions={["webp", "jpg", "jpeg", "png"]}
                        multiple={true}
                      />
                    )}
                  </div>
                )}

                {this.state.imgPreviewList.length !== 0 && (
                  <div className="container">
                    <div className="form-group row">
                      <div className="row mx-auto mb-5">
                        {this.state.imgPreviewList.map((image, index) => {
                          return (
                            <MaintenanceImgPreview
                              key={"img" + index}
                              index={image.id}
                              src={image.src}
                              deleteImage={(index) => this.deleteImage(index)}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {this.state.mode === "sign" && (
              <SignaturePage
                tech_signature={this.state.tech_signature}
                rep_signature={this.state.rep_signature}
                rep_signature_checkbox={{ isEstablishmentRep: true }}
                establishment={this.props.establishment}
                selectedRepOption={this.state.selectedRepOption}
                repSelectOptions={this.getRepSelectOptions()}
                selectedTechOption={{
                  value: this.props.user._id,
                  label: `${this.props.user.name} ${this.props.user.first_name}`,
                }}
                selfSigned
                setState={(state) => this.setState(state)}
                callBack={() => this.confirm()}
              />
            )}
          </Modal.Body>

          {this.state.mode === "intervention" && (
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.close(true)}>
                <FormattedMessage id="Cancel" />
              </Button>
              <Button
                variant="warning"
                disabled={this.state.disabled}
                onClick={() =>
                  this.setState({ mode: "preview" }, () => this.confirm())
                }
              >
                <FormattedMessage id="Preview" />
              </Button>
              <Button
                variant="warning"
                disabled={this.state.disabled}
                onClick={() =>
                  this.setState({ mode: "sign" }, () => this.confirm())
                }
              >
                <FormattedMessage id="Confirm" />
                <Icon icon="arrow-circle-right" className="ml-2" />
              </Button>
            </Modal.Footer>
          )}

          {this.state.modal}
        </Modal>

        <div style={{ position: "absolute", marginLeft: "-10000px" }}>
          {this.state.pdfViewer}
        </div>

        {this.state.spinner}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    materialsTypes: state.materialsTypes,
    checkpoints: state.checkpoints,
    equipments: state.equipments,
    materiovigilance: state.materiovigilance,
    clients: state.clients,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddMaintenance: (establishmentId, data, successCallback) =>
      dispatch(addMaintenance(establishmentId, data, successCallback)),
  };
};

const embeddedCSS = `
strong {
    font-family: "arial black" !important;
}
h3 {
    font-size: 24px !important;
}
h5 {
    font-size: 16px !important;
}
.pagebreak {
    page-break-before: always
}
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AddMaintenanceModal));

class CheckpointRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showComment: false,
    };
  }

  change(field, value) {
    this.props.onChange(this.props.checkpoint._id, field, value);
  }

  toggleComment(value) {
    this.setState({ showComment: !value });
  }

  render() {
    const compliantClassName =
      this.props.checkpoint.state === CheckpointState.COMPLIANT
        ? "success"
        : "light";
    const compliantClassIcon =
      this.props.checkpoint.state === CheckpointState.COMPLIANT
        ? ""
        : "fa-regular";
    const notCompliantClassName =
      this.props.checkpoint.state === CheckpointState.NOT_COMPLIANT
        ? "danger"
        : "light";
    const notCompliantClassIcon =
      this.props.checkpoint.state === CheckpointState.NOT_COMPLIANT
        ? ""
        : "fa-regular";
    const notApplicableClassName =
      this.props.checkpoint.state === CheckpointState.NOT_APPLICABLE
        ? "secondary"
        : "light";
    const notApplicableClassIcon =
      this.props.checkpoint.state === CheckpointState.NOT_APPLICABLE
        ? ""
        : "fa-regular";

    return (
      <React.Fragment>
        <tr key={this.props.checkpoint.name}>
          <td>{this.props.checkpoint.name}</td>
          <td className="text-center">
            <Button
              variant={compliantClassName}
              onClick={() => this.change("state", CheckpointState.COMPLIANT)}
            >
              <Icon icon="circle" className={compliantClassIcon} />
            </Button>
          </td>
          <td className="text-center">
            <Button
              variant={notCompliantClassName}
              onClick={() =>
                this.change("state", CheckpointState.NOT_COMPLIANT)
              }
            >
              <Icon icon="circle" className={notCompliantClassIcon} />
            </Button>
          </td>
          <td className="text-center">
            <Button
              variant={notApplicableClassName}
              onClick={() =>
                this.change("state", CheckpointState.NOT_APPLICABLE)
              }
            >
              <Icon icon="circle" className={notApplicableClassIcon} />
            </Button>
          </td>
          <td className="text-center">
            <Button
              variant={
                this.state.showComment
                  ? "secondary"
                  : this.props.checkpoint.desc !== ""
                    ? "warning"
                    : "light"
              }
              onClick={() => this.toggleComment(this.state.showComment)}
            >
              <Icon icon="eye" clickable />
            </Button>
          </td>
        </tr>

        {this.state.showComment && (
          <tr>
            <td colSpan="1000 mw-200">
              <TextareaCounter
                countLimit={500}
                rows={1}
                className="textareaCounter"
                id="comment"
                name="comment"
                value={this.props.checkpoint.desc}
                onChange={(e) => this.change("desc", e.target.value)}
              />
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  }
}
