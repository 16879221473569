import APIUrl from "../../../APIUrl";
import axios from "axios";
import { getEstablishmentsSettings } from "../../establishments/establishmentSettings";
import { getAllEquipments } from "../../equipments/equipments";
import { getAllRequests } from "../../requests/requests";
import { getCheckpoints } from "../../checkpoints/checkpoints";
import { getEquipmentsRequest } from "../../equipmentsRequest/equipmentsRequest";

export const GET_MATERIALS_TYPES = "GET_MATERIALS_TYPES";

function getMaterialsTypesAction(materialsTypes) {
  return { type: GET_MATERIALS_TYPES, materialsTypes: materialsTypes };
}

export const getMaterialsTypes = function (successCallback) {
  return function (dispatch) {
    return axios
      .get(APIUrl.getMaterialsTypes)
      .then(function (response) {
        if (response) dispatch(getMaterialsTypesAction(response.data));
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

// ==================================================================
// =========================== ADMIN ================================
// ==================================================================

export const addMaterialsType = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addMaterialsType, data)
      .then(function (response) {
        dispatch(getMaterialsTypes());
        dispatch(getCheckpoints());
        successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteMaterialType = function (data) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteMaterialType, data)
      .then(function (response) {
        dispatch(getMaterialsTypes());
        dispatch(getEstablishmentsSettings());
        dispatch(getAllEquipments());
        dispatch(getAllRequests());
        dispatch(getEquipmentsRequest());
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const sortCheckpoint = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.sortCheckpoint, data)
      .then(function (response) {
        dispatch(
          getCheckpoints(() =>
            dispatch(getMaterialsTypes(() => successCallback())),
          ),
        );
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addCheckpointToMT = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addCheckpointToMT, data)
      .then(function (response) {
        dispatch(
          getCheckpoints(() =>
            dispatch(getMaterialsTypes(() => successCallback())),
          ),
        );
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateCheckpoint = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateCheckpoint, data)
      .then(function (response) {
        dispatch(
          getCheckpoints(() =>
            dispatch(getMaterialsTypes(() => successCallback())),
          ),
        );
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteCheckpointFromMT = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteCheckpointFromMT, data)
      .then(function (response) {
        dispatch(
          getCheckpoints(() =>
            dispatch(getMaterialsTypes(() => successCallback())),
          ),
        );
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateMaterialType = function (data) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateMaterialType, data)
      .then(function (response) {
        dispatch(getCheckpoints(() => dispatch(getMaterialsTypes())));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addMaterialTypeImg = function (
  MaterialTypeId,
  data,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addMaterialTypeImg + MaterialTypeId, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        dispatch(
          getCheckpoints(() =>
            dispatch(getMaterialsTypes(() => successCallback())),
          ),
        );
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteMaterialTypeImg = function (
  materialTypeId,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteMaterialTypeImg, { materialTypeId: materialTypeId })
      .then(function (response) {
        dispatch(
          getCheckpoints(() =>
            dispatch(
              getMaterialsTypes(() => {
                if (successCallback) return successCallback();
              }),
            ),
          ),
        );
      })
      .catch(function (err) {
        throw err;
      });
  };
};
