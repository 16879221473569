import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import EstablishmentOverview from "./EstablishmentOverview";
import EquipmentOverview from "../../requests/EquipmentOverview";
import CheckpointState from "../../../enums/CheckpointState";
import APIUrl from "../../../APIUrl";
import { Alert, Badge, Card } from "react-bootstrap";
import DateUtil from "../../../util/DateUtil";
import Icon from "../../sub/Icon";

class MaintenancePDF extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logoDisposition: "portrait",
    };
  }

  getCheckpointName(checkpointId) {
    for (let checkpoint of this.props.checkpoints) {
      if (checkpoint._id === checkpointId) return checkpoint.name;
    }
  }

  render() {
    const { generalSettings, company, maintenance } = this.props;

    const checkpoints = [];

    for (const checkpoint of maintenance.checkpoints) {
      checkpoints.push({
        _id: checkpoint.checkpoint_id,
        name: this.getCheckpointName(checkpoint.checkpoint_id),
        state: checkpoint.state,
        desc: checkpoint.desc,
      });
    }

    const checkpointsNode = checkpoints.map((checkpoint) => {
      return (
        <CheckpointRow
          user={this.props.user}
          checkpoint={checkpoint}
          key={checkpoint._id}
        />
      );
    });

    let companyData = "";
    let imgError = false;

    const logo = generalSettings.logo && (
      <img
        src={
          APIUrl.getLogo +
          generalSettings._id +
          "/" +
          Math.random() +
          "?token=" +
          APIUrl.jwtToken
        }
        style={{ maxHeight: "7em", maxWidth: "10em" }}
        alt="logo client"
        onError={(e) => {
          if (imgError) return;
          e.target.onerror = null;
          imgError = true;
          e.target.src = "/images/placeholder.png";
        }}
      />
    );

    if (generalSettings.commercial_sign) {
      companyData += generalSettings.commercial_sign;
    } else companyData += company.name;
    if (generalSettings.contact_information)
      companyData += " - " + generalSettings.contact_information;
    if (generalSettings.Siret)
      companyData += " - SIRET : " + generalSettings.Siret;

    companyData = (
      <div className="mt-2">
        {
          <div>
            <strong>{generalSettings.commercial_sign || company.name}</strong>
          </div>
        }
        {generalSettings.contact_information && (
          <div>{generalSettings.contact_information}</div>
        )}
        {generalSettings.phone && (
          <div>
            <FormattedMessage id="Phone" />
            <span>: {generalSettings.phone}</span>
          </div>
        )}
        {generalSettings.email && (
          <div>
            <FormattedMessage id="Email" />
            <span>: {generalSettings.email}</span>
          </div>
        )}
        {generalSettings.vatNumber && (
          <div>
            <FormattedMessage id="VAT.Number" />
            <span>: {generalSettings.vatNumber}</span>
          </div>
        )}
        {generalSettings.website && (
          <div>
            <FormattedMessage id="Website" />
            <span>: {generalSettings.website}</span>
          </div>
        )}
      </div>
    );

    return (
      <div className="alert-dark p-5">
        <div className="pdf-page mb-5 ml-5 mr-5 pt-5">
          <div id={this.props.id} className="pdf-container">
            <div className="container" style={{ fontFamily: "arial" }}>
              <div className="ml-5 mr-5">
                <table
                  className="table table-borderless"
                  style={{ width: "100%" }}
                >
                  <tbody>
                    <tr>
                      <td style={{ width: "65%" }}>
                        {this.props.generalSettings.logo && (
                          <div
                            className={
                              this.state.logoDisposition === "portrait"
                                ? "float-left mr-2"
                                : "float-left mr-2"
                            }
                          >
                            {logo}
                          </div>
                        )}
                        <div>
                          <small>{companyData}</small>
                        </div>
                      </td>
                      <td style={{ width: "35%" }} className="text-right">
                        <img
                          src={APIUrl.rootUrl + "/img/logo_pepsicoll.png"}
                          className="imgHome mb-3"
                          alt="Logo PEPSiCOLL"
                          style={{
                            width: "auto",
                            maxHeight: "3em",
                            top: "0",
                            verticalAlign: "top",
                          }}
                        />
                        <div>
                          <h3>
                            <Badge variant="light">
                              <FormattedMessage id="Date" /> :{" "}
                              {DateUtil.toDate(Date.now())}
                            </Badge>
                          </h3>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="row text-center mb-3 mt-3">
                  <div className="col-md-12 text-uppercase">
                    <Alert
                      variant="info pb-0"
                      style={{
                        backgroundColor: "#088DAE",
                        color: "#fff",
                        border: "1px solid #000",
                      }}
                    >
                      <h3>
                        <strong>
                          <FormattedMessage id="Equipment.Maintenance" />
                        </strong>
                      </h3>
                    </Alert>
                  </div>
                </div>
                <EstablishmentOverview
                  establishment={this.props.establishment}
                  admin={this.props.admin}
                />

                <EquipmentOverview
                  equipment={this.props.equipment}
                  maintenanceDate={this.props.maintenanceDate}
                  isMaintenance
                  estabRepId={this.props.maintenance.rep_id}
                />

                <div className={"blue-table"}>
                  <table className="table table-bordered">
                    <thead className="thead-light">
                      <tr>
                        <th>
                          <div>
                            <FormattedMessage id="Checkpoint" />
                          </div>
                        </th>
                        <th>
                          <div>
                            <FormattedMessage id="CheckpointState.0" />
                          </div>
                        </th>
                        <th>
                          <div>
                            <FormattedMessage id="CheckpointState.1" />
                          </div>
                        </th>
                        <th>
                          <div>
                            <FormattedMessage id="CheckpointState.2" />
                          </div>
                        </th>
                      </tr>
                    </thead>

                    <tbody>{checkpointsNode}</tbody>
                  </table>

                  <Card className="mb-3 pagebreak">
                    <Card.Header>
                      <strong>
                        <FormattedMessage id="General.State" />
                      </strong>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text className="col-md-12 mb-1 text-justify">
                        <FormattedMessage
                          id={"EqState." + maintenance.general_state}
                        />
                      </Card.Text>
                    </Card.Body>
                  </Card>

                  <Card className="mb-2">
                    <Card.Header>
                      <strong>
                        <FormattedMessage id="Suggested.Action" />
                      </strong>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text className="col-md-12 mb-1 text-justify">
                        {maintenance.comments && maintenance.comments.length ? (
                          maintenance.comments
                        ) : (
                          <FormattedMessage id="None.Feminine" />
                        )}
                      </Card.Text>
                    </Card.Body>
                  </Card>

                  {maintenance.pictures &&
                    maintenance.pictures.length !== 0 && (
                      <Card className="mb-2 pagebreak">
                        <Card.Header>
                          <strong>
                            <FormattedMessage id="Pictures" />
                          </strong>
                        </Card.Header>
                        <Card.Body className="p-0">
                          {maintenance.pictures.map((x, i) => (
                            <img
                              key={i}
                              className="p-2"
                              style={{ maxWidth: "49%", float: "left" }}
                              src={x}
                              alt=""
                            />
                          ))}
                        </Card.Body>
                      </Card>
                    )}

                  {(maintenance.rep_signature ||
                    maintenance.rep_signature_checkbox) && (
                    <table
                      className="table table-borderless"
                      style={{ width: "100%" }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{ width: "50%" }}
                            className="text-center pl-0"
                          >
                            <Card>
                              <Card.Header>
                                <strong>
                                  <FormattedMessage id="Technician.Signature" />
                                </strong>
                              </Card.Header>
                              <Card.Body className="p-0 pt-1">
                                <img
                                  width="284px"
                                  height="160px"
                                  src={maintenance.tech_signature}
                                  alt="Tech signature"
                                  className="zoom-65"
                                />
                              </Card.Body>
                            </Card>
                          </td>
                          <td
                            style={{ width: "50%" }}
                            className="text-center pr-0"
                          >
                            <Card variant="dark">
                              <Card.Header>
                                <strong>
                                  <FormattedMessage id="Estab.Rep.Signature" />
                                </strong>
                              </Card.Header>
                              <Card.Body className="p-0 pt-1">
                                {maintenance.rep_signature &&
                                  !maintenance.rep_signature_checkbox && (
                                    <img
                                      width="284px"
                                      height="160px"
                                      src={maintenance.rep_signature}
                                      alt="Tech signature"
                                      className="zoom-65"
                                    />
                                  )}

                                {(!maintenance.rep_signature ||
                                  maintenance.rep_signature === "checked") &&
                                maintenance.rep_signature_checkbox ? (
                                  maintenance.rep_signature_checkbox
                                    .isEstablishmentRep ? (
                                    <div
                                      id="rep_signature-container"
                                      className={
                                        "max-height-200 min-height-200"
                                      }
                                    >
                                      <p width="350px" height="200px">
                                        <br />
                                        <br />
                                        {this.props.intl.formatMessage({
                                          id: "Signature.Replace",
                                        }) +
                                          " " +
                                          this.props.intl.formatMessage({
                                            id: "Establishment",
                                          })}
                                        <br />
                                        <strong>
                                          {this.props.establishment.name}
                                        </strong>
                                        <br />
                                        {maintenance.representative_name}
                                      </p>
                                    </div>
                                  ) : (
                                    <div
                                      id="rep_signature-container"
                                      className={
                                        "max-height-200 min-height-200"
                                      }
                                    >
                                      <p width="350px" height="200px">
                                        <b>{this.props.establishment.name}</b>
                                      </p>
                                    </div>
                                  )
                                ) : null}
                              </Card.Body>
                            </Card>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    generalSettings: state.generalSettings,
    company: state.company,
    checkpoints: state.checkpoints,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(MaintenancePDF));

class CheckpointRow extends React.Component {
  render() {
    const compliantClassIcon =
      this.props.checkpoint.state === CheckpointState.COMPLIANT ? (
        <Icon icon="check" />
      ) : (
        ""
      );
    const notCompliantClassIcon =
      this.props.checkpoint.state === CheckpointState.NOT_COMPLIANT ? (
        <Icon icon="times" />
      ) : (
        ""
      );
    const notApplicableClassIcon =
      this.props.checkpoint.state === CheckpointState.NOT_APPLICABLE ? (
        <Icon icon="minus" />
      ) : (
        ""
      );

    return (
      <React.Fragment>
        <tr key={this.props.checkpoint.name}>
          <td>{this.props.checkpoint.name}</td>
          <td className="text-center text-success">{compliantClassIcon}</td>
          <td className="text-center text-danger">{notCompliantClassIcon}</td>
          <td className="text-center">{notApplicableClassIcon}</td>
        </tr>

        {this.props.checkpoint.desc.length !== 0 && (
          <tr style={{ backgroundColor: "inherit" }}>
            <td colSpan="4" className="p-0 text-justify">
              <Alert variant="light pt-0 m-0">
                <small>{this.props.checkpoint.desc}</small>
              </Alert>
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  }
}
