import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// https://fontawesome.com/v5/docs/web/use-with/react
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";

library.add(fas, far);

export default class MenuButton extends React.Component {
  setAdditionalClasses() {
    return this.props.className || "";
    // return "ml-1 mr-1 mb-1 " + additionnalClassname;
  }

  // Avoid Warning like "Expected `onClick` listener to be a function, instead got `false`" if onClick no set for MenuButton.
  onClickVoid() {}

  setOnclick() {
    return this.props.onClick || this.onClickVoid();
  }

  setDisabled() {
    return this.props.disabled || false;
  }

  render() {
    /**
     * https://fontawesome.com/docs/web/use-with/react/
     * icon : The name like provided by fontawesome
     * className : Additionnal bootstrap className
     * disabled : icon looks and acts as disabled
     * id : icon (element) id
     * onClick : icon onclik action
     * clickable : Set style on the icon to act like a link (on hover)
     * variant : which fontawesome collection to use ("fas" = solid, "far" = regular, "fab" = brand)
     * size : Icon size. If other than normal (none specified), must be one of these values (see availableSizes const)
     */
    const { icon, variant, size, processing, hover, placement } = this.props;

    const collection =
      variant && (variant === "fas" || variant === "far" || variant === "fab")
        ? variant
        : "fas";

    const iconElement = icon ? (
      <FontAwesomeIcon
        icon={processing ? ["fas", "circle-notch"] : [collection, icon]}
        className={processing ? "fa-spin" : ""}
        fixedWidth={true}
      />
    ) : (
      ""
    );

    return (
      <React.Fragment>
        {hover && (
          <OverlayTrigger
            delay={{ hide: 0, show: 0 }}
            overlay={(props) => <Tooltip {...props}>{hover}</Tooltip>}
            placement={placement || "top"}
          >
            <Button
              variant={
                variant
                  ? variant
                  : this.setDisabled()
                    ? "outline-secondary"
                    : "outline-info"
              }
              className={this.setAdditionalClasses()}
              onClick={this.setOnclick()}
              disabled={this.setDisabled()}
              size={size || ""}
            >
              {iconElement}
              {this.props.children && " "}
              {this.props.children}
            </Button>
          </OverlayTrigger>
        )}

        {!hover && (
          <Button
            variant={variant || "outline-info"}
            className={this.setAdditionalClasses()}
            onClick={this.setOnclick()}
            disabled={this.setDisabled()}
            size={size || ""}
          >
            {iconElement}
            {this.props.children && " "}
            {this.props.children}
          </Button>
        )}
      </React.Fragment>
    );
  }
}
